@charset "UTF-8";
/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 5.4.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/
:root {
  --ct-logo-lg-height: 22px;
  --ct-logo-sm-height: 22px;
  --ct-leftbar-width: 260px;
  --ct-leftbar-width-md: 160px;
  --ct-leftbar-width-sm: 70px;
  --ct-leftbar-condensed-height: 2000px;
  --ct-topbar-height: 70px;
  --ct-menu-item-icon-size: 1.2rem;
  --ct-menu-item-font-size: 0.9375rem;
  --ct-menu-item-padding-x: 10px;
  --ct-menu-item-padding-y: 15px;
  --ct-menu-condensed-link-bg: #727cf5;
  --ct-footer-height: 60px;
  --ct-theme-card-border-width: 0px;
}

/* Background Light left-sidebar */
html[data-menu-color=light] {
  --ct-menu-bg: #ffffff;
  --ct-menu-item-color: #6c757d;
  --ct-menu-item-hover-color: #727cf5;
  --ct-menu-item-active-color: #727cf5;
  --ct-help-box-bg: #727cf5;
}

/* Dark Left Sidebar */
html[data-menu-color=dark] {
  --ct-menu-bg: #313a46;
  --ct-menu-item-color: #8391a2;
  --ct-menu-item-hover-color: #bccee4;
  --ct-menu-item-active-color: #ffffff;
  --ct-help-box-bg: rgba(255, 255, 255, 0.07);
}

html[data-menu-color=brand] {
  --ct-menu-bg: linear-gradient(135deg, #6379c3 0%, #546ee5 60%);
  --ct-menu-item-color: #cedce4;
  --ct-menu-item-hover-color: #ffffff;
  --ct-menu-item-active-color: #ffffff;
  --ct-help-box-bg: rgba(255, 255, 255, 0.07);
}

html[data-bs-theme=dark][data-menu-color=light],
html[data-bs-theme=dark][data-menu-color=dark] {
  --ct-menu-bg: #3a444e;
  --ct-menu-item-color: #8391a2;
  --ct-menu-item-hover-color: #bccee4;
  --ct-menu-item-active-color: #ffffff;
  --ct-help-box-bg: rgba(255, 255, 255, 0.07);
}

html[data-topbar-color=light] {
  --ct-topbar-bg: #ffffff;
  --ct-topbar-item-color: #6c757d;
  --ct-topbar-item-hover-color: #727cf5;
  --ct-topbar-search-bg: #f0f3f8;
  --ct-topbar-user-bg: #fafafd;
  --ct-topbar-user-border: #eef2f7;
}

html[data-topbar-color=dark] {
  --ct-topbar-bg: #313a46;
  --ct-topbar-item-color: #8391a2;
  --ct-topbar-item-hover-color: #bccee4;
  --ct-topbar-search-bg: #464f5b;
  --ct-topbar-user-bg: #3c4655;
  --ct-topbar-user-border: #414d5d;
}

html[data-topbar-color=brand] {
  --ct-topbar-bg: linear-gradient(135deg, #6379c3 0%, #546ee5 60%);
  --ct-topbar-item-color: rgba(255, 255, 255, 0.7);
  --ct-topbar-item-hover-color: #ffffff;
  --ct-topbar-search-bg: rgba(255, 255, 255, 0.1);
  --ct-topbar-user-bg: rgba(255, 255, 255, 0.1);
  --ct-topbar-user-border: rgba(255, 255, 255, 0.15);
}

html[data-bs-theme=dark][data-topbar-color=light],
html[data-bs-theme=dark][data-topbar-color=dark] {
  --ct-topbar-bg: #3a444e;
  --ct-topbar-item-color: #8391a2;
  --ct-topbar-item-hover-color: #bccee4;
  --ct-topbar-search-bg: #464f5b;
  --ct-topbar-user-bg: #3c4655;
  --ct-topbar-user-border: #414d5d;
}

/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --ct-blue: #2c8ef8;
  --ct-indigo: #727cf5;
  --ct-purple: #6b5eae;
  --ct-pink: #ff679b;
  --ct-red: #fa5c7c;
  --ct-orange: #fd7e14;
  --ct-yellow: #ffc35a;
  --ct-green: #0acf97;
  --ct-teal: #02a8b5;
  --ct-cyan: #39afd1;
  --ct-black: #000;
  --ct-white: #fff;
  --ct-gray: #8a969c;
  --ct-gray-dark: #343a40;
  --ct-gray-100: #f6f7fb;
  --ct-gray-200: #eef2f7;
  --ct-gray-300: #dee2e6;
  --ct-gray-400: #ced4da;
  --ct-gray-500: #a1a9b1;
  --ct-gray-600: #8a969c;
  --ct-gray-700: #6c757d;
  --ct-gray-800: #343a40;
  --ct-gray-900: #313a46;
  --ct-primary: #727cf5;
  --ct-secondary: #6c757d;
  --ct-success: #0acf97;
  --ct-info: #39afd1;
  --ct-warning: #ffc35a;
  --ct-danger: #fa5c7c;
  --ct-light: #eef2f7;
  --ct-dark: #313a46;
  --ct-primary-rgb: 114, 124, 245;
  --ct-secondary-rgb: 108, 117, 125;
  --ct-success-rgb: 10, 207, 151;
  --ct-info-rgb: 57, 175, 209;
  --ct-warning-rgb: 255, 195, 90;
  --ct-danger-rgb: 250, 92, 124;
  --ct-light-rgb: 238, 242, 247;
  --ct-dark-rgb: 49, 58, 70;
  --ct-primary-text-emphasis: #2e3262;
  --ct-secondary-text-emphasis: #8a969c;
  --ct-success-text-emphasis: #04533c;
  --ct-info-text-emphasis: #174654;
  --ct-warning-text-emphasis: #664e24;
  --ct-danger-text-emphasis: #642532;
  --ct-light-text-emphasis: #6c757d;
  --ct-dark-text-emphasis: #6c757d;
  --ct-primary-bg-subtle: #f1f2fe;
  --ct-secondary-bg-subtle: #f6f7fb;
  --ct-success-bg-subtle: #e7faf5;
  --ct-info-bg-subtle: #ebf7fa;
  --ct-warning-bg-subtle: #fff9ef;
  --ct-danger-bg-subtle: #ffeff2;
  --ct-light-bg-subtle: #fbfbfd;
  --ct-dark-bg-subtle: #ced4da;
  --ct-primary-border-subtle: #c7cbfb;
  --ct-secondary-border-subtle: #eef2f7;
  --ct-success-border-subtle: #9decd5;
  --ct-info-border-subtle: #b0dfed;
  --ct-warning-border-subtle: #ffe7bd;
  --ct-danger-border-subtle: #fdbecb;
  --ct-light-border-subtle: #eef2f7;
  --ct-dark-border-subtle: #a1a9b1;
  --ct-white-rgb: 255, 255, 255;
  --ct-black-rgb: 0, 0, 0;
  --ct-font-sans-serif: "Nunito", sans-serif;
  --ct-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --ct-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --ct-body-font-family: var(--ct-font-sans-serif);
  --ct-body-font-size: 0.9rem;
  --ct-body-font-weight: 400;
  --ct-body-line-height: 1.5;
  --ct-body-color: #6c757d;
  --ct-body-color-rgb: 108, 117, 125;
  --ct-body-bg: #fafbfe;
  --ct-body-bg-rgb: 250, 251, 254;
  --ct-emphasis-color: rgba(108, 117, 125, 0.75);
  --ct-emphasis-color-rgb: 108, 117, 125;
  --ct-secondary-color: #8a969c;
  --ct-secondary-color-rgb: 138, 150, 156;
  --ct-secondary-bg: #fff;
  --ct-secondary-bg-rgb: 255, 255, 255;
  --ct-tertiary-color: #6c757d;
  --ct-tertiary-color-rgb: 108, 117, 125;
  --ct-tertiary-bg: #f6f7fb;
  --ct-tertiary-bg-rgb: 246, 247, 251;
  --ct-heading-color: inherit;
  --ct-link-color: #727cf5;
  --ct-link-color-rgb: 114, 124, 245;
  --ct-link-decoration: none;
  --ct-link-hover-color: #6169d0;
  --ct-link-hover-color-rgb: 97, 105, 208;
  --ct-code-color: #39afd1;
  --ct-highlight-color: #6c757d;
  --ct-highlight-bg: #fff3de;
  --ct-border-width: 1px;
  --ct-border-style: solid;
  --ct-border-color: #dee2e6;
  --ct-border-color-translucent: #dee2e6;
  --ct-border-radius: 0.25rem;
  --ct-border-radius-sm: 0.2rem;
  --ct-border-radius-lg: 0.3rem;
  --ct-border-radius-xl: 1rem;
  --ct-border-radius-xxl: 2rem;
  --ct-border-radius-2xl: var(--ct-border-radius-xxl);
  --ct-border-radius-pill: 50rem;
  --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--ct-body-color-rgb), 0.15);
  --ct-box-shadow-lg: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  --ct-box-shadow-inset: inset 0 1px 2px rgba(var(--ct-body-color-rgb), 0.075);
  --ct-focus-ring-width: 0.15rem;
  --ct-focus-ring-opacity: 0.25;
  --ct-focus-ring-color: rgba(114, 124, 245, 0.25);
  --ct-form-valid-color: #0acf97;
  --ct-form-valid-border-color: #0acf97;
  --ct-form-invalid-color: #fa5c7c;
  --ct-form-invalid-border-color: #fa5c7c;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --ct-body-color: #aab8c5;
  --ct-body-color-rgb: 170, 184, 197;
  --ct-body-bg: #343a40;
  --ct-body-bg-rgb: 52, 58, 64;
  --ct-emphasis-color: #dee2e6;
  --ct-emphasis-color-rgb: 222, 226, 230;
  --ct-secondary-color: #8391a2;
  --ct-secondary-color-rgb: 131, 145, 162;
  --ct-secondary-bg: #37404a;
  --ct-secondary-bg-rgb: 55, 64, 74;
  --ct-tertiary-color: #f1f1f1;
  --ct-tertiary-color-rgb: 241, 241, 241;
  --ct-tertiary-bg: #404954;
  --ct-tertiary-bg-rgb: 64, 73, 84;
  --ct-primary-text-emphasis: #aab0f9;
  --ct-secondary-text-emphasis: #a7acb1;
  --ct-success-text-emphasis: #6ce2c1;
  --ct-info-text-emphasis: #88cfe3;
  --ct-warning-text-emphasis: #ffdb9c;
  --ct-danger-text-emphasis: #fc9db0;
  --ct-light-text-emphasis: #f6f7fb;
  --ct-dark-text-emphasis: #dee2e6;
  --ct-primary-bg-subtle: rgba(114, 124, 245, 0.15);
  --ct-secondary-bg-subtle: rgba(108, 117, 125, 0.15);
  --ct-success-bg-subtle: rgba(10, 207, 151, 0.15);
  --ct-info-bg-subtle: rgba(57, 175, 209, 0.15);
  --ct-warning-bg-subtle: rgba(255, 195, 90, 0.15);
  --ct-danger-bg-subtle: rgba(250, 92, 124, 0.15);
  --ct-light-bg-subtle: rgba(var(--ct-light-rgb), 0.15);
  --ct-dark-bg-subtle: rgba(var(--ct-dark-rgb), 0.15);
  --ct-primary-border-subtle: #444a93;
  --ct-secondary-border-subtle: #6c757d;
  --ct-success-border-subtle: #067c5b;
  --ct-info-border-subtle: #22697d;
  --ct-warning-border-subtle: #997536;
  --ct-danger-border-subtle: #96374a;
  --ct-light-border-subtle: #6c757d;
  --ct-dark-border-subtle: #a1a9b1;
  --ct-heading-color: #aab8c5;
  --ct-link-color: #aab0f9;
  --ct-link-hover-color: #b7bcfa;
  --ct-link-color-rgb: 170, 176, 249;
  --ct-link-hover-color-rgb: 183, 188, 250;
  --ct-code-color: #88cfe3;
  --ct-highlight-color: #aab8c5;
  --ct-highlight-bg: #664e24;
  --ct-border-color: #464f5b;
  --ct-border-color-translucent: #8391a2;
  --ct-form-valid-color: #6ce2c1;
  --ct-form-valid-border-color: #6ce2c1;
  --ct-form-invalid-color: #fc9db0;
  --ct-form-invalid-border-color: #fc9db0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--ct-body-font-family);
  font-size: var(--ct-body-font-size);
  font-weight: var(--ct-body-font-weight);
  line-height: var(--ct-body-line-height);
  color: var(--ct-body-color);
  text-align: var(--ct-body-text-align);
  background-color: var(--ct-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.0005rem 0;
  color: inherit;
  border: 0;
  border-top: var(--ct-border-width) solid var(--ct-border-color);
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.1;
  color: var(--ct-heading-color);
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3122rem + 0.7464vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.872rem;
  }
}

h3, .h3 {
  font-size: calc(1.2753rem + 0.3036vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.503rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 0.936rem;
}

h6, .h6 {
  font-size: 0.765rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.75rem;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--ct-highlight-color);
  background-color: var(--ct-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --ct-link-color-rgb: var(--ct-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--ct-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.75rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.75rem;
  color: var(--ct-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
  color: var(--ct-body-bg);
  background-color: var(--ct-body-color);
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  color: var(--ct-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  color: var(--ct-secondary-color);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--ct-body-bg);
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: var(--ct-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.75rem;
  color: var(--ct-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--ct-gutter-x) * 0.5);
  padding-left: calc(var(--ct-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --ct-breakpoint-xs: 0;
  --ct-breakpoint-sm: 576px;
  --ct-breakpoint-md: 768px;
  --ct-breakpoint-lg: 992px;
  --ct-breakpoint-xl: 1200px;
  --ct-breakpoint-xxl: 1400px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var(--ct-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--ct-gutter-x) * 0.5);
  padding-left: calc(var(--ct-gutter-x) * 0.5);
  margin-top: var(--ct-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --ct-gutter-x: 0;
}

.g-0,
.gy-0 {
  --ct-gutter-y: 0;
}

.g-1,
.gx-1 {
  --ct-gutter-x: 0.375rem;
}

.g-1,
.gy-1 {
  --ct-gutter-y: 0.375rem;
}

.g-2,
.gx-2 {
  --ct-gutter-x: 0.75rem;
}

.g-2,
.gy-2 {
  --ct-gutter-y: 0.75rem;
}

.g-3,
.gx-3 {
  --ct-gutter-x: 1.5rem;
}

.g-3,
.gy-3 {
  --ct-gutter-y: 1.5rem;
}

.g-4,
.gx-4 {
  --ct-gutter-x: 2.25rem;
}

.g-4,
.gy-4 {
  --ct-gutter-y: 2.25rem;
}

.g-5,
.gx-5 {
  --ct-gutter-x: 4.5rem;
}

.g-5,
.gy-5 {
  --ct-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --ct-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --ct-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --ct-gutter-y: 4.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --ct-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --ct-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-md-1,
  .gy-md-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-md-2,
  .gx-md-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-md-2,
  .gy-md-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-md-3,
  .gx-md-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-md-3,
  .gy-md-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-md-4,
  .gx-md-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-md-5,
  .gy-md-5 {
    --ct-gutter-y: 4.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --ct-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --ct-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --ct-gutter-y: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --ct-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --ct-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --ct-gutter-y: 4.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --ct-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --ct-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --ct-gutter-y: 4.5rem;
  }
}
.table {
  --ct-table-color-type: initial;
  --ct-table-bg-type: initial;
  --ct-table-color-state: initial;
  --ct-table-bg-state: initial;
  --ct-table-color: var(--ct-body-color);
  --ct-table-bg: transparent;
  --ct-table-border-color: var(--ct-border-color);
  --ct-table-accent-bg: transparent;
  --ct-table-striped-color: var(--ct-body-color);
  --ct-table-striped-bg: rgba(var(--ct-tertiary-bg-rgb), 1);
  --ct-table-active-color: var(--ct-body-color);
  --ct-table-active-bg: rgba(var(--ct-tertiary-bg-rgb), 1);
  --ct-table-hover-color: var(--ct-body-color);
  --ct-table-hover-bg: rgba(var(--ct-tertiary-bg-rgb), 1);
  width: 100%;
  margin-bottom: 1.5rem;
  vertical-align: top;
  border-color: var(--ct-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.95rem 0.95rem;
  color: var(--ct-table-color-state, var(--ct-table-color-type, var(--ct-table-color)));
  background-color: var(--ct-table-bg);
  border-bottom-width: var(--ct-border-width);
  box-shadow: inset 0 0 0 9999px var(--ct-table-bg-state, var(--ct-table-bg-type, var(--ct-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--ct-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--ct-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--ct-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --ct-table-color-type: var(--ct-table-striped-color);
  --ct-table-bg-type: var(--ct-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --ct-table-color-type: var(--ct-table-striped-color);
  --ct-table-bg-type: var(--ct-table-striped-bg);
}

.table-active {
  --ct-table-color-state: var(--ct-table-active-color);
  --ct-table-bg-state: var(--ct-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --ct-table-color-state: var(--ct-table-hover-color);
  --ct-table-bg-state: var(--ct-table-hover-bg);
}

.table-primary {
  --ct-table-color: #313a46;
  --ct-table-bg: #e3e5fd;
  --ct-table-border-color: #d1d4eb;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-secondary {
  --ct-table-color: #313a46;
  --ct-table-bg: #e2e3e5;
  --ct-table-border-color: #d0d2d5;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-success {
  --ct-table-color: #313a46;
  --ct-table-bg: #cef5ea;
  --ct-table-border-color: #bee2da;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-info {
  --ct-table-color: #313a46;
  --ct-table-bg: #d7eff6;
  --ct-table-border-color: #c6dde4;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-warning {
  --ct-table-color: #313a46;
  --ct-table-bg: #fff3de;
  --ct-table-border-color: #eae1cf;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-danger {
  --ct-table-color: #313a46;
  --ct-table-bg: #fedee5;
  --ct-table-border-color: #eaced5;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-light {
  --ct-table-color: #313a46;
  --ct-table-bg: #eef2f7;
  --ct-table-border-color: #dbe0e5;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-dark {
  --ct-table-color: #fff;
  --ct-table-bg: #313a46;
  --ct-table-border-color: #464e59;
  --ct-table-striped-bg: white;
  --ct-table-striped-color: #313a46;
  --ct-table-active-bg: white;
  --ct-table-active-color: #313a46;
  --ct-table-hover-bg: white;
  --ct-table-hover-color: #313a46;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.45rem + var(--ct-border-width));
  padding-bottom: calc(0.45rem + var(--ct-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--ct-border-width));
  padding-bottom: calc(0.5rem + var(--ct-border-width));
  font-size: calc(1.2501rem + 0.0012vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.251rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.28rem + var(--ct-border-width));
  padding-bottom: calc(0.28rem + var(--ct-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--ct-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--ct-body-color);
  appearance: none;
  background-color: var(--ct-input-bg);
  background-clip: padding-box;
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--ct-body-color);
  background-color: var(--ct-input-bg);
  border-color: var(--ct-border-color);
  outline: 0;
  box-shadow: none;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--ct-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--ct-tertiary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.45rem 0.9rem;
  margin: -0.45rem -0.9rem;
  margin-inline-end: 0.9rem;
  color: var(--ct-body-color);
  background-color: var(--ct-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--ct-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--ct-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.45rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--ct-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--ct-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.56rem + calc(var(--ct-border-width) * 2));
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.28rem 0.8rem;
  margin: -0.28rem -0.8rem;
  margin-inline-end: 0.8rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--ct-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.251rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.56rem + calc(var(--ct-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--ct-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
  padding: 0.45rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.25rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.56rem + calc(var(--ct-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--ct-border-width) * 2));
}

.form-select {
  --ct-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.45rem 2.7rem 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--ct-body-color);
  appearance: none;
  background-color: var(--ct-input-bg);
  background-image: var(--ct-form-select-bg-img), var(--ct-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.9rem center;
  background-size: 14px 10px;
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: var(--ct-border-color);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(114, 124, 245, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.9rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--ct-tertiary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--ct-body-color);
}

.form-select-sm {
  padding-top: 0.28rem;
  padding-bottom: 0.28rem;
  padding-left: 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.251rem;
  }
}

[data-bs-theme=dark] .form-select {
  --ct-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23aab8c5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.35rem;
  padding-left: 1.612em;
  margin-bottom: 0;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.612em;
}

.form-check-reverse {
  padding-right: 1.612em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.612em;
  margin-left: 0;
}

.form-check-input {
  --ct-form-check-bg: var(--ct-secondary-bg);
  flex-shrink: 0;
  width: 1.112em;
  height: 1.112em;
  margin-top: 0.194em;
  vertical-align: top;
  appearance: none;
  background-color: var(--ct-form-check-bg);
  background-image: var(--ct-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--ct-border-width) solid var(--ct-border-color-translucent);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: var(--ct-border-color);
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(114, 124, 245, 0.25);
}
.form-check-input:checked {
  background-color: #727cf5;
  border-color: #727cf5;
}
.form-check-input:checked[type=checkbox] {
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #727cf5;
  border-color: #727cf5;
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--ct-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--ct-border-color%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.3rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fafbfe, none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fafbfe, none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #727cf5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #d5d8fc;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--ct-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #727cf5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #d5d8fc;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--ct-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--ct-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--ct-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--ct-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--ct-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.9rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--ct-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.9rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--ct-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.45rem;
  z-index: -1;
  height: 1.875em;
  content: "";
  background-color: var(--ct-input-bg);
  border-radius: 0.25rem;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--ct-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--ct-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #8a969c;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--ct-tertiary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--ct-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ct-tertiary-bg);
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.251rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.6rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--ct-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--ct-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--ct-success);
  border-radius: var(--ct-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--ct-form-valid-border-color);
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--ct-form-valid-border-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--ct-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --ct-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.95rem;
  background-position: right 0.9rem center, center right 2.7rem;
  background-size: 14px 10px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--ct-form-valid-border-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.9rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--ct-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--ct-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--ct-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--ct-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--ct-danger);
  border-radius: var(--ct-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--ct-form-invalid-border-color);
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--ct-form-invalid-border-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--ct-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --ct-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.95rem;
  background-position: right 0.9rem center, center right 2.7rem;
  background-size: 14px 10px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--ct-form-invalid-border-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.9rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--ct-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--ct-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--ct-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --ct-btn-padding-x: 0.9rem;
  --ct-btn-padding-y: 0.45rem;
  --ct-btn-font-family: ;
  --ct-btn-font-size: 0.9rem;
  --ct-btn-font-weight: 400;
  --ct-btn-line-height: 1.5;
  --ct-btn-color: var(--ct-body-color);
  --ct-btn-bg: transparent;
  --ct-btn-border-width: var(--ct-border-width);
  --ct-btn-border-color: transparent;
  --ct-btn-border-radius: 0.15rem;
  --ct-btn-hover-border-color: transparent;
  --ct-btn-box-shadow: 0px 2px 6px 0px;
  --ct-btn-disabled-opacity: 0.65;
  --ct-btn-focus-box-shadow: 0 0 0 0.15rem rgba(var(--ct-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--ct-btn-padding-y) var(--ct-btn-padding-x);
  font-family: var(--ct-btn-font-family);
  font-size: var(--ct-btn-font-size);
  font-weight: var(--ct-btn-font-weight);
  line-height: var(--ct-btn-line-height);
  color: var(--ct-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--ct-btn-border-width) solid var(--ct-btn-border-color);
  border-radius: var(--ct-btn-border-radius);
  background-color: var(--ct-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--ct-btn-hover-color);
  background-color: var(--ct-btn-hover-bg);
  border-color: var(--ct-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--ct-btn-color);
  background-color: var(--ct-btn-bg);
  border-color: var(--ct-btn-border-color);
}
.btn:focus-visible {
  color: var(--ct-btn-hover-color);
  background-color: var(--ct-btn-hover-bg);
  border-color: var(--ct-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--ct-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--ct-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--ct-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--ct-btn-active-color);
  background-color: var(--ct-btn-active-bg);
  border-color: var(--ct-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--ct-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--ct-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--ct-btn-disabled-color);
  pointer-events: none;
  background-color: var(--ct-btn-disabled-bg);
  border-color: var(--ct-btn-disabled-border-color);
  opacity: var(--ct-btn-disabled-opacity);
}

.btn-primary {
  --ct-btn-color: #fff;
  --ct-btn-bg: #727cf5;
  --ct-btn-border-color: #727cf5;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #6973e3;
  --ct-btn-hover-border-color: #6973e3;
  --ct-btn-focus-shadow-rgb: 135, 144, 247;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #6973e3;
  --ct-btn-active-border-color: #6973e3;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #727cf5;
  --ct-btn-disabled-border-color: #727cf5;
}

.btn-secondary {
  --ct-btn-color: #fff;
  --ct-btn-bg: #6c757d;
  --ct-btn-border-color: #6c757d;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #646c74;
  --ct-btn-hover-border-color: #646c74;
  --ct-btn-focus-shadow-rgb: 130, 138, 145;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #646c74;
  --ct-btn-active-border-color: #646c74;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #6c757d;
  --ct-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --ct-btn-color: #fff;
  --ct-btn-bg: #0acf97;
  --ct-btn-border-color: #0acf97;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #09bf8c;
  --ct-btn-hover-border-color: #09bf8c;
  --ct-btn-focus-shadow-rgb: 47, 214, 167;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #09bf8c;
  --ct-btn-active-border-color: #09bf8c;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #0acf97;
  --ct-btn-disabled-border-color: #0acf97;
}

.btn-info {
  --ct-btn-color: #fff;
  --ct-btn-bg: #39afd1;
  --ct-btn-border-color: #39afd1;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #35a2c1;
  --ct-btn-hover-border-color: #35a2c1;
  --ct-btn-focus-shadow-rgb: 87, 187, 216;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #35a2c1;
  --ct-btn-active-border-color: #35a2c1;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #39afd1;
  --ct-btn-disabled-border-color: #39afd1;
}

.btn-warning {
  --ct-btn-color: #fff;
  --ct-btn-bg: #ffc35a;
  --ct-btn-border-color: #ffc35a;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #ecb453;
  --ct-btn-hover-border-color: #ecb453;
  --ct-btn-focus-shadow-rgb: 255, 204, 115;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #ecb453;
  --ct-btn-active-border-color: #ecb453;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #ffc35a;
  --ct-btn-disabled-border-color: #ffc35a;
}

.btn-danger {
  --ct-btn-color: #fff;
  --ct-btn-bg: #fa5c7c;
  --ct-btn-border-color: #fa5c7c;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #e75573;
  --ct-btn-hover-border-color: #e75573;
  --ct-btn-focus-shadow-rgb: 251, 116, 144;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #e75573;
  --ct-btn-active-border-color: #e75573;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #fa5c7c;
  --ct-btn-disabled-border-color: #fa5c7c;
}

.btn-light {
  --ct-btn-color: #313a46;
  --ct-btn-bg: #eef2f7;
  --ct-btn-border-color: #eef2f7;
  --ct-btn-hover-color: #313a46;
  --ct-btn-hover-bg: #dce0e4;
  --ct-btn-hover-border-color: #dce0e4;
  --ct-btn-focus-shadow-rgb: 210, 214, 220;
  --ct-btn-active-color: #313a46;
  --ct-btn-active-bg: #dce0e4;
  --ct-btn-active-border-color: #dce0e4;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #313a46;
  --ct-btn-disabled-bg: #eef2f7;
  --ct-btn-disabled-border-color: #eef2f7;
}

.btn-dark {
  --ct-btn-color: #fff;
  --ct-btn-bg: #313a46;
  --ct-btn-border-color: #313a46;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #505862;
  --ct-btn-hover-border-color: #464e59;
  --ct-btn-focus-shadow-rgb: 80, 88, 98;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #505862;
  --ct-btn-active-border-color: #464e59;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #313a46;
  --ct-btn-disabled-border-color: #313a46;
}

.btn-outline-primary {
  --ct-btn-color: #727cf5;
  --ct-btn-border-color: #727cf5;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #727cf5;
  --ct-btn-hover-border-color: #727cf5;
  --ct-btn-focus-shadow-rgb: 114, 124, 245;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #727cf5;
  --ct-btn-active-border-color: #727cf5;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #727cf5;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #727cf5;
  --ct-gradient: none;
}

.btn-outline-secondary {
  --ct-btn-color: #6c757d;
  --ct-btn-border-color: #6c757d;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #6c757d;
  --ct-btn-hover-border-color: #6c757d;
  --ct-btn-focus-shadow-rgb: 108, 117, 125;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #6c757d;
  --ct-btn-active-border-color: #6c757d;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #6c757d;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #6c757d;
  --ct-gradient: none;
}

.btn-outline-success {
  --ct-btn-color: #0acf97;
  --ct-btn-border-color: #0acf97;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #0acf97;
  --ct-btn-hover-border-color: #0acf97;
  --ct-btn-focus-shadow-rgb: 10, 207, 151;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #0acf97;
  --ct-btn-active-border-color: #0acf97;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #0acf97;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #0acf97;
  --ct-gradient: none;
}

.btn-outline-info {
  --ct-btn-color: #39afd1;
  --ct-btn-border-color: #39afd1;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #39afd1;
  --ct-btn-hover-border-color: #39afd1;
  --ct-btn-focus-shadow-rgb: 57, 175, 209;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #39afd1;
  --ct-btn-active-border-color: #39afd1;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #39afd1;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #39afd1;
  --ct-gradient: none;
}

.btn-outline-warning {
  --ct-btn-color: #ffc35a;
  --ct-btn-border-color: #ffc35a;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #ffc35a;
  --ct-btn-hover-border-color: #ffc35a;
  --ct-btn-focus-shadow-rgb: 255, 195, 90;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #ffc35a;
  --ct-btn-active-border-color: #ffc35a;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #ffc35a;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #ffc35a;
  --ct-gradient: none;
}

.btn-outline-danger {
  --ct-btn-color: #fa5c7c;
  --ct-btn-border-color: #fa5c7c;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #fa5c7c;
  --ct-btn-hover-border-color: #fa5c7c;
  --ct-btn-focus-shadow-rgb: 250, 92, 124;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #fa5c7c;
  --ct-btn-active-border-color: #fa5c7c;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fa5c7c;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #fa5c7c;
  --ct-gradient: none;
}

.btn-outline-light {
  --ct-btn-color: #eef2f7;
  --ct-btn-border-color: #eef2f7;
  --ct-btn-hover-color: #313a46;
  --ct-btn-hover-bg: #eef2f7;
  --ct-btn-hover-border-color: #eef2f7;
  --ct-btn-focus-shadow-rgb: 238, 242, 247;
  --ct-btn-active-color: #313a46;
  --ct-btn-active-bg: #eef2f7;
  --ct-btn-active-border-color: #eef2f7;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #eef2f7;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #eef2f7;
  --ct-gradient: none;
}

.btn-outline-dark {
  --ct-btn-color: #313a46;
  --ct-btn-border-color: #313a46;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #313a46;
  --ct-btn-hover-border-color: #313a46;
  --ct-btn-focus-shadow-rgb: 49, 58, 70;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #313a46;
  --ct-btn-active-border-color: #313a46;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #313a46;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #313a46;
  --ct-gradient: none;
}

.btn-link {
  --ct-btn-font-weight: 400;
  --ct-btn-color: var(--ct-link-color);
  --ct-btn-bg: transparent;
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: var(--ct-link-hover-color);
  --ct-btn-hover-border-color: transparent;
  --ct-btn-active-color: var(--ct-link-hover-color);
  --ct-btn-active-border-color: transparent;
  --ct-btn-disabled-color: #8a969c;
  --ct-btn-disabled-border-color: transparent;
  --ct-btn-box-shadow: 0 0 0 #000;
  --ct-btn-focus-shadow-rgb: 135, 144, 247;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--ct-btn-color);
}
.btn-link:hover {
  color: var(--ct-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --ct-btn-padding-y: 0.5rem;
  --ct-btn-padding-x: 1rem;
  --ct-btn-font-size: calc(1.2501rem + 0.0012vw);
  --ct-btn-border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    --ct-btn-font-size: 1.251rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --ct-btn-padding-y: 0.28rem;
  --ct-btn-padding-x: 0.8rem;
  --ct-btn-font-size: 0.875rem;
  --ct-btn-border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --ct-dropdown-zindex: 1000;
  --ct-dropdown-min-width: 10rem;
  --ct-dropdown-padding-x: 0;
  --ct-dropdown-padding-y: 0.25rem;
  --ct-dropdown-spacer: 0.125rem;
  --ct-dropdown-font-size: 0.9rem;
  --ct-dropdown-color: var(--ct-body-color);
  --ct-dropdown-bg: var(--ct-secondary-bg);
  --ct-dropdown-border-color: var(--ct-border-color);
  --ct-dropdown-border-radius: 0.25rem;
  --ct-dropdown-border-width: var(--ct-border-width);
  --ct-dropdown-inner-border-radius: calc(0.25rem - var(--ct-border-width));
  --ct-dropdown-divider-bg: var(--ct-border-color);
  --ct-dropdown-divider-margin-y: 0.75rem;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: var(--ct-body-color);
  --ct-dropdown-link-hover-color: var(--ct-emphasis-color);
  --ct-dropdown-link-hover-bg: var(--ct-tertiary-bg);
  --ct-dropdown-link-active-color: var(--ct-emphasis-color);
  --ct-dropdown-link-active-bg: var(--ct-tertiary-bg);
  --ct-dropdown-link-disabled-color: var(--ct-emphasis-color);
  --ct-dropdown-item-padding-x: 1.5rem;
  --ct-dropdown-item-padding-y: 0.375rem;
  --ct-dropdown-header-color: inherit;
  --ct-dropdown-header-padding-x: 1.125rem;
  --ct-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--ct-dropdown-zindex);
  display: none;
  min-width: var(--ct-dropdown-min-width);
  padding: var(--ct-dropdown-padding-y) var(--ct-dropdown-padding-x);
  margin: 0;
  font-size: var(--ct-dropdown-font-size);
  color: var(--ct-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--ct-dropdown-bg);
  background-clip: padding-box;
  border: var(--ct-dropdown-border-width) solid var(--ct-dropdown-border-color);
  border-radius: var(--ct-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--ct-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--ct-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--ct-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--ct-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--ct-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--ct-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--ct-dropdown-item-padding-y) var(--ct-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--ct-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--ct-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--ct-dropdown-link-hover-color);
  background-color: var(--ct-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--ct-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--ct-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--ct-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--ct-dropdown-header-padding-y) var(--ct-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--ct-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--ct-dropdown-item-padding-y) var(--ct-dropdown-item-padding-x);
  color: var(--ct-dropdown-link-color);
}

.dropdown-menu-dark {
  --ct-dropdown-color: #dee2e6;
  --ct-dropdown-bg: #343a40;
  --ct-dropdown-border-color: var(--ct-border-color);
  --ct-dropdown-box-shadow: ;
  --ct-dropdown-link-color: #dee2e6;
  --ct-dropdown-link-hover-color: #fff;
  --ct-dropdown-divider-bg: var(--ct-border-color);
  --ct-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --ct-dropdown-link-active-color: var(--ct-emphasis-color);
  --ct-dropdown-link-active-bg: var(--ct-tertiary-bg);
  --ct-dropdown-link-disabled-color: #a1a9b1;
  --ct-dropdown-header-color: #a1a9b1;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.15rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--ct-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--ct-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --ct-nav-link-padding-x: 1rem;
  --ct-nav-link-padding-y: 0.5rem;
  --ct-nav-link-font-weight: ;
  --ct-nav-link-color: var(--ct-link-color);
  --ct-nav-link-hover-color: var(--ct-link-hover-color);
  --ct-nav-link-disabled-color: var(--ct-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--ct-nav-link-padding-y) var(--ct-nav-link-padding-x);
  font-size: var(--ct-nav-link-font-size);
  font-weight: var(--ct-nav-link-font-weight);
  color: var(--ct-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--ct-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(114, 124, 245, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--ct-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --ct-nav-tabs-border-width: var(--ct-border-width);
  --ct-nav-tabs-border-color: var(--ct-border-color);
  --ct-nav-tabs-border-radius: var(--ct-border-radius);
  --ct-nav-tabs-link-hover-border-color: var(--ct-secondary-bg) var(--ct-secondary-bg) var(--ct-border-color);
  --ct-nav-tabs-link-active-color: var(--ct-emphasis-color);
  --ct-nav-tabs-link-active-bg: var(--ct-secondary-bg);
  --ct-nav-tabs-link-active-border-color: var(--ct-border-color) var(--ct-border-color) var(--ct-secondary-bg);
  border-bottom: var(--ct-nav-tabs-border-width) solid var(--ct-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--ct-nav-tabs-border-width));
  border: var(--ct-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--ct-nav-tabs-border-radius);
  border-top-right-radius: var(--ct-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--ct-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--ct-nav-tabs-link-active-color);
  background-color: var(--ct-nav-tabs-link-active-bg);
  border-color: var(--ct-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--ct-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --ct-nav-pills-border-radius: 0.25rem;
  --ct-nav-pills-link-active-color: #fff;
  --ct-nav-pills-link-active-bg: #727cf5;
}
.nav-pills .nav-link {
  border-radius: var(--ct-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--ct-nav-pills-link-active-color);
  background-color: var(--ct-nav-pills-link-active-bg);
}

.nav-underline {
  --ct-nav-underline-gap: 1rem;
  --ct-nav-underline-border-width: 0.125rem;
  --ct-nav-underline-link-active-color: var(--ct-emphasis-color);
  gap: var(--ct-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--ct-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--ct-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --ct-navbar-padding-x: 0;
  --ct-navbar-padding-y: 0.75rem;
  --ct-navbar-color: rgba(var(--ct-emphasis-color-rgb), 0.65);
  --ct-navbar-hover-color: rgba(var(--ct-emphasis-color-rgb), 0.8);
  --ct-navbar-disabled-color: rgba(var(--ct-emphasis-color-rgb), 0.3);
  --ct-navbar-active-color: rgba(var(--ct-emphasis-color-rgb), 1);
  --ct-navbar-brand-padding-y: 0.23675rem;
  --ct-navbar-brand-margin-end: 1rem;
  --ct-navbar-brand-font-size: 1.251rem;
  --ct-navbar-brand-color: rgba(var(--ct-emphasis-color-rgb), 1);
  --ct-navbar-brand-hover-color: rgba(var(--ct-emphasis-color-rgb), 1);
  --ct-navbar-nav-link-padding-x: 0.5rem;
  --ct-navbar-toggler-padding-y: 0.25rem;
  --ct-navbar-toggler-padding-x: 0.75rem;
  --ct-navbar-toggler-font-size: 1.251rem;
  --ct-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28108, 117, 125, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --ct-navbar-toggler-border-color: rgba(var(--ct-emphasis-color-rgb), 0.15);
  --ct-navbar-toggler-border-radius: 0.15rem;
  --ct-navbar-toggler-focus-width: 0.15rem;
  --ct-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--ct-navbar-padding-y) var(--ct-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--ct-navbar-brand-padding-y);
  padding-bottom: var(--ct-navbar-brand-padding-y);
  margin-right: var(--ct-navbar-brand-margin-end);
  font-size: var(--ct-navbar-brand-font-size);
  color: var(--ct-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--ct-navbar-brand-hover-color);
}

.navbar-nav {
  --ct-nav-link-padding-x: 0;
  --ct-nav-link-padding-y: 0.5rem;
  --ct-nav-link-font-weight: ;
  --ct-nav-link-color: var(--ct-navbar-color);
  --ct-nav-link-hover-color: var(--ct-navbar-hover-color);
  --ct-nav-link-disabled-color: var(--ct-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--ct-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--ct-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--ct-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--ct-navbar-toggler-padding-y) var(--ct-navbar-toggler-padding-x);
  font-size: var(--ct-navbar-toggler-font-size);
  line-height: 1;
  color: var(--ct-navbar-color);
  background-color: transparent;
  border: var(--ct-border-width) solid var(--ct-navbar-toggler-border-color);
  border-radius: var(--ct-navbar-toggler-border-radius);
  transition: var(--ct-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--ct-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--ct-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--ct-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--ct-navbar-nav-link-padding-x);
  padding-left: var(--ct-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --ct-navbar-color: rgba(255, 255, 255, 0.55);
  --ct-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --ct-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --ct-navbar-active-color: #fff;
  --ct-navbar-brand-color: #fff;
  --ct-navbar-brand-hover-color: #fff;
  --ct-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --ct-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --ct-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --ct-card-spacer-y: 1.5rem;
  --ct-card-spacer-x: 1.5rem;
  --ct-card-title-spacer-y: 0.75rem;
  --ct-card-title-color: ;
  --ct-card-subtitle-color: ;
  --ct-card-border-width: var(--ct-theme-card-border-width);
  --ct-card-border-color: var(--ct-border-color);
  --ct-card-border-radius: var(--ct-border-radius);
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-inner-border-radius: calc(var(--ct-border-radius) - (var(--ct-theme-card-border-width)));
  --ct-card-cap-padding-y: 1.125rem;
  --ct-card-cap-padding-x: 1.5rem;
  --ct-card-cap-bg: transparent;
  --ct-card-cap-color: ;
  --ct-card-height: ;
  --ct-card-color: ;
  --ct-card-bg: var(--ct-secondary-bg);
  --ct-card-img-overlay-padding: 1.5rem;
  --ct-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--ct-card-height);
  color: var(--ct-body-color);
  word-wrap: break-word;
  background-color: var(--ct-card-bg);
  background-clip: border-box;
  border: var(--ct-card-border-width) solid var(--ct-card-border-color);
  border-radius: var(--ct-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--ct-card-inner-border-radius);
  border-top-right-radius: var(--ct-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--ct-card-inner-border-radius);
  border-bottom-left-radius: var(--ct-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--ct-card-spacer-y) var(--ct-card-spacer-x);
  color: var(--ct-card-color);
}

.card-title {
  margin-bottom: var(--ct-card-title-spacer-y);
  color: var(--ct-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--ct-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--ct-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--ct-card-spacer-x);
}

.card-header {
  padding: var(--ct-card-cap-padding-y) var(--ct-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--ct-card-cap-color);
  background-color: var(--ct-card-cap-bg);
  border-bottom: var(--ct-card-border-width) solid var(--ct-card-border-color);
}
.card-header:first-child {
  border-radius: var(--ct-card-inner-border-radius) var(--ct-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--ct-card-cap-padding-y) var(--ct-card-cap-padding-x);
  color: var(--ct-card-cap-color);
  background-color: var(--ct-card-cap-bg);
  border-top: var(--ct-card-border-width) solid var(--ct-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--ct-card-inner-border-radius) var(--ct-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--ct-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--ct-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--ct-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--ct-card-bg);
  border-bottom-color: var(--ct-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--ct-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--ct-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--ct-card-img-overlay-padding);
  border-radius: var(--ct-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--ct-card-inner-border-radius);
  border-top-right-radius: var(--ct-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--ct-card-inner-border-radius);
  border-bottom-left-radius: var(--ct-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--ct-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --ct-accordion-color: var(--ct-body-color);
  --ct-accordion-bg: var(--ct-secondary-bg);
  --ct-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --ct-accordion-border-color: var(--ct-border-color);
  --ct-accordion-border-width: var(--ct-border-width);
  --ct-accordion-border-radius: var(--ct-border-radius);
  --ct-accordion-inner-border-radius: calc(var(--ct-border-radius) - (var(--ct-border-width)));
  --ct-accordion-btn-padding-x: 1.25rem;
  --ct-accordion-btn-padding-y: 1rem;
  --ct-accordion-btn-color: var(--ct-body-color);
  --ct-accordion-btn-bg: var(--ct-accordion-bg);
  --ct-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236c757d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --ct-accordion-btn-icon-width: 0.75rem;
  --ct-accordion-btn-icon-transform: rotate(-180deg);
  --ct-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --ct-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232e3262'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --ct-accordion-btn-focus-box-shadow: none;
  --ct-accordion-body-padding-x: 1.25rem;
  --ct-accordion-body-padding-y: 1rem;
  --ct-accordion-active-color: var(--ct-primary);
  --ct-accordion-active-bg: var(--ct-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--ct-accordion-btn-padding-y) var(--ct-accordion-btn-padding-x);
  font-size: 0.9rem;
  color: var(--ct-accordion-btn-color);
  text-align: left;
  background-color: var(--ct-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--ct-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--ct-accordion-active-color);
  background-color: var(--ct-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--ct-accordion-border-width)) 0 var(--ct-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--ct-accordion-btn-active-icon);
  transform: var(--ct-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--ct-accordion-btn-icon-width);
  height: var(--ct-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--ct-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--ct-accordion-btn-icon-width);
  transition: var(--ct-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--ct-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--ct-accordion-color);
  background-color: var(--ct-accordion-bg);
  border: var(--ct-accordion-border-width) solid var(--ct-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--ct-accordion-border-radius);
  border-top-right-radius: var(--ct-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--ct-accordion-inner-border-radius);
  border-top-right-radius: var(--ct-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--ct-accordion-border-radius);
  border-bottom-left-radius: var(--ct-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--ct-accordion-inner-border-radius);
  border-bottom-left-radius: var(--ct-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--ct-accordion-border-radius);
  border-bottom-left-radius: var(--ct-accordion-border-radius);
}

.accordion-body {
  padding: var(--ct-accordion-body-padding-y) var(--ct-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --ct-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23aab0f9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --ct-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23aab0f9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --ct-breadcrumb-padding-x: 0;
  --ct-breadcrumb-padding-y: 1.5rem;
  --ct-breadcrumb-margin-bottom: 1rem;
  --ct-breadcrumb-bg: ;
  --ct-breadcrumb-border-radius: ;
  --ct-breadcrumb-divider-color: var(--ct-secondary-color);
  --ct-breadcrumb-item-padding-x: 0.5rem;
  --ct-breadcrumb-item-active-color: var(--ct-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--ct-breadcrumb-padding-y) var(--ct-breadcrumb-padding-x);
  margin-bottom: var(--ct-breadcrumb-margin-bottom);
  font-size: var(--ct-breadcrumb-font-size);
  list-style: none;
  background-color: var(--ct-breadcrumb-bg);
  border-radius: var(--ct-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--ct-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--ct-breadcrumb-item-padding-x);
  color: var(--ct-breadcrumb-divider-color);
  content: var(--ct-breadcrumb-divider, "\f0142") /* rtl: var(--ct-breadcrumb-divider, "\f0142") */;
}
.breadcrumb-item.active {
  color: var(--ct-breadcrumb-item-active-color);
}

.pagination {
  --ct-pagination-padding-x: 0.75rem;
  --ct-pagination-padding-y: 0.375rem;
  --ct-pagination-font-size: 0.9rem;
  --ct-pagination-color: var(--ct-tertiary-color);
  --ct-pagination-bg: var(--ct-secondary-bg);
  --ct-pagination-border-width: var(--ct-border-width);
  --ct-pagination-border-color: var(--ct-border-color);
  --ct-pagination-border-radius: var(--ct-border-radius);
  --ct-pagination-hover-color: var(--ct-link-hover-color);
  --ct-pagination-hover-bg: var(--ct-tertiary-bg);
  --ct-pagination-hover-border-color: var(--ct-border-color);
  --ct-pagination-focus-color: var(--ct-link-hover-color);
  --ct-pagination-focus-bg: var(--ct-secondary-bg);
  --ct-pagination-focus-box-shadow: none;
  --ct-pagination-active-color: #fff;
  --ct-pagination-active-bg: #727cf5;
  --ct-pagination-active-border-color: #727cf5;
  --ct-pagination-disabled-color: var(--ct-secondary-color);
  --ct-pagination-disabled-bg: var(--ct-secondary-bg);
  --ct-pagination-disabled-border-color: var(--ct-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--ct-pagination-padding-y) var(--ct-pagination-padding-x);
  font-size: var(--ct-pagination-font-size);
  color: var(--ct-pagination-color);
  background-color: var(--ct-pagination-bg);
  border: var(--ct-pagination-border-width) solid var(--ct-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--ct-pagination-hover-color);
  background-color: var(--ct-pagination-hover-bg);
  border-color: var(--ct-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--ct-pagination-focus-color);
  background-color: var(--ct-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--ct-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--ct-pagination-active-color);
  background-color: var(--ct-pagination-active-bg);
  border-color: var(--ct-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--ct-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--ct-pagination-disabled-bg);
  border-color: var(--ct-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--ct-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--ct-pagination-border-radius);
  border-bottom-left-radius: var(--ct-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--ct-pagination-border-radius);
  border-bottom-right-radius: var(--ct-pagination-border-radius);
}

.pagination-lg {
  --ct-pagination-padding-x: 1.5rem;
  --ct-pagination-padding-y: 0.75rem;
  --ct-pagination-font-size: calc(1.2501rem + 0.0012vw);
  --ct-pagination-border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .pagination-lg {
    --ct-pagination-font-size: 1.251rem;
  }
}

.pagination-sm {
  --ct-pagination-padding-x: 0.5rem;
  --ct-pagination-padding-y: 0.25rem;
  --ct-pagination-font-size: 0.875rem;
  --ct-pagination-border-radius: 0.2rem;
}

.badge {
  --ct-badge-padding-x: 0.4em;
  --ct-badge-padding-y: 0.25em;
  --ct-badge-font-size: 0.75em;
  --ct-badge-font-weight: 700;
  --ct-badge-color: #fff;
  --ct-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--ct-badge-padding-y) var(--ct-badge-padding-x);
  font-size: var(--ct-badge-font-size);
  font-weight: var(--ct-badge-font-weight);
  line-height: 1;
  color: var(--ct-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--ct-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --ct-alert-bg: transparent;
  --ct-alert-padding-x: 1.25rem;
  --ct-alert-padding-y: 0.8rem;
  --ct-alert-margin-bottom: 1rem;
  --ct-alert-color: inherit;
  --ct-alert-border-color: transparent;
  --ct-alert-border: var(--ct-border-width) solid var(--ct-alert-border-color);
  --ct-alert-border-radius: 0.25rem;
  --ct-alert-link-color: inherit;
  position: relative;
  padding: var(--ct-alert-padding-y) var(--ct-alert-padding-x);
  margin-bottom: var(--ct-alert-margin-bottom);
  color: var(--ct-alert-color);
  background-color: var(--ct-alert-bg);
  border: var(--ct-alert-border);
  border-radius: var(--ct-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--ct-alert-link-color);
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1rem 1.25rem;
}

.alert-primary {
  --ct-alert-color: var(--ct-primary-text-emphasis);
  --ct-alert-bg: var(--ct-primary-bg-subtle);
  --ct-alert-border-color: var(--ct-primary-border-subtle);
  --ct-alert-link-color: var(--ct-primary-text-emphasis);
}

.alert-secondary {
  --ct-alert-color: var(--ct-secondary-text-emphasis);
  --ct-alert-bg: var(--ct-secondary-bg-subtle);
  --ct-alert-border-color: var(--ct-secondary-border-subtle);
  --ct-alert-link-color: var(--ct-secondary-text-emphasis);
}

.alert-success {
  --ct-alert-color: var(--ct-success-text-emphasis);
  --ct-alert-bg: var(--ct-success-bg-subtle);
  --ct-alert-border-color: var(--ct-success-border-subtle);
  --ct-alert-link-color: var(--ct-success-text-emphasis);
}

.alert-info {
  --ct-alert-color: var(--ct-info-text-emphasis);
  --ct-alert-bg: var(--ct-info-bg-subtle);
  --ct-alert-border-color: var(--ct-info-border-subtle);
  --ct-alert-link-color: var(--ct-info-text-emphasis);
}

.alert-warning {
  --ct-alert-color: var(--ct-warning-text-emphasis);
  --ct-alert-bg: var(--ct-warning-bg-subtle);
  --ct-alert-border-color: var(--ct-warning-border-subtle);
  --ct-alert-link-color: var(--ct-warning-text-emphasis);
}

.alert-danger {
  --ct-alert-color: var(--ct-danger-text-emphasis);
  --ct-alert-bg: var(--ct-danger-bg-subtle);
  --ct-alert-border-color: var(--ct-danger-border-subtle);
  --ct-alert-link-color: var(--ct-danger-text-emphasis);
}

.alert-light {
  --ct-alert-color: var(--ct-light-text-emphasis);
  --ct-alert-bg: var(--ct-light-bg-subtle);
  --ct-alert-border-color: var(--ct-light-border-subtle);
  --ct-alert-link-color: var(--ct-light-text-emphasis);
}

.alert-dark {
  --ct-alert-color: var(--ct-dark-text-emphasis);
  --ct-alert-bg: var(--ct-dark-bg-subtle);
  --ct-alert-border-color: var(--ct-dark-border-subtle);
  --ct-alert-link-color: var(--ct-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --ct-progress-height: 1rem;
  --ct-progress-font-size: 0.675rem;
  --ct-progress-bg: var(--ct-tertiary-bg);
  --ct-progress-border-radius: var(--ct-border-radius);
  --ct-progress-box-shadow: var(--ct-box-shadow-inset);
  --ct-progress-bar-color: #fff;
  --ct-progress-bar-bg: #727cf5;
  --ct-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--ct-progress-height);
  overflow: hidden;
  font-size: var(--ct-progress-font-size);
  background-color: var(--ct-progress-bg);
  border-radius: var(--ct-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--ct-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ct-progress-bar-bg);
  transition: var(--ct-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--ct-progress-height) var(--ct-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --ct-list-group-color: var(--ct-tertiary-color);
  --ct-list-group-bg: var(--ct-secondary-bg);
  --ct-list-group-border-color: var(--ct-border-color);
  --ct-list-group-border-width: var(--ct-border-width);
  --ct-list-group-border-radius: var(--ct-border-radius);
  --ct-list-group-item-padding-x: 1.2rem;
  --ct-list-group-item-padding-y: 0.75rem;
  --ct-list-group-action-color: var(--ct-secondary-color);
  --ct-list-group-action-hover-color: var(--ct-tertiary-color);
  --ct-list-group-action-hover-bg: var(--ct-tertiary-bg);
  --ct-list-group-action-active-color: var(--ct-body-color);
  --ct-list-group-action-active-bg: var(--ct-secondary-bg);
  --ct-list-group-disabled-color: var(--ct-secondary-color);
  --ct-list-group-disabled-bg: var(--ct-tertiary-bg);
  --ct-list-group-active-color: #fff;
  --ct-list-group-active-bg: #727cf5;
  --ct-list-group-active-border-color: #727cf5;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--ct-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--ct-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--ct-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--ct-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--ct-list-group-action-active-color);
  background-color: var(--ct-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--ct-list-group-item-padding-y) var(--ct-list-group-item-padding-x);
  color: var(--ct-list-group-color);
  background-color: var(--ct-list-group-bg);
  border: var(--ct-list-group-border-width) solid var(--ct-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--ct-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--ct-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--ct-list-group-active-color);
  background-color: var(--ct-list-group-active-bg);
  border-color: var(--ct-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--ct-list-group-border-width));
  border-top-width: var(--ct-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--ct-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--ct-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--ct-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--ct-list-group-border-width));
  border-left-width: var(--ct-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--ct-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --ct-list-group-color: var(--ct-primary-text-emphasis);
  --ct-list-group-bg: var(--ct-primary-bg-subtle);
  --ct-list-group-border-color: var(--ct-primary-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-primary-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-primary-border-subtle);
  --ct-list-group-active-color: var(--ct-primary-bg-subtle);
  --ct-list-group-active-bg: var(--ct-primary-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-primary-text-emphasis);
}

.list-group-item-secondary {
  --ct-list-group-color: var(--ct-secondary-text-emphasis);
  --ct-list-group-bg: var(--ct-secondary-bg-subtle);
  --ct-list-group-border-color: var(--ct-secondary-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-secondary-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-secondary-border-subtle);
  --ct-list-group-active-color: var(--ct-secondary-bg-subtle);
  --ct-list-group-active-bg: var(--ct-secondary-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-secondary-text-emphasis);
}

.list-group-item-success {
  --ct-list-group-color: var(--ct-success-text-emphasis);
  --ct-list-group-bg: var(--ct-success-bg-subtle);
  --ct-list-group-border-color: var(--ct-success-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-success-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-success-border-subtle);
  --ct-list-group-active-color: var(--ct-success-bg-subtle);
  --ct-list-group-active-bg: var(--ct-success-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-success-text-emphasis);
}

.list-group-item-info {
  --ct-list-group-color: var(--ct-info-text-emphasis);
  --ct-list-group-bg: var(--ct-info-bg-subtle);
  --ct-list-group-border-color: var(--ct-info-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-info-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-info-border-subtle);
  --ct-list-group-active-color: var(--ct-info-bg-subtle);
  --ct-list-group-active-bg: var(--ct-info-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-info-text-emphasis);
}

.list-group-item-warning {
  --ct-list-group-color: var(--ct-warning-text-emphasis);
  --ct-list-group-bg: var(--ct-warning-bg-subtle);
  --ct-list-group-border-color: var(--ct-warning-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-warning-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-warning-border-subtle);
  --ct-list-group-active-color: var(--ct-warning-bg-subtle);
  --ct-list-group-active-bg: var(--ct-warning-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-warning-text-emphasis);
}

.list-group-item-danger {
  --ct-list-group-color: var(--ct-danger-text-emphasis);
  --ct-list-group-bg: var(--ct-danger-bg-subtle);
  --ct-list-group-border-color: var(--ct-danger-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-danger-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-danger-border-subtle);
  --ct-list-group-active-color: var(--ct-danger-bg-subtle);
  --ct-list-group-active-bg: var(--ct-danger-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-danger-text-emphasis);
}

.list-group-item-light {
  --ct-list-group-color: var(--ct-light-text-emphasis);
  --ct-list-group-bg: var(--ct-light-bg-subtle);
  --ct-list-group-border-color: var(--ct-light-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-light-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-light-border-subtle);
  --ct-list-group-active-color: var(--ct-light-bg-subtle);
  --ct-list-group-active-bg: var(--ct-light-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-light-text-emphasis);
}

.list-group-item-dark {
  --ct-list-group-color: var(--ct-dark-text-emphasis);
  --ct-list-group-bg: var(--ct-dark-bg-subtle);
  --ct-list-group-border-color: var(--ct-dark-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-dark-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-dark-border-subtle);
  --ct-list-group-active-color: var(--ct-dark-bg-subtle);
  --ct-list-group-active-bg: var(--ct-dark-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-dark-text-emphasis);
}

.btn-close {
  --ct-btn-close-color: var(--ct-secondary-color);
  --ct-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--ct-secondary-color%29'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --ct-btn-close-opacity: 0.5;
  --ct-btn-close-hover-opacity: 0.8;
  --ct-btn-close-focus-shadow: none;
  --ct-btn-close-focus-opacity: 1;
  --ct-btn-close-disabled-opacity: 0.25;
  --ct-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--ct-btn-close-color);
  background: transparent var(--ct-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: var(--ct-btn-close-opacity);
}
.btn-close:hover {
  color: var(--ct-btn-close-color);
  text-decoration: none;
  opacity: var(--ct-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--ct-btn-close-focus-shadow);
  opacity: var(--ct-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--ct-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--ct-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--ct-btn-close-white-filter);
}

.toast {
  --ct-toast-zindex: 1090;
  --ct-toast-padding-x: 0.75rem;
  --ct-toast-padding-y: 0.5rem;
  --ct-toast-spacing: 1.5rem;
  --ct-toast-max-width: 350px;
  --ct-toast-font-size: 0.875rem;
  --ct-toast-color: ;
  --ct-toast-bg: rgba(var(--ct-body-bg-rgb), 0.85);
  --ct-toast-border-width: var(--ct-border-width);
  --ct-toast-border-color: var(--ct-border-color);
  --ct-toast-border-radius: var(--ct-border-radius);
  --ct-toast-box-shadow: var(--ct-box-shadow);
  --ct-toast-header-color: var(--ct-secondary-color);
  --ct-toast-header-bg: rgba(var(--ct-body-bg-rgb), 0.85);
  --ct-toast-header-border-color: var(--ct-border-color);
  width: var(--ct-toast-max-width);
  max-width: 100%;
  font-size: var(--ct-toast-font-size);
  color: var(--ct-toast-color);
  pointer-events: auto;
  background-color: var(--ct-toast-bg);
  background-clip: padding-box;
  border: var(--ct-toast-border-width) solid var(--ct-toast-border-color);
  box-shadow: var(--ct-toast-box-shadow);
  border-radius: var(--ct-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --ct-toast-zindex: 1090;
  position: absolute;
  z-index: var(--ct-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--ct-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--ct-toast-padding-y) var(--ct-toast-padding-x);
  color: var(--ct-toast-header-color);
  background-color: var(--ct-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--ct-toast-border-width) solid var(--ct-toast-header-border-color);
  border-top-left-radius: calc(var(--ct-toast-border-radius) - var(--ct-toast-border-width));
  border-top-right-radius: calc(var(--ct-toast-border-radius) - var(--ct-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--ct-toast-padding-x));
  margin-left: var(--ct-toast-padding-x);
}

.toast-body {
  padding: var(--ct-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --ct-modal-zindex: 1055;
  --ct-modal-width: 500px;
  --ct-modal-padding: 1rem;
  --ct-modal-margin: 0.5rem;
  --ct-modal-color: ;
  --ct-modal-bg: var(--ct-secondary-bg);
  --ct-modal-border-color: transparent;
  --ct-modal-border-width: var(--ct-border-width);
  --ct-modal-border-radius: var(--ct-border-radius-lg);
  --ct-modal-box-shadow: 0 0.125rem 0.25rem rgba(var(--ct-body-color-rgb), 0.15);
  --ct-modal-inner-border-radius: calc(var(--ct-border-radius-lg) - (var(--ct-border-width)));
  --ct-modal-header-padding-x: 1rem;
  --ct-modal-header-padding-y: 1rem;
  --ct-modal-header-padding: 1rem 1rem;
  --ct-modal-header-border-color: var(--ct-border-color);
  --ct-modal-header-border-width: var(--ct-border-width);
  --ct-modal-title-line-height: 1.5;
  --ct-modal-footer-gap: 0.5rem;
  --ct-modal-footer-bg: ;
  --ct-modal-footer-border-color: var(--ct-border-color);
  --ct-modal-footer-border-width: var(--ct-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--ct-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--ct-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--ct-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--ct-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--ct-modal-color);
  pointer-events: auto;
  background-color: var(--ct-modal-bg);
  background-clip: padding-box;
  border: var(--ct-modal-border-width) solid var(--ct-modal-border-color);
  border-radius: var(--ct-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --ct-backdrop-zindex: 1050;
  --ct-backdrop-bg: var(--ct-emphasis-color);
  --ct-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--ct-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--ct-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--ct-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--ct-modal-header-padding);
  border-bottom: var(--ct-modal-header-border-width) solid var(--ct-modal-header-border-color);
  border-top-left-radius: var(--ct-modal-inner-border-radius);
  border-top-right-radius: var(--ct-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--ct-modal-header-padding-y) * 0.5) calc(var(--ct-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--ct-modal-header-padding-y)) calc(-0.5 * var(--ct-modal-header-padding-x)) calc(-0.5 * var(--ct-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--ct-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--ct-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--ct-modal-padding) - var(--ct-modal-footer-gap) * 0.5);
  background-color: var(--ct-modal-footer-bg);
  border-top: var(--ct-modal-footer-border-width) solid var(--ct-modal-footer-border-color);
  border-bottom-right-radius: var(--ct-modal-inner-border-radius);
  border-bottom-left-radius: var(--ct-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--ct-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --ct-modal-margin: 1.75rem;
    --ct-modal-box-shadow: var(--ct-box-shadow);
  }
  .modal-dialog {
    max-width: var(--ct-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --ct-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --ct-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --ct-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --ct-tooltip-zindex: 1080;
  --ct-tooltip-max-width: 200px;
  --ct-tooltip-padding-x: 0.75rem;
  --ct-tooltip-padding-y: 0.375rem;
  --ct-tooltip-margin: ;
  --ct-tooltip-font-size: 0.875rem;
  --ct-tooltip-color: var(--ct-body-bg);
  --ct-tooltip-bg: var(--ct-body-color);
  --ct-tooltip-border-radius: var(--ct-border-radius);
  --ct-tooltip-opacity: 1;
  --ct-tooltip-arrow-width: 0.8rem;
  --ct-tooltip-arrow-height: 0.4rem;
  z-index: var(--ct-tooltip-zindex);
  display: block;
  margin: var(--ct-tooltip-margin);
  font-family: var(--ct-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--ct-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--ct-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--ct-tooltip-arrow-width);
  height: var(--ct-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--ct-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--ct-tooltip-arrow-height) calc(var(--ct-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--ct-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--ct-tooltip-arrow-height));
  width: var(--ct-tooltip-arrow-height);
  height: var(--ct-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--ct-tooltip-arrow-width) * 0.5) var(--ct-tooltip-arrow-height) calc(var(--ct-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--ct-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--ct-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--ct-tooltip-arrow-width) * 0.5) var(--ct-tooltip-arrow-height);
  border-bottom-color: var(--ct-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--ct-tooltip-arrow-height));
  width: var(--ct-tooltip-arrow-height);
  height: var(--ct-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--ct-tooltip-arrow-width) * 0.5) 0 calc(var(--ct-tooltip-arrow-width) * 0.5) var(--ct-tooltip-arrow-height);
  border-left-color: var(--ct-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--ct-tooltip-max-width);
  padding: var(--ct-tooltip-padding-y) var(--ct-tooltip-padding-x);
  color: var(--ct-tooltip-color);
  text-align: center;
  background-color: var(--ct-tooltip-bg);
  border-radius: var(--ct-tooltip-border-radius);
}

.popover {
  --ct-popover-zindex: 1070;
  --ct-popover-max-width: 276px;
  --ct-popover-font-size: 0.875rem;
  --ct-popover-bg: var(--ct-secondary-bg);
  --ct-popover-border-width: var(--ct-border-width);
  --ct-popover-border-color: var(--ct-border-color);
  --ct-popover-border-radius: var(--ct-border-radius-lg);
  --ct-popover-inner-border-radius: calc(var(--ct-border-radius-lg) - var(--ct-border-width));
  --ct-popover-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-popover-header-padding-x: 0.8rem;
  --ct-popover-header-padding-y: 0.7rem;
  --ct-popover-header-font-size: 0.9rem;
  --ct-popover-header-color: inherit;
  --ct-popover-header-bg: var(--ct-tertiary-bg);
  --ct-popover-body-padding-x: 0.75rem;
  --ct-popover-body-padding-y: 0.5rem;
  --ct-popover-body-color: var(--ct-body-color);
  --ct-popover-arrow-width: 1rem;
  --ct-popover-arrow-height: 0.5rem;
  --ct-popover-arrow-border: var(--ct-popover-border-color);
  z-index: var(--ct-popover-zindex);
  display: block;
  max-width: var(--ct-popover-max-width);
  font-family: var(--ct-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--ct-popover-font-size);
  word-wrap: break-word;
  background-color: var(--ct-popover-bg);
  background-clip: padding-box;
  border: var(--ct-popover-border-width) solid var(--ct-popover-border-color);
  border-radius: var(--ct-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--ct-popover-arrow-width);
  height: var(--ct-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--ct-popover-arrow-height)) - var(--ct-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--ct-popover-arrow-height) calc(var(--ct-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--ct-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--ct-popover-border-width);
  border-top-color: var(--ct-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--ct-popover-arrow-height)) - var(--ct-popover-border-width));
  width: var(--ct-popover-arrow-height);
  height: var(--ct-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--ct-popover-arrow-width) * 0.5) var(--ct-popover-arrow-height) calc(var(--ct-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--ct-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--ct-popover-border-width);
  border-right-color: var(--ct-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--ct-popover-arrow-height)) - var(--ct-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--ct-popover-arrow-width) * 0.5) var(--ct-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--ct-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--ct-popover-border-width);
  border-bottom-color: var(--ct-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--ct-popover-arrow-width);
  margin-left: calc(-0.5 * var(--ct-popover-arrow-width));
  content: "";
  border-bottom: var(--ct-popover-border-width) solid var(--ct-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--ct-popover-arrow-height)) - var(--ct-popover-border-width));
  width: var(--ct-popover-arrow-height);
  height: var(--ct-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--ct-popover-arrow-width) * 0.5) 0 calc(var(--ct-popover-arrow-width) * 0.5) var(--ct-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--ct-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--ct-popover-border-width);
  border-left-color: var(--ct-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--ct-popover-header-padding-y) var(--ct-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--ct-popover-header-font-size);
  color: var(--ct-popover-header-color);
  background-color: var(--ct-popover-header-bg);
  border-bottom: var(--ct-popover-border-width) solid var(--ct-popover-border-color);
  border-top-left-radius: var(--ct-popover-inner-border-radius);
  border-top-right-radius: var(--ct-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--ct-popover-body-padding-y) var(--ct-popover-body-padding-x);
  color: var(--ct-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--ct-spinner-width);
  height: var(--ct-spinner-height);
  vertical-align: var(--ct-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--ct-spinner-animation-speed) linear infinite var(--ct-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --ct-spinner-width: 2rem;
  --ct-spinner-height: 2rem;
  --ct-spinner-vertical-align: -0.25em;
  --ct-spinner-border-width: 0.25em;
  --ct-spinner-animation-speed: 0.75s;
  --ct-spinner-animation-name: spinner-border;
  border: var(--ct-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --ct-spinner-width: 1rem;
  --ct-spinner-height: 1rem;
  --ct-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --ct-spinner-width: 2rem;
  --ct-spinner-height: 2rem;
  --ct-spinner-vertical-align: -0.25em;
  --ct-spinner-animation-speed: 0.75s;
  --ct-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --ct-spinner-width: 1rem;
  --ct-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --ct-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --ct-offcanvas-zindex: 1045;
  --ct-offcanvas-width: 400px;
  --ct-offcanvas-height: 30vh;
  --ct-offcanvas-padding-x: 1rem;
  --ct-offcanvas-padding-y: 1rem;
  --ct-offcanvas-color: var(--ct-body-color);
  --ct-offcanvas-bg: var(--ct-secondary-bg);
  --ct-offcanvas-border-width: var(--ct-border-width);
  --ct-offcanvas-border-color: transparent;
  --ct-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(var(--ct-body-color-rgb), 0.15);
  --ct-offcanvas-transition: transform 0.3s ease-in-out;
  --ct-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--ct-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--ct-offcanvas-color);
  visibility: hidden;
  background-color: var(--ct-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--ct-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--ct-offcanvas-width);
  border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--ct-offcanvas-width);
  border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--ct-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--ct-offcanvas-height);
  max-height: 100%;
  border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--ct-emphasis-color);
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--ct-offcanvas-padding-y) var(--ct-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--ct-offcanvas-padding-y) * 0.5) calc(var(--ct-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--ct-offcanvas-padding-y)) calc(-0.5 * var(--ct-offcanvas-padding-x)) calc(-0.5 * var(--ct-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--ct-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--ct-offcanvas-padding-y) var(--ct-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--ct-primary-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--ct-secondary-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--ct-success-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--ct-info-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--ct-warning-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--ct-danger-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #313a46 !important;
  background-color: RGBA(var(--ct-light-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--ct-dark-rgb), var(--ct-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--ct-primary-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-primary-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(97, 105, 208, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(97, 105, 208, var(--ct-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--ct-secondary-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-secondary-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(92, 99, 106, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(92, 99, 106, var(--ct-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--ct-success-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-success-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(9, 176, 128, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(9, 176, 128, var(--ct-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--ct-info-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-info-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(48, 149, 178, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(48, 149, 178, var(--ct-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--ct-warning-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-warning-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(217, 166, 77, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(217, 166, 77, var(--ct-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--ct-danger-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-danger-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(213, 78, 105, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(213, 78, 105, var(--ct-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--ct-light-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-light-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(241, 244, 248, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 244, 248, var(--ct-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--ct-dark-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-dark-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(42, 49, 60, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(42, 49, 60, var(--ct-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--ct-emphasis-color-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-emphasis-color-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--ct-emphasis-color-rgb), var(--ct-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--ct-emphasis-color-rgb), var(--ct-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--ct-focus-ring-x, 0) var(--ct-focus-ring-y, 0) var(--ct-focus-ring-blur, 0) var(--ct-focus-ring-width) var(--ct-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--ct-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--ct-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --ct-aspect-ratio: 100%;
}

.ratio-4x3 {
  --ct-aspect-ratio: 75%;
}

.ratio-16x9 {
  --ct-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --ct-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--ct-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--ct-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--ct-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--ct-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --ct-focus-ring-color: rgba(var(--ct-primary-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-secondary {
  --ct-focus-ring-color: rgba(var(--ct-secondary-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-success {
  --ct-focus-ring-color: rgba(var(--ct-success-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-info {
  --ct-focus-ring-color: rgba(var(--ct-info-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-warning {
  --ct-focus-ring-color: rgba(var(--ct-warning-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-danger {
  --ct-focus-ring-color: rgba(var(--ct-danger-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-light {
  --ct-focus-ring-color: rgba(var(--ct-light-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-dark {
  --ct-focus-ring-color: rgba(var(--ct-dark-rgb), var(--ct-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-primary-rgb), var(--ct-border-opacity)) !important;
}

.border-secondary {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-secondary-rgb), var(--ct-border-opacity)) !important;
}

.border-success {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-success-rgb), var(--ct-border-opacity)) !important;
}

.border-info {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-info-rgb), var(--ct-border-opacity)) !important;
}

.border-warning {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-warning-rgb), var(--ct-border-opacity)) !important;
}

.border-danger {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-danger-rgb), var(--ct-border-opacity)) !important;
}

.border-light {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-light-rgb), var(--ct-border-opacity)) !important;
}

.border-dark {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-dark-rgb), var(--ct-border-opacity)) !important;
}

.border-black {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-black-rgb), var(--ct-border-opacity)) !important;
}

.border-white {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-white-rgb), var(--ct-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--ct-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--ct-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--ct-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--ct-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--ct-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--ct-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--ct-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--ct-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --ct-border-opacity: 0.1;
}

.border-opacity-25 {
  --ct-border-opacity: 0.25;
}

.border-opacity-50 {
  --ct-border-opacity: 0.5;
}

.border-opacity-75 {
  --ct-border-opacity: 0.75;
}

.border-opacity-100 {
  --ct-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}

.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-5 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.375rem !important;
}

.mt-2 {
  margin-top: 0.75rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.375rem !important;
}

.me-2 {
  margin-right: 0.75rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.375rem !important;
}

.mb-2 {
  margin-bottom: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.375rem !important;
}

.ms-2 {
  margin-left: 0.75rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important;
}

.mx-n2 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n3 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n4 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n5 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.my-n1 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.my-n2 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -0.375rem !important;
}

.mt-n2 {
  margin-top: -0.75rem !important;
}

.mt-n3 {
  margin-top: -1.5rem !important;
}

.mt-n4 {
  margin-top: -2.25rem !important;
}

.mt-n5 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -0.375rem !important;
}

.me-n2 {
  margin-right: -0.75rem !important;
}

.me-n3 {
  margin-right: -1.5rem !important;
}

.me-n4 {
  margin-right: -2.25rem !important;
}

.me-n5 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.375rem !important;
}

.mb-n2 {
  margin-bottom: -0.75rem !important;
}

.mb-n3 {
  margin-bottom: -1.5rem !important;
}

.mb-n4 {
  margin-bottom: -2.25rem !important;
}

.mb-n5 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -0.375rem !important;
}

.ms-n2 {
  margin-left: -0.75rem !important;
}

.ms-n3 {
  margin-left: -1.5rem !important;
}

.ms-n4 {
  margin-left: -2.25rem !important;
}

.ms-n5 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}

.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-5 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.375rem !important;
}

.pt-2 {
  padding-top: 0.75rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.375rem !important;
}

.pe-2 {
  padding-right: 0.75rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.375rem !important;
}

.pb-2 {
  padding-bottom: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.375rem !important;
}

.ps-2 {
  padding-left: 0.75rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 4.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.375rem !important;
}

.gap-2 {
  gap: 0.75rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 4.5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.375rem !important;
}

.row-gap-2 {
  row-gap: 0.75rem !important;
}

.row-gap-3 {
  row-gap: 1.5rem !important;
}

.row-gap-4 {
  row-gap: 2.25rem !important;
}

.row-gap-5 {
  row-gap: 4.5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.375rem !important;
}

.column-gap-2 {
  column-gap: 0.75rem !important;
}

.column-gap-3 {
  column-gap: 1.5rem !important;
}

.column-gap-4 {
  column-gap: 2.25rem !important;
}

.column-gap-5 {
  column-gap: 4.5rem !important;
}

.font-monospace {
  font-family: var(--ct-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3122rem + 0.7464vw) !important;
}

.fs-3 {
  font-size: calc(1.2753rem + 0.3036vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 0.936rem !important;
}

.fs-6 {
  font-size: 0.765rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-primary-rgb), var(--ct-text-opacity)) !important;
}

.text-secondary {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-secondary-rgb), var(--ct-text-opacity)) !important;
}

.text-success {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-success-rgb), var(--ct-text-opacity)) !important;
}

.text-info {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-info-rgb), var(--ct-text-opacity)) !important;
}

.text-warning {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-warning-rgb), var(--ct-text-opacity)) !important;
}

.text-danger {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-danger-rgb), var(--ct-text-opacity)) !important;
}

.text-light {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-light-rgb), var(--ct-text-opacity)) !important;
}

.text-dark {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-dark-rgb), var(--ct-text-opacity)) !important;
}

.text-black {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-black-rgb), var(--ct-text-opacity)) !important;
}

.text-white {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-white-rgb), var(--ct-text-opacity)) !important;
}

.text-body {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-body-color-rgb), var(--ct-text-opacity)) !important;
}

.text-muted {
  --ct-text-opacity: 1;
  color: var(--ct-secondary-color) !important;
}

.text-black-50 {
  --ct-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --ct-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --ct-text-opacity: 1;
  color: var(--ct-secondary-color) !important;
}

.text-body-tertiary {
  --ct-text-opacity: 1;
  color: var(--ct-tertiary-color) !important;
}

.text-body-emphasis {
  --ct-text-opacity: 1;
  color: var(--ct-emphasis-color) !important;
}

.text-reset {
  --ct-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --ct-text-opacity: 0.25;
}

.text-opacity-50 {
  --ct-text-opacity: 0.5;
}

.text-opacity-75 {
  --ct-text-opacity: 0.75;
}

.text-opacity-100 {
  --ct-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--ct-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--ct-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--ct-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--ct-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--ct-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--ct-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--ct-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--ct-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --ct-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --ct-link-opacity: 0.1;
}

.link-opacity-25 {
  --ct-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --ct-link-opacity: 0.25;
}

.link-opacity-50 {
  --ct-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --ct-link-opacity: 0.5;
}

.link-opacity-75 {
  --ct-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --ct-link-opacity: 0.75;
}

.link-opacity-100 {
  --ct-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --ct-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-primary-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-secondary-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-success {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-success-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-info {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-info-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-warning {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-warning-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-danger {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-danger-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-light {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-light-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-dark {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-dark-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-link-color-rgb), var(--ct-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --ct-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --ct-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --ct-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --ct-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --ct-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --ct-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --ct-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --ct-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --ct-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --ct-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --ct-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --ct-link-underline-opacity: 1;
}

.bg-primary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-primary-rgb), var(--ct-bg-opacity)) !important;
}

.bg-secondary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-secondary-rgb), var(--ct-bg-opacity)) !important;
}

.bg-success {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-success-rgb), var(--ct-bg-opacity)) !important;
}

.bg-info {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-info-rgb), var(--ct-bg-opacity)) !important;
}

.bg-warning {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-warning-rgb), var(--ct-bg-opacity)) !important;
}

.bg-danger {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-danger-rgb), var(--ct-bg-opacity)) !important;
}

.bg-light {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-light-rgb), var(--ct-bg-opacity)) !important;
}

.bg-dark {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-dark-rgb), var(--ct-bg-opacity)) !important;
}

.bg-black {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-black-rgb), var(--ct-bg-opacity)) !important;
}

.bg-white {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-white-rgb), var(--ct-bg-opacity)) !important;
}

.bg-body {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-body-bg-rgb), var(--ct-bg-opacity)) !important;
}

.bg-transparent {
  --ct-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-secondary-bg-rgb), var(--ct-bg-opacity)) !important;
}

.bg-body-tertiary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-tertiary-bg-rgb), var(--ct-bg-opacity)) !important;
}

.bg-opacity-10 {
  --ct-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --ct-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --ct-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --ct-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --ct-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--ct-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--ct-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--ct-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--ct-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--ct-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--ct-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--ct-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--ct-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--ct-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--ct-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--ct-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--ct-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--ct-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--ct-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--ct-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--ct-border-radius) !important;
  border-top-right-radius: var(--ct-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--ct-border-radius-sm) !important;
  border-top-right-radius: var(--ct-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--ct-border-radius) !important;
  border-top-right-radius: var(--ct-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--ct-border-radius-lg) !important;
  border-top-right-radius: var(--ct-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--ct-border-radius-xl) !important;
  border-top-right-radius: var(--ct-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--ct-border-radius-xxl) !important;
  border-top-right-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--ct-border-radius-pill) !important;
  border-top-right-radius: var(--ct-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--ct-border-radius) !important;
  border-bottom-right-radius: var(--ct-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--ct-border-radius-sm) !important;
  border-bottom-right-radius: var(--ct-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--ct-border-radius) !important;
  border-bottom-right-radius: var(--ct-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--ct-border-radius-lg) !important;
  border-bottom-right-radius: var(--ct-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--ct-border-radius-xl) !important;
  border-bottom-right-radius: var(--ct-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--ct-border-radius-xxl) !important;
  border-bottom-right-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--ct-border-radius-pill) !important;
  border-bottom-right-radius: var(--ct-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--ct-border-radius) !important;
  border-bottom-left-radius: var(--ct-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--ct-border-radius-sm) !important;
  border-bottom-left-radius: var(--ct-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--ct-border-radius) !important;
  border-bottom-left-radius: var(--ct-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--ct-border-radius-lg) !important;
  border-bottom-left-radius: var(--ct-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--ct-border-radius-xl) !important;
  border-bottom-left-radius: var(--ct-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--ct-border-radius-xxl) !important;
  border-bottom-left-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--ct-border-radius-pill) !important;
  border-bottom-left-radius: var(--ct-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--ct-border-radius) !important;
  border-top-left-radius: var(--ct-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--ct-border-radius-sm) !important;
  border-top-left-radius: var(--ct-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--ct-border-radius) !important;
  border-top-left-radius: var(--ct-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--ct-border-radius-lg) !important;
  border-top-left-radius: var(--ct-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--ct-border-radius-xl) !important;
  border-top-left-radius: var(--ct-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--ct-border-radius-xxl) !important;
  border-top-left-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--ct-border-radius-pill) !important;
  border-top-left-radius: var(--ct-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-21 {
  font-size: 21px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-48 {
  font-size: 48px !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 2.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.375rem !important;
  }
  .me-sm-2 {
    margin-right: 0.75rem !important;
  }
  .me-sm-3 {
    margin-right: 1.5rem !important;
  }
  .me-sm-4 {
    margin-right: 2.25rem !important;
  }
  .me-sm-5 {
    margin-right: 4.5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.375rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-4 {
    margin-left: 2.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2.25rem !important;
  }
  .m-sm-n5 {
    margin: -4.5rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -4.5rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n4 {
    margin-right: -2.25rem !important;
  }
  .me-sm-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-sm-n5 {
    margin-left: -4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 4.5rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-sm-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-sm-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-sm-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 2.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 4.5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-4 {
    padding-right: 2.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.375rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-4 {
    padding-left: 2.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 4.5rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.375rem !important;
  }
  .gap-sm-2 {
    gap: 0.75rem !important;
  }
  .gap-sm-3 {
    gap: 1.5rem !important;
  }
  .gap-sm-4 {
    gap: 2.25rem !important;
  }
  .gap-sm-5 {
    gap: 4.5rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 4.5rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2.25rem !important;
  }
  .m-md-5 {
    margin: 4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-md-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.375rem !important;
  }
  .mt-md-2 {
    margin-top: 0.75rem !important;
  }
  .mt-md-3 {
    margin-top: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 2.25rem !important;
  }
  .mt-md-5 {
    margin-top: 4.5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.375rem !important;
  }
  .me-md-2 {
    margin-right: 0.75rem !important;
  }
  .me-md-3 {
    margin-right: 1.5rem !important;
  }
  .me-md-4 {
    margin-right: 2.25rem !important;
  }
  .me-md-5 {
    margin-right: 4.5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.375rem !important;
  }
  .ms-md-2 {
    margin-left: 0.75rem !important;
  }
  .ms-md-3 {
    margin-left: 1.5rem !important;
  }
  .ms-md-4 {
    margin-left: 2.25rem !important;
  }
  .ms-md-5 {
    margin-left: 4.5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2.25rem !important;
  }
  .m-md-n5 {
    margin: -4.5rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-md-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-md-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-md-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -4.5rem !important;
  }
  .me-md-n1 {
    margin-right: -0.375rem !important;
  }
  .me-md-n2 {
    margin-right: -0.75rem !important;
  }
  .me-md-n3 {
    margin-right: -1.5rem !important;
  }
  .me-md-n4 {
    margin-right: -2.25rem !important;
  }
  .me-md-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-md-n5 {
    margin-left: -4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2.25rem !important;
  }
  .p-md-5 {
    padding: 4.5rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-md-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-md-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-md-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.375rem !important;
  }
  .pt-md-2 {
    padding-top: 0.75rem !important;
  }
  .pt-md-3 {
    padding-top: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 2.25rem !important;
  }
  .pt-md-5 {
    padding-top: 4.5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.375rem !important;
  }
  .pe-md-2 {
    padding-right: 0.75rem !important;
  }
  .pe-md-3 {
    padding-right: 1.5rem !important;
  }
  .pe-md-4 {
    padding-right: 2.25rem !important;
  }
  .pe-md-5 {
    padding-right: 4.5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.375rem !important;
  }
  .ps-md-2 {
    padding-left: 0.75rem !important;
  }
  .ps-md-3 {
    padding-left: 1.5rem !important;
  }
  .ps-md-4 {
    padding-left: 2.25rem !important;
  }
  .ps-md-5 {
    padding-left: 4.5rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.375rem !important;
  }
  .gap-md-2 {
    gap: 0.75rem !important;
  }
  .gap-md-3 {
    gap: 1.5rem !important;
  }
  .gap-md-4 {
    gap: 2.25rem !important;
  }
  .gap-md-5 {
    gap: 4.5rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-md-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-md-5 {
    column-gap: 4.5rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 2.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.375rem !important;
  }
  .me-lg-2 {
    margin-right: 0.75rem !important;
  }
  .me-lg-3 {
    margin-right: 1.5rem !important;
  }
  .me-lg-4 {
    margin-right: 2.25rem !important;
  }
  .me-lg-5 {
    margin-right: 4.5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.375rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-4 {
    margin-left: 2.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2.25rem !important;
  }
  .m-lg-n5 {
    margin: -4.5rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -4.5rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n4 {
    margin-right: -2.25rem !important;
  }
  .me-lg-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-lg-n5 {
    margin-left: -4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 4.5rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-lg-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-lg-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-lg-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 2.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 4.5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-4 {
    padding-right: 2.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.375rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-4 {
    padding-left: 2.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 4.5rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.375rem !important;
  }
  .gap-lg-2 {
    gap: 0.75rem !important;
  }
  .gap-lg-3 {
    gap: 1.5rem !important;
  }
  .gap-lg-4 {
    gap: 2.25rem !important;
  }
  .gap-lg-5 {
    gap: 4.5rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 4.5rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 2.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xl-4 {
    margin-right: 2.25rem !important;
  }
  .me-xl-5 {
    margin-right: 4.5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-4 {
    margin-left: 2.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2.25rem !important;
  }
  .m-xl-n5 {
    margin: -4.5rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -4.5rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n4 {
    margin-right: -2.25rem !important;
  }
  .me-xl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-xl-n5 {
    margin-left: -4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 4.5rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 2.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 4.5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-4 {
    padding-right: 2.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-4 {
    padding-left: 2.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 4.5rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.375rem !important;
  }
  .gap-xl-2 {
    gap: 0.75rem !important;
  }
  .gap-xl-3 {
    gap: 1.5rem !important;
  }
  .gap-xl-4 {
    gap: 2.25rem !important;
  }
  .gap-xl-5 {
    gap: 4.5rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 4.5rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.375rem !important;
  }
  .m-xxl-2 {
    margin: 0.75rem !important;
  }
  .m-xxl-3 {
    margin: 1.5rem !important;
  }
  .m-xxl-4 {
    margin: 2.25rem !important;
  }
  .m-xxl-5 {
    margin: 4.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-4 {
    margin-top: 2.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-4 {
    margin-right: 2.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-4 {
    margin-left: 2.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.375rem !important;
  }
  .m-xxl-n2 {
    margin: -0.75rem !important;
  }
  .m-xxl-n3 {
    margin: -1.5rem !important;
  }
  .m-xxl-n4 {
    margin: -2.25rem !important;
  }
  .m-xxl-n5 {
    margin: -4.5rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-xxl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -4.5rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n4 {
    margin-right: -2.25rem !important;
  }
  .me-xxl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -4.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.375rem !important;
  }
  .p-xxl-2 {
    padding: 0.75rem !important;
  }
  .p-xxl-3 {
    padding: 1.5rem !important;
  }
  .p-xxl-4 {
    padding: 2.25rem !important;
  }
  .p-xxl-5 {
    padding: 4.5rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-4 {
    padding-top: 2.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 4.5rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-4 {
    padding-right: 2.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-4 {
    padding-left: 2.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 4.5rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.375rem !important;
  }
  .gap-xxl-2 {
    gap: 0.75rem !important;
  }
  .gap-xxl-3 {
    gap: 1.5rem !important;
  }
  .gap-xxl-4 {
    gap: 2.25rem !important;
  }
  .gap-xxl-5 {
    gap: 4.5rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 4.5rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }
  .fs-2 {
    font-size: 1.872rem !important;
  }
  .fs-3 {
    font-size: 1.503rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-Light.eot");
  src: local("Nunito Light"), local("Nunito-Light"), url("../fonts/Nunito-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-Light.woff") format("woff"), url("../fonts/Nunito-Light.ttf") format("truetype"), url("../fonts/Nunito-Light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-Regular.eot");
  src: local("Nunito Regular"), local("Nunito-Regular"), url("../fonts/Nunito-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-Regular.woff") format("woff"), url("../fonts/Nunito-Regular.ttf") format("truetype"), url("../fonts/Nunito-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-Medium.eot");
  src: local("Nunito Medium"), local("Nunito-Medium"), url("../fonts/Nunito-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-Medium.woff") format("woff"), url("../fonts/Nunito-Medium.ttf") format("truetype"), url("../fonts/Nunito-Medium.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-SemiBold.eot");
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"), url("../fonts/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-SemiBold.woff") format("woff"), url("../fonts/Nunito-SemiBold.ttf") format("truetype"), url("../fonts/Nunito-SemiBold.svg#Roboto") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-Bold.eot");
  src: local("Nunito Bold"), local("Nunito-Bold"), url("../fonts/Nunito-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-Bold.woff") format("woff"), url("../fonts/Nunito-Bold.ttf") format("truetype"), url("../fonts/Nunito-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}
.logo-lg img {
  height: var(--ct-logo-lg-height);
}

.logo-sm img {
  height: var(--ct-logo-sm-height);
}

.logo {
  display: block;
  top: 0;
  position: sticky;
  text-align: center;
  line-height: var(--ct-topbar-height);
  padding: 0 calc(var(--ct-leftbar-width-sm) * 0.5 - var(--ct-logo-sm-height) * 0.7);
  transition: all 0.25s ease-in-out;
}
.logo span.logo-lg {
  display: block;
}
.logo span.logo-sm {
  display: none;
}
.logo.logo-light {
  display: none;
}
.logo.logo-dark {
  display: block;
}

html[data-menu-color=brand] .logo.logo-light,
html[data-menu-color=dark] .logo.logo-light,
html[data-bs-theme=dark] .logo.logo-light {
  display: block;
}
html[data-menu-color=brand] .logo.logo-dark,
html[data-menu-color=dark] .logo.logo-dark,
html[data-bs-theme=dark] .logo.logo-dark {
  display: none;
}

.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: var(--ct-help-box-bg);
}
.help-box .close-btn {
  right: 10px;
  top: 10px;
  position: absolute;
}

.wrapper {
  height: 100%;
  width: 100%;
}

.content-page {
  position: relative;
  margin-left: var(--ct-leftbar-width);
  min-height: calc(100vh - var(--ct-topbar-height) - 2px);
  padding: 0 0.75rem var(--ct-footer-height);
  transition: all 0.25s ease-in-out;
}

.leftside-menu {
  z-index: 1000;
  bottom: 0;
  top: 0;
  position: fixed;
  width: var(--ct-leftbar-width);
  min-width: var(--ct-leftbar-width);
  padding-bottom: calc(var(--ct-footer-height) + 0.75rem);
  background: var(--ct-menu-bg);
  box-shadow: var(--ct-box-shadow);
  border-right: var(--ct-theme-card-border-width) solid var(--ct-border-color);
  transition: all 0.25s ease-in-out;
}

.leftbar-user {
  padding: 20px;
  text-align: center;
  display: none;
}
.leftbar-user .leftbar-user-name {
  font-weight: 700;
  color: var(--ct-menu-item-color);
  white-space: nowrap;
  display: block;
}

html[data-sidenav-user=true] .leftbar-user {
  display: block;
}

.side-nav {
  padding-left: 0;
  list-style-type: none;
}
.side-nav ul {
  list-style-type: none;
}
.side-nav .side-nav-link {
  display: block;
  list-style: none;
  position: relative;
  white-space: nowrap;
  color: var(--ct-menu-item-color);
  transition: all 0.25s ease-in-out;
  font-size: var(--ct-menu-item-font-size);
  padding: var(--ct-menu-item-padding-y) var(--ct-menu-item-padding-x);
}
.side-nav .side-nav-link:hover, .side-nav .side-nav-link:focus, .side-nav .side-nav-link:active {
  color: var(--ct-menu-item-hover-color);
  text-decoration: none;
}
.side-nav .side-nav-link span {
  vertical-align: middle;
}
.side-nav .side-nav-link i {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: var(--ct-menu-item-icon-size);
  line-height: var(--ct-menu-item-icon-size);
  width: calc(var(--ct-leftbar-width-sm) - var(--ct-menu-item-padding-x) * 2);
  min-width: calc(var(--ct-leftbar-width-sm) - var(--ct-menu-item-padding-x) * 2);
}
.side-nav .menu-arrow {
  display: inline-block;
  text-rendering: auto;
  position: absolute;
  top: 50%;
  right: calc(var(--ct-menu-item-padding-x) * 1.5);
  transition: transform 0.15s;
  transform: translate(-50%, -50%);
  line-height: var(--ct-menu-item-icon-size);
  font-size: calc(var(--ct-menu-item-font-size) * 1.18);
}
.side-nav .menu-arrow:before {
  content: "\f0142";
  font-family: "Material Design Icons";
}
.side-nav .badge {
  margin-top: calc(var(--ct-menu-item-font-size) * 0.25);
  position: absolute;
  right: 0;
  margin-right: calc(var(--ct-menu-item-padding-x) * 3);
}
.side-nav .side-nav-title {
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--ct-menu-item-color);
  font-weight: 700;
  font-size: calc(var(--ct-menu-item-font-size) * 0.74);
  padding: var(--ct-menu-item-padding-y) calc(var(--ct-menu-item-padding-x) * 2);
}
.side-nav .side-nav-item > a[aria-expanded=true] > span.menu-arrow {
  transform: translate(-50%, -50%) rotate(90deg);
}
.side-nav .side-nav-item.menuitem-active > a:not(.collapsed) > span.menu-arrow {
  transform: translate(-50%, -50%) rotate(90deg);
}
.side-nav .menuitem-active > a {
  color: var(--ct-menu-item-active-color);
  font-weight: 500;
}
.side-nav .menuitem-active > a:hover, .side-nav .menuitem-active > a:focus, .side-nav .menuitem-active > a:active {
  color: var(--ct-menu-item-active-color);
}
.side-nav .menuitem-active .menuitem-active .active {
  color: var(--ct-menu-item-active-color);
  font-weight: 500;
}
.side-nav.bottom {
  bottom: 0;
  width: 100%;
  position: absolute;
}

*[dir=rtl] .side-nav .side-nav-item .menu-arrow:before {
  content: "\f0141" !important;
}

.side-nav-second-level li,
.side-nav-second-level .side-nav-item,
.side-nav-third-level li,
.side-nav-third-level .side-nav-item,
.side-nav-forth-level li,
.side-nav-forth-level .side-nav-item {
  padding: 0;
}
.side-nav-second-level li a,
.side-nav-second-level li .side-nav-link,
.side-nav-second-level .side-nav-item a,
.side-nav-second-level .side-nav-item .side-nav-link,
.side-nav-third-level li a,
.side-nav-third-level li .side-nav-link,
.side-nav-third-level .side-nav-item a,
.side-nav-third-level .side-nav-item .side-nav-link,
.side-nav-forth-level li a,
.side-nav-forth-level li .side-nav-link,
.side-nav-forth-level .side-nav-item a,
.side-nav-forth-level .side-nav-item .side-nav-link {
  color: var(--ct-menu-item-color);
  display: block;
  position: relative;
  transition: all 0.25s ease-in-out;
  font-size: calc(var(--ct-menu-item-font-size) * 0.98);
  padding: calc(var(--ct-menu-item-padding-y) * 0.6) calc(var(--ct-menu-item-padding-x) * 1.5);
}
.side-nav-second-level li a:hover, .side-nav-second-level li a:focus, .side-nav-second-level li a:active,
.side-nav-second-level li .side-nav-link:hover,
.side-nav-second-level li .side-nav-link:focus,
.side-nav-second-level li .side-nav-link:active,
.side-nav-second-level .side-nav-item a:hover,
.side-nav-second-level .side-nav-item a:focus,
.side-nav-second-level .side-nav-item a:active,
.side-nav-second-level .side-nav-item .side-nav-link:hover,
.side-nav-second-level .side-nav-item .side-nav-link:focus,
.side-nav-second-level .side-nav-item .side-nav-link:active,
.side-nav-third-level li a:hover,
.side-nav-third-level li a:focus,
.side-nav-third-level li a:active,
.side-nav-third-level li .side-nav-link:hover,
.side-nav-third-level li .side-nav-link:focus,
.side-nav-third-level li .side-nav-link:active,
.side-nav-third-level .side-nav-item a:hover,
.side-nav-third-level .side-nav-item a:focus,
.side-nav-third-level .side-nav-item a:active,
.side-nav-third-level .side-nav-item .side-nav-link:hover,
.side-nav-third-level .side-nav-item .side-nav-link:focus,
.side-nav-third-level .side-nav-item .side-nav-link:active,
.side-nav-forth-level li a:hover,
.side-nav-forth-level li a:focus,
.side-nav-forth-level li a:active,
.side-nav-forth-level li .side-nav-link:hover,
.side-nav-forth-level li .side-nav-link:focus,
.side-nav-forth-level li .side-nav-link:active,
.side-nav-forth-level .side-nav-item a:hover,
.side-nav-forth-level .side-nav-item a:focus,
.side-nav-forth-level .side-nav-item a:active,
.side-nav-forth-level .side-nav-item .side-nav-link:hover,
.side-nav-forth-level .side-nav-item .side-nav-link:focus,
.side-nav-forth-level .side-nav-item .side-nav-link:active {
  color: var(--ct-menu-item-hover-color);
}
.side-nav-second-level li.active > a,
.side-nav-second-level .side-nav-item.active > a,
.side-nav-third-level li.active > a,
.side-nav-third-level .side-nav-item.active > a,
.side-nav-forth-level li.active > a,
.side-nav-forth-level .side-nav-item.active > a {
  color: var(--ct-menu-item-active-color);
}
.side-nav-second-level li.active > a:hover, .side-nav-second-level li.active > a:focus, .side-nav-second-level li.active > a:active,
.side-nav-second-level .side-nav-item.active > a:hover,
.side-nav-second-level .side-nav-item.active > a:focus,
.side-nav-second-level .side-nav-item.active > a:active,
.side-nav-third-level li.active > a:hover,
.side-nav-third-level li.active > a:focus,
.side-nav-third-level li.active > a:active,
.side-nav-third-level .side-nav-item.active > a:hover,
.side-nav-third-level .side-nav-item.active > a:focus,
.side-nav-third-level .side-nav-item.active > a:active,
.side-nav-forth-level li.active > a:hover,
.side-nav-forth-level li.active > a:focus,
.side-nav-forth-level li.active > a:active,
.side-nav-forth-level .side-nav-item.active > a:hover,
.side-nav-forth-level .side-nav-item.active > a:focus,
.side-nav-forth-level .side-nav-item.active > a:active {
  color: var(--ct-menu-item-active-color);
}

.side-nav-second-level {
  margin-top: calc(var(--ct-menu-item-padding-y) * -0.5);
  padding-left: calc(var(--ct-leftbar-width-sm) - var(--ct-menu-item-padding-x) * 2);
}

.side-nav-third-level {
  padding-left: calc(var(--ct-menu-item-padding-x) * 2);
}

.side-nav-forth-level {
  padding-left: calc(var(--ct-menu-item-padding-x) * 2);
}

html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .navbar-custom {
  z-index: 1005;
  margin-left: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu {
  position: absolute;
  width: var(--ct-leftbar-width-sm);
  min-width: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-mask,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-content-wrapper {
  overflow: visible !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-scrollbar {
  display: none !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-offset {
  bottom: 0 !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .logo {
  z-index: 1;
  background: var(--ct-menu-bg);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .logo span.logo-lg {
  display: none;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .logo span.logo-sm {
  display: block;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing {
  display: none;
  height: inherit !important;
  transition: none !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-second-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-third-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-forth-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-second-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-third-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-forth-level {
  display: none !important;
  padding: 0;
  margin-top: 0;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-second-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-second-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-third-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-third-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-forth-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-forth-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-second-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-second-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-third-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-third-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-forth-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-forth-level li .side-nav-link::before {
  display: none;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-second-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-third-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-forth-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-second-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-third-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-forth-level .menu-arrow {
  right: var(--ct-menu-item-padding-x);
  transform: translate(-50%, -50%);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item {
  position: relative;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link {
  transition: none;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link:hover, html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link:active, html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-menu-item-hover-color);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link span {
  visibility: hidden;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link .menu-arrow {
  transform: translate(-50%, -50%) rotate(90deg);
  padding-left: 0 !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover .side-nav-link {
  position: relative;
  color: #fff;
  background: var(--ct-menu-condensed-link-bg);
  width: var(--ct-leftbar-width);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover .side-nav-link span {
  visibility: visible;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover .side-nav-link span:not(.badge) {
  margin-left: var(--ct-menu-item-padding-x);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing {
  display: block !important;
  transition: none !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul {
  display: block !important;
  left: var(--ct-leftbar-width-sm);
  position: absolute;
  background: var(--ct-menu-bg);
  box-shadow: var(--ct-box-shadow);
  width: calc(var(--ct-leftbar-width) - var(--ct-leftbar-width-sm));
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul a,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul a {
  box-shadow: none;
  position: relative;
  width: calc(var(--ct-leftbar-width) - var(--ct-leftbar-width-sm));
  padding: calc(var(--ct-menu-item-padding-y) * 0.6) calc(var(--ct-menu-item-padding-x) * 2);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul a:hover,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
  color: var(--ct-menu-item-hover-color);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapsing,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapsing {
  display: block !important;
  height: auto !important;
  transition: none !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse > ul,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapsing > ul,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse > ul,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapsing > ul {
  display: block !important;
  position: absolute !important;
  top: 0;
  left: calc(var(--ct-leftbar-width) - var(--ct-leftbar-width-sm));
  width: calc(var(--ct-leftbar-width) - var(--ct-leftbar-width-sm));
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper :is(.help-box,
.leftbar-user,
.side-nav-title) {
  display: none;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .content-page {
  margin-left: var(--ct-leftbar-width-sm);
  min-height: var(--ct-leftbar-condensed-height);
}

html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .navbar-custom {
  margin-left: var(--ct-leftbar-width-md);
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu {
  width: var(--ct-leftbar-width-md);
  min-width: var(--ct-leftbar-width-md);
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-title {
  text-align: center;
  padding: var(--ct-menu-item-padding-y) calc(var(--ct-menu-item-padding-x));
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item {
  text-align: center;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-link {
  padding: var(--ct-menu-item-padding-y) var(--ct-menu-item-padding-x);
  text-align: center;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-link i {
  display: block;
  text-align: center;
  margin: 0 0 calc(var(--ct-menu-item-padding-y) * 1 / 3);
  width: auto;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-link span {
  margin-left: 0;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-link .badge {
  display: none;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-second-level,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-third-level,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-forth-level {
  padding: 0;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-second-level .menu-arrow,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-third-level .menu-arrow,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-forth-level .menu-arrow {
  display: inline-block;
  right: var(--ct-menu-item-padding-x);
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-second-level li a,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-second-level li .side-nav-link,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-third-level li a,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-third-level li .side-nav-link,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-forth-level li a,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-forth-level li .side-nav-link {
  padding: calc(var(--ct-menu-item-padding-y) * 0.8) calc(var(--ct-menu-item-padding-x));
  text-align: center;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .help-box,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .menu-arrow {
  display: none;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .content-page {
  margin-left: var(--ct-leftbar-width-md);
}

html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .content-page {
  margin-left: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .navbar-custom {
  margin-left: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .navbar-custom .button-toggle-menu {
  display: none;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) {
  width: var(--ct-leftbar-width-sm);
  min-width: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .simplebar-scrollbar:before {
  background: transparent;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .logo .logo-sm {
  display: block;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .logo .logo-lg {
  display: none;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-item .side-nav-link span {
  visibility: hidden;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .help-box,
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .button-sm-hover {
  visibility: hidden;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .leftbar-user,
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-title {
  display: none;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-second-level,
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-third-level,
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-forth-level {
  height: 0;
  opacity: 0;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-horizontal .simplebar-scrollbar:before {
  background: transparent;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu .logo {
  text-align: left;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:hover {
  z-index: 1045;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu .button-sm-hover {
  display: block;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu .help-box {
  display: none;
}

.button-sm-hover {
  cursor: pointer;
  display: none;
  right: calc(var(--ct-leftbar-width-sm) * 0.5 - var(--ct-logo-sm-height) * 0.7);
  top: 0;
  position: absolute;
  font-size: var(--ct-menu-item-icon-size);
  line-height: var(--ct-topbar-height);
  z-index: 1;
  color: var(--ct-menu-item-color);
}
.button-sm-hover:hover, .button-sm-hover:focus, .button-sm-hover:active {
  color: var(--ct-menu-item-hover-color);
}

html[data-sidenav-size=sm-hover-active]:not([data-layout=topnav]) .button-toggle-menu {
  display: none;
}
html[data-sidenav-size=sm-hover-active]:not([data-layout=topnav]) .leftside-menu .logo {
  text-align: left;
}
html[data-sidenav-size=sm-hover-active]:not([data-layout=topnav]) .leftside-menu .button-sm-hover {
  display: block;
}
html[data-sidenav-size=sm-hover-active]:not([data-layout=topnav]) .leftside-menu .button-sm-hover .ri-checkbox-blank-circle-line:before {
  content: "\f05a";
}

.button-close-fullsidebar {
  cursor: pointer;
  display: none;
  right: calc(var(--ct-leftbar-width-sm) * 0.5 - var(--ct-logo-sm-height) * 0.7);
  top: 0;
  position: absolute;
  font-size: var(--ct-menu-item-icon-size);
  line-height: var(--ct-topbar-height);
  z-index: 1;
  color: var(--ct-menu-item-color);
}
.button-close-fullsidebar:hover, .button-close-fullsidebar:focus, .button-close-fullsidebar:active {
  color: var(--ct-menu-item-hover-color);
}

html[data-sidenav-size=full]:not([data-layout=topnav]) .navbar-custom {
  margin-left: 0;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .navbar-custom .logo-topbar {
  display: inline-block;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .button-close-fullsidebar {
  display: block;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .leftside-menu {
  margin-left: calc(var(--ct-leftbar-width) * -1);
  border-color: transparent;
  opacity: 0;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .leftside-menu .logo {
  text-align: left;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .content-page {
  margin-left: 0;
}
html[data-sidenav-size=full]:not([data-layout=topnav]).sidebar-enable .leftside-menu {
  opacity: 1;
  z-index: 1055;
  margin-left: 0;
}

html[data-sidenav-size=fullscreen]:not([data-layout=topnav]) .navbar-custom {
  margin-left: 0;
}
html[data-sidenav-size=fullscreen]:not([data-layout=topnav]) .leftside-menu {
  margin-left: calc(var(--ct-leftbar-width) * -1);
  opacity: 0;
}
html[data-sidenav-size=fullscreen]:not([data-layout=topnav]) .content-page {
  margin-left: 0;
}

html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper {
  max-width: 95%;
  margin: 0 auto;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .navbar-custom {
  z-index: 1005;
  margin: 0 -2.65%;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .navbar-custom .topbar {
  max-width: 95%;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .leftside-menu {
  bottom: 0.75rem;
  top: calc(var(--ct-topbar-height) + 1.5rem * 0.5);
  border-radius: 0.3rem;
  border: var(--ct-theme-card-border-width) solid var(--ct-border-color);
  padding-bottom: 0;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .leftside-menu .button-sm-hover {
  top: var(--ct-menu-item-padding-y);
  line-height: 1;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .footer {
  right: 0.75rem;
  left: 0.75rem;
  bottom: 0.75rem;
  border-radius: 0.3rem;
  border: none;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .logo {
  visibility: hidden;
  height: 0;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .logo-topbar {
  display: block;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .leftbar-user {
  background: url("../images/waves.png") no-repeat;
}
html[data-layout-mode=detached]:not([data-layout=topnav])[data-sidenav-size=full] .wrapper .leftside-menu {
  padding-bottom: calc(var(--ct-footer-height) + 0.75rem);
  top: 0.75rem;
  left: 0.75rem;
}
html[data-layout-mode=detached]:not([data-layout=topnav])[data-sidenav-size=full] .wrapper .leftside-menu .logo {
  top: 0.75rem;
  visibility: visible;
  height: auto;
}
html[data-layout-mode=detached]:not([data-layout=topnav])[data-sidenav-size=full] .wrapper .leftside-menu .logo span.logo-lg {
  display: block;
}

@media (min-width: 1400px) {
  html[data-layout-mode=boxed] body {
    background-color: var(--ct-tertiary-bg);
  }
  html[data-layout-mode=boxed] .wrapper {
    margin: 0 auto;
    box-shadow: var(--ct-box-shadow);
    max-width: 1300px;
    background-color: var(--ct-body-bg);
  }
}
@media (min-width: 992px) {
  html[data-layout-position=scrollable] .wrapper {
    display: block;
  }
  html[data-layout-position=scrollable] .wrapper .leftside-menu {
    position: absolute;
    padding-top: 0 !important;
  }
  html[data-layout-position=scrollable] .wrapper .leftside-menu .logo {
    position: relative;
  }
  html[data-layout-position=scrollable] .wrapper .leftside-menu #leftside-menu-container {
    height: calc(100% - var(--ct-topbar-height)) !important;
  }
  html[data-layout-position=scrollable] .navbar-custom {
    position: static !important;
  }
  html[data-layout-position=scrollable][data-layout=topnav] .topnav {
    position: static;
  }
}
@media (max-width: 767.98px) {
  html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper {
    max-width: 100% !important;
  }
  html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .navbar-custom {
    margin: 0;
    padding: 0;
  }
}
.navbar-custom {
  background: var(--ct-topbar-bg);
  box-shadow: var(--ct-box-shadow);
  min-height: var(--ct-topbar-height);
  margin-left: var(--ct-leftbar-width);
  position: sticky;
  top: 0;
  transition: all 0.25s ease-in-out;
  z-index: 1000;
  border-bottom: var(--ct-theme-card-border-width) solid var(--ct-border-color);
}
.navbar-custom .topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.navbar-custom .topbar .app-search form {
  height: var(--ct-topbar-height);
  max-width: 320px;
}
.navbar-custom .topbar .app-search form .form-control {
  color: var(--ct-topbar-item-color);
  background-color: var(--ct-topbar-search-bg);
}
.navbar-custom .topbar .app-search form .form-control::placeholder {
  color: var(--ct-topbar-item-color);
  opacity: 0.5;
}
.navbar-custom .topbar .app-search form span.search-icon {
  color: var(--ct-topbar-item-color);
}
.navbar-custom .topbar-menu {
  position: relative;
  list-style-type: none;
  margin-bottom: 0;
}
.navbar-custom .topbar-menu li {
  max-height: var(--ct-topbar-height);
}
.navbar-custom .topbar-menu li .nav-link.show {
  color: var(--ct-topbar-item-hover-color);
}
.navbar-custom .topbar-menu .nav-link {
  padding: 0;
  position: relative;
  color: var(--ct-topbar-item-color);
  display: flex;
  align-items: center;
  height: var(--ct-topbar-height);
  cursor: pointer;
}
.navbar-custom .topbar-menu .nav-link:hover {
  color: var(--ct-topbar-item-hover-color);
}

.logo-topbar {
  float: left;
  display: none;
  line-height: var(--ct-topbar-height);
  padding: 0 0.75rem;
}
.logo-topbar .logo-sm {
  display: none;
}
.logo-topbar .logo-light {
  display: none;
}
.logo-topbar .logo-dark {
  display: block;
}

html[data-topbar-color=brand] .logo-topbar .logo-light,
html[data-topbar-color=dark] .logo-topbar .logo-light,
html[data-bs-theme=dark] .logo-topbar .logo-light {
  display: block;
}
html[data-topbar-color=brand] .logo-topbar .logo-dark,
html[data-topbar-color=dark] .logo-topbar .logo-dark,
html[data-bs-theme=dark] .logo-topbar .logo-dark {
  display: none;
}

html[data-bs-theme=dark] #light-dark-mode .ri-moon-line::before {
  content: "\f1bf";
}

.button-toggle-menu {
  border: none;
  color: var(--ct-topbar-item-color);
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.app-search {
  position: static;
  overflow-y: hidden;
}
.app-search form {
  display: flex;
  overflow: hidden;
  align-items: center;
}
.app-search form .form-control {
  border: none;
  height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
  padding-left: 40px;
  padding-right: 20px;
  background-color: var(--ct-light);
  box-shadow: none;
}
.app-search form span.search-icon {
  position: absolute;
  z-index: 4;
  font-size: 20px;
  line-height: 38px;
  left: 10px;
  top: 0;
  z-index: 5;
  color: var(--ct-secondary);
}
.app-search form .input-group-text {
  margin-left: 0;
  z-index: 4;
}
.app-search form .dropdown-menu {
  z-index: 1060;
}

.notification-list {
  margin-left: 0;
}
.notification-list .noti-title {
  background-color: transparent;
  padding: 15px 20px;
}
.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 1px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #fa5c7c;
}
.notification-list .notify-item {
  padding: 10px 20px;
}
.notification-list .notify-item.unread-noti {
  background-color: var(--ct-tertiary-bg);
}
.notification-list .notify-item.read-noti {
  background-color: transparent;
  border: 1px solid var(--ct-secondary-bg-subtle);
  transition: all 0.2s ease-in-out;
}
.notification-list .notify-item.read-noti:hover {
  background-color: var(--ct-tertiary-bg);
}
.notification-list .notify-item .card-body {
  padding: 14px;
}
.notification-list .notify-item .card-body .noti-close-btn {
  position: absolute;
  top: 3px;
  right: 5px;
}
.notification-list .notify-item .card-body .noti-item-title {
  margin: 0 0 2px;
}
.notification-list .notify-item .card-body .noti-item-title,
.notification-list .notify-item .card-body .noti-item-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}
.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
}
.notification-list .notify-item .notify-details b {
  font-weight: 500;
}
.notification-list .notify-item .notify-details small, .notification-list .notify-item .notify-details .small {
  display: block;
}

.profile-dropdown {
  min-width: 170px;
}
.profile-dropdown i,
.profile-dropdown span {
  vertical-align: middle;
}

.nav-user {
  display: flex;
  align-items: center;
  gap: 10px;
  border-width: 0 1px;
  border-style: solid;
  border-color: var(--ct-topbar-user-border);
  background-color: var(--ct-topbar-user-bg);
  min-height: var(--ct-topbar-height);
  transition: none;
}

.fullscreen-enable .ri-fullscreen-line::before {
  content: "\ed9a";
}

@media (max-width: 375px) {
  .navbar-custom .button-toggle-menu {
    width: auto;
  }
  .logo-topbar {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .navbar-custom .topbar-menu {
    position: initial;
  }
  .navbar-custom .dropdown {
    position: static;
  }
  .navbar-custom .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}
@media (max-width: 767.98px) {
  .navbar-custom {
    padding: 0 0.75rem;
  }
  .logo-topbar {
    display: block;
  }
  .logo-topbar span.logo-lg {
    display: none;
  }
  .logo-topbar span.logo-sm {
    display: block;
  }
}
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.page-title-box .page-title-right {
  float: right;
  margin-top: 20px;
}
.page-title-box .breadcrumb {
  padding-top: 8px;
}

.page-title-box-sm .page-title {
  line-height: 1 !important;
  margin-bottom: 25px;
}
.page-title-box-sm .page-title-right {
  float: right;
  margin-top: 0;
}
.page-title-box-sm .breadcrumb {
  padding-top: 0;
  margin-top: -3px !important;
}

.text-title {
  color: var(--ct-secondary-color);
}
.text-title:hover {
  color: var(--ct-tertiary-color);
}

@media (max-width: 767.98px) {
  .page-title-box .page-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 70px;
  }
  .page-title-box .breadcrumb {
    display: none;
  }
  .page-title-box .page-title-right {
    display: none;
  }
}
@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--ct-footer-height);
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  color: var(--ct-emphasis-color);
  transition: all 0.25s ease-in-out;
  border-top: 1px solid var(--ct-border-color);
}
.footer .footer-links a {
  color: var(--ct-emphasis-color);
  margin-left: 1.5rem;
  transition: all 0.4s;
}
.footer .footer-links a:hover {
  color: var(--ct-emphasis-color);
}
.footer .footer-links a:first-of-type {
  margin-left: 0;
}

.footer-alt {
  border: none;
  text-align: center;
  justify-content: center;
}

html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-fluid, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-sm, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-md, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-lg, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-xl, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-xxl {
  max-width: 100%;
  padding: 0;
}

@media (max-width: 767.98px) {
  .footer {
    text-align: center;
  }
}
html[data-layout=topnav] .navbar-custom {
  margin: 0;
  padding: 0;
  z-index: 1005;
  box-shadow: none;
}
html[data-layout=topnav] .navbar-custom .logo-topbar {
  display: block;
}
html[data-layout=topnav] .navbar-custom .button-toggle-menu {
  display: none;
}
html[data-layout=topnav] .content-page {
  margin-left: 0;
  padding: 0 0 var(--ct-topbar-height);
}
html[data-layout=topnav] .container-fluid, html[data-layout=topnav] .container-sm, html[data-layout=topnav] .container-md, html[data-layout=topnav] .container-lg, html[data-layout=topnav] .container-xl, html[data-layout=topnav] .container-xxl {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
html[data-layout=topnav] .topnav {
  width: 100%;
  z-index: 1000;
  position: sticky;
  top: var(--ct-topbar-height);
  background: var(--ct-menu-bg);
  box-shadow: var(--ct-box-shadow);
  border-top: 1px solid var(--ct-topbar-user-border);
}
html[data-layout=topnav] .topnav .navbar {
  margin: 0;
  padding: 0;
}
html[data-layout=topnav] .topnav .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  position: relative;
  font-size: var(--ct-menu-item-font-size);
  padding: var(--ct-menu-item-padding-y) calc(var(--ct-menu-item-padding-x) * 2);
}
html[data-layout=topnav] .topnav .navbar-nav .nav-link i {
  font-size: var(--ct-menu-item-icon-size);
  margin-right: calc(var(--ct-menu-item-icon-size) * 0.75);
}
html[data-layout=topnav] .topnav .navbar-nav .dropdown .dropdown-menu {
  font-size: calc(0.9rem - 0.01rem);
}
html[data-layout=topnav] .topnav .navbar-nav .dropdown .dropdown-menu .dropdown-item {
  padding: calc(var(--ct-menu-item-padding-y) * 0.5) calc(var(--ct-menu-item-padding-x) * 2.4);
}
html[data-layout=topnav] .topnav .nav-item > a,
html[data-layout=topnav] .topnav .nav-item .nav-link {
  color: var(--ct-menu-item-color);
}
html[data-layout=topnav] .topnav .nav-item > a:hover,
html[data-layout=topnav] .topnav .nav-item .nav-link:hover {
  color: var(--ct-menu-item-hover-color);
}
html[data-layout=topnav] .topnav .nav-item.active > a {
  color: var(--ct-menu-item-active-color);
}
html[data-layout=topnav] .arrow-down {
  display: inline-block;
  display: inline-block;
  text-rendering: auto;
  margin-left: calc(var(--ct-menu-item-padding-x) * 1.5);
  font-size: calc(var(--ct-menu-item-font-size) * 1.18);
}
html[data-layout=topnav] .arrow-down:before {
  content: "\f0140";
  font-family: "Material Design Icons";
}
html[data-layout=topnav] #sidebar-size,
html[data-layout=topnav] #layout-detached,
html[data-layout=topnav] #sidebar-user {
  display: none;
}
html[data-layout=topnav] #topnav-color {
  display: block;
}

#topnav-color {
  display: none;
}

.navbar-toggle {
  display: none;
  position: relative;
  cursor: pointer;
  float: left;
  padding: 0;
  background-color: transparent;
  color: var(--ct-topbar-item-color);
  border: none;
}
.navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  height: 16px;
  transition: all 0.5s ease;
}
.navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: var(--ct-topbar-item-color);
  display: block;
  margin-bottom: 5px;
  transition: transform 0.5s ease;
}
.navbar-toggle.open span {
  position: absolute;
}
.navbar-toggle.open span:first-child {
  top: 7px;
  transform: rotate(45deg);
}
.navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
.navbar-toggle.open span:last-child {
  width: 100%;
  top: 7px;
  transform: rotate(-45deg);
}

@media (min-width: 992px) {
  html[data-layout=topnav] .container-fluid, html[data-layout=topnav] .container-sm, html[data-layout=topnav] .container-md, html[data-layout=topnav] .container-lg, html[data-layout=topnav] .container-xl, html[data-layout=topnav] .container-xxl {
    max-width: 95%;
  }
  html[data-layout=topnav][data-layout-mode=boxed] .container-fluid, html[data-layout=topnav][data-layout-mode=boxed] .container-sm, html[data-layout=topnav][data-layout-mode=boxed] .container-md, html[data-layout=topnav][data-layout-mode=boxed] .container-lg, html[data-layout=topnav][data-layout-mode=boxed] .container-xl, html[data-layout=topnav][data-layout-mode=boxed] .container-xxl {
    max-width: 97%;
  }
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }
  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    min-width: calc(var(--ct-dropdown-min-width) + var(--ct-menu-item-font-size) * 1.5);
    font-size: calc(0.9rem - 0.01rem);
  }
  .topnav .dropdown .dropdown-menu .arrow-down {
    font-size: var(--ct-menu-item-font-size);
    transform: rotate(270deg);
    position: absolute;
    right: var(--ct-menu-item-padding-x);
  }
  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
  }
  .topnav .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .topnav .dropdown.active > a.dropdown-item {
    color: var(--ct-emphasis-color);
    background-color: var(--ct-tertiary-bg);
  }
}
@media (max-width: 991.98px) {
  html[data-layout=topnav] .topnav {
    max-height: 360px;
    overflow-y: auto;
  }
  html[data-layout=topnav] .topnav .navbar-nav .nav-link {
    padding: calc(var(--ct-menu-item-padding-y) * 0.8) calc(var(--ct-menu-item-padding-x) * 2);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: calc(var(--ct-menu-item-padding-x) * 3);
    margin-top: calc(var(--ct-menu-item-padding-y) * -0.75);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-menu .dropdown-menu {
    margin-top: calc(var(--ct-menu-item-padding-y) * -0.5);
    padding-left: calc(var(--ct-menu-item-padding-x) * 1.5);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-item {
    color: var(--ct-menu-item-color);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-item.hover {
    color: var(--ct-menu-item-hover-color);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-item.active, html[data-layout=topnav] .topnav .dropdown .dropdown-item:active {
    color: var(--ct-menu-item-active-color);
  }
  html[data-layout=topnav] .topnav .dropdown.active > a.dropdown-item {
    color: var(--ct-menu-item-active-color);
  }
  html[data-layout=topnav] .topnav .arrow-down {
    font-size: var(--ct-menu-item-font-size);
    transform: rotate(270deg);
    position: absolute;
    right: var(--ct-menu-item-padding-x);
  }
  html[data-layout=topnav] .navbar-toggle {
    display: block;
  }
}
@media (min-width: 1400px) {
  html[data-layout=topnav] .container-fluid, html[data-layout=topnav] .container-sm, html[data-layout=topnav] .container-md, html[data-layout=topnav] .container-lg, html[data-layout=topnav] .container-xl, html[data-layout=topnav] .container-xxl {
    max-width: 85%;
  }
}
:root,
[data-bs-theme=light] {
  --ct-input-bg: #fff;
}

[data-bs-theme=dark] {
  --ct-light: #464f5b;
  --ct-light-rgb: 70, 79, 91;
  --ct-dark: #f1f1f1;
  --ct-dark-rgb: 241, 241, 241;
  --ct-secondary: #6c757d;
  --ct-secondary-rgb: 108, 117, 125;
  --ct-input-bg: #404954;
  --ct-box-shadow: 0px 0px 35px 0px rgba(49, 57, 66, 0.5);
}

.bg-primary-lighten {
  background-color: var(--ct-primary-bg-subtle) !important;
}

.bg-secondary-lighten {
  background-color: var(--ct-secondary-bg-subtle) !important;
}

.bg-success-lighten {
  background-color: var(--ct-success-bg-subtle) !important;
}

.bg-info-lighten {
  background-color: var(--ct-info-bg-subtle) !important;
}

.bg-warning-lighten {
  background-color: var(--ct-warning-bg-subtle) !important;
}

.bg-danger-lighten {
  background-color: var(--ct-danger-bg-subtle) !important;
}

.bg-light-lighten {
  background-color: var(--ct-light-bg-subtle) !important;
}

.bg-dark-lighten {
  background-color: var(--ct-dark-bg-subtle) !important;
}

.accordion-header {
  margin: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.accordion-button {
  font-weight: 500;
}

.custom-accordion .card {
  box-shadow: none;
}
.custom-accordion .card-header {
  background-color: var(--ct-tertiary-bg);
}
.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
  position: absolute;
  right: 0;
}
.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "\f0142";
}

.custom-accordion-title {
  position: relative;
  color: var(--ct-secondary-color);
}
.custom-accordion-title:hover {
  color: var(--ct-tertiary-color);
}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: var(--ct-primary);
  color: #fff;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Design Icons";
  font-size: 16px;
  line-height: 1.3;
}

.btn .mdi:before {
  line-height: initial;
}

.btn-primary {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-primary-rgb), 0.5);
}

.btn-soft-primary {
  --ct-btn-color: var(--ct-primary);
  --ct-btn-bg: var(--ct-primary-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-primary);
  --ct-btn-hover-border-color: var(--ct-primary);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-primary);
  --ct-btn-active-border-color: var(--ct-primary);
  --ct-btn-disabled-color: var(--ct-primary);
  --ct-btn-disabled-bg: var(--ct-primary);
  --ct-btn-disabled-border-color: var(--ct-primary);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-primary)-rgb, 0.5);
}

.btn-secondary {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-secondary-rgb), 0.5);
}

.btn-soft-secondary {
  --ct-btn-color: var(--ct-secondary);
  --ct-btn-bg: var(--ct-secondary-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-secondary);
  --ct-btn-hover-border-color: var(--ct-secondary);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-secondary);
  --ct-btn-active-border-color: var(--ct-secondary);
  --ct-btn-disabled-color: var(--ct-secondary);
  --ct-btn-disabled-bg: var(--ct-secondary);
  --ct-btn-disabled-border-color: var(--ct-secondary);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-secondary)-rgb, 0.5);
}

.btn-success {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-success-rgb), 0.5);
}

.btn-soft-success {
  --ct-btn-color: var(--ct-success);
  --ct-btn-bg: var(--ct-success-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-success);
  --ct-btn-hover-border-color: var(--ct-success);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-success);
  --ct-btn-active-border-color: var(--ct-success);
  --ct-btn-disabled-color: var(--ct-success);
  --ct-btn-disabled-bg: var(--ct-success);
  --ct-btn-disabled-border-color: var(--ct-success);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-success)-rgb, 0.5);
}

.btn-info {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-info-rgb), 0.5);
}

.btn-soft-info {
  --ct-btn-color: var(--ct-info);
  --ct-btn-bg: var(--ct-info-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-info);
  --ct-btn-hover-border-color: var(--ct-info);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-info);
  --ct-btn-active-border-color: var(--ct-info);
  --ct-btn-disabled-color: var(--ct-info);
  --ct-btn-disabled-bg: var(--ct-info);
  --ct-btn-disabled-border-color: var(--ct-info);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-info)-rgb, 0.5);
}

.btn-warning {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-warning-rgb), 0.5);
}

.btn-soft-warning {
  --ct-btn-color: var(--ct-warning);
  --ct-btn-bg: var(--ct-warning-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-warning);
  --ct-btn-hover-border-color: var(--ct-warning);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-warning);
  --ct-btn-active-border-color: var(--ct-warning);
  --ct-btn-disabled-color: var(--ct-warning);
  --ct-btn-disabled-bg: var(--ct-warning);
  --ct-btn-disabled-border-color: var(--ct-warning);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-warning)-rgb, 0.5);
}

.btn-danger {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-danger-rgb), 0.5);
}

.btn-soft-danger {
  --ct-btn-color: var(--ct-danger);
  --ct-btn-bg: var(--ct-danger-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-danger);
  --ct-btn-hover-border-color: var(--ct-danger);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-danger);
  --ct-btn-active-border-color: var(--ct-danger);
  --ct-btn-disabled-color: var(--ct-danger);
  --ct-btn-disabled-bg: var(--ct-danger);
  --ct-btn-disabled-border-color: var(--ct-danger);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-danger)-rgb, 0.5);
}

.btn-light {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-light-rgb), 0.5);
}

.btn-soft-light {
  --ct-btn-color: var(--ct-light);
  --ct-btn-bg: var(--ct-light-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-light);
  --ct-btn-hover-border-color: var(--ct-light);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-light);
  --ct-btn-active-border-color: var(--ct-light);
  --ct-btn-disabled-color: var(--ct-light);
  --ct-btn-disabled-bg: var(--ct-light);
  --ct-btn-disabled-border-color: var(--ct-light);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-light)-rgb, 0.5);
}

.btn-dark {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-dark-rgb), 0.5);
}

.btn-soft-dark {
  --ct-btn-color: var(--ct-dark);
  --ct-btn-bg: var(--ct-dark-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-dark);
  --ct-btn-hover-border-color: var(--ct-dark);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-dark);
  --ct-btn-active-border-color: var(--ct-dark);
  --ct-btn-disabled-color: var(--ct-dark);
  --ct-btn-disabled-bg: var(--ct-dark);
  --ct-btn-disabled-border-color: var(--ct-dark);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-dark)-rgb, 0.5);
}

.btn-light {
  --ct-btn-color: var(--ct-dark);
  --ct-btn-bg: var(--ct-light);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: var(--ct-tertiary-color);
  --ct-btn-hover-bg: var(--ct-tertiary-bg);
  --ct-btn-hover-border-color: var(--ct-tertiary-bg);
  --ct-btn-active-bg: var(--ct-tertiary-bg);
  --ct-btn-active-color: var(--ct-tertiary-color);
  --ct-btn-active-border-color: var(--ct-tertiary-bg);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-light-rgb), 0.5);
}

.btn-dark {
  --ct-btn-color: var(--ct-light);
  --ct-btn-bg: var(--ct-dark);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: var(--ct-light);
  --ct-btn-hover-bg: var(--ct-dark);
  --ct-btn-hover-border-color: var(--ct-dark);
  --ct-btn-active-bg: var(--ct-dark);
  --ct-btn-active-color: var(--ct-light);
  --ct-btn-active-border-color: var(--ct-dark);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-dark-rgb), 0.5);
}

.btn-outline-light {
  --ct-btn-color: var(--ct-dark);
  --ct-btn-bg: transparent;
  --ct-btn-border-color: var(--ct-light);
  --ct-btn-hover-color: var(--ct-tertiary-color);
  --ct-btn-hover-bg: var(--ct-tertiary-bg);
  --ct-btn-hover-border-color: var(--ct-tertiary-bg);
  --ct-btn-active-bg: var(--ct-tertiary-bg);
  --ct-btn-active-color: var(--ct-tertiary-color);
  --ct-btn-active-border-color: var(--ct-tertiary-bg);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-light-rgb), 0.5);
}

.btn-outline-dark {
  --ct-btn-color: var(--ct-dark);
  --ct-btn-bg: transparent;
  --ct-btn-border-color: var(--ct-dark);
  --ct-btn-hover-color: var(--ct-light);
  --ct-btn-hover-bg: var(--ct-dark);
  --ct-btn-hover-border-color: var(--ct-dark);
  --ct-btn-active-bg: var(--ct-dark);
  --ct-btn-active-color: var(--ct-light);
  --ct-btn-active-border-color: var(--ct-dark);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-dark-rgb), 0.5);
}

.btn-soft-dark {
  --ct-btn-hover-color: var(--ct-light);
  --ct-btn-active-color: var(--ct-light);
}

[data-bs-theme=dark] .btn-secondary {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-light-rgb), 0.5);
}

.badge-primary-lighten {
  --ct-badge-color: var(--ct-primary);
  background-color: var(--ct-primary-bg-subtle);
}
.badge-primary-lighten[href] {
  color: var(--ct-primary);
  text-decoration: none;
  background-color: var(--ct-primary-bg-subtle);
}
.badge-primary-lighten[href]:is(:hover, :focus) {
  color: var(--ct-primary);
  text-decoration: none;
  background-color: var(--ct-primary-bg-subtle);
}

.badge-secondary-lighten {
  --ct-badge-color: var(--ct-secondary);
  background-color: var(--ct-secondary-bg-subtle);
}
.badge-secondary-lighten[href] {
  color: var(--ct-secondary);
  text-decoration: none;
  background-color: var(--ct-secondary-bg-subtle);
}
.badge-secondary-lighten[href]:is(:hover, :focus) {
  color: var(--ct-secondary);
  text-decoration: none;
  background-color: var(--ct-secondary-bg-subtle);
}

.badge-success-lighten {
  --ct-badge-color: var(--ct-success);
  background-color: var(--ct-success-bg-subtle);
}
.badge-success-lighten[href] {
  color: var(--ct-success);
  text-decoration: none;
  background-color: var(--ct-success-bg-subtle);
}
.badge-success-lighten[href]:is(:hover, :focus) {
  color: var(--ct-success);
  text-decoration: none;
  background-color: var(--ct-success-bg-subtle);
}

.badge-info-lighten {
  --ct-badge-color: var(--ct-info);
  background-color: var(--ct-info-bg-subtle);
}
.badge-info-lighten[href] {
  color: var(--ct-info);
  text-decoration: none;
  background-color: var(--ct-info-bg-subtle);
}
.badge-info-lighten[href]:is(:hover, :focus) {
  color: var(--ct-info);
  text-decoration: none;
  background-color: var(--ct-info-bg-subtle);
}

.badge-warning-lighten {
  --ct-badge-color: var(--ct-warning);
  background-color: var(--ct-warning-bg-subtle);
}
.badge-warning-lighten[href] {
  color: var(--ct-warning);
  text-decoration: none;
  background-color: var(--ct-warning-bg-subtle);
}
.badge-warning-lighten[href]:is(:hover, :focus) {
  color: var(--ct-warning);
  text-decoration: none;
  background-color: var(--ct-warning-bg-subtle);
}

.badge-danger-lighten {
  --ct-badge-color: var(--ct-danger);
  background-color: var(--ct-danger-bg-subtle);
}
.badge-danger-lighten[href] {
  color: var(--ct-danger);
  text-decoration: none;
  background-color: var(--ct-danger-bg-subtle);
}
.badge-danger-lighten[href]:is(:hover, :focus) {
  color: var(--ct-danger);
  text-decoration: none;
  background-color: var(--ct-danger-bg-subtle);
}

.badge-light-lighten {
  --ct-badge-color: var(--ct-light);
  background-color: var(--ct-light-bg-subtle);
}
.badge-light-lighten[href] {
  color: var(--ct-light);
  text-decoration: none;
  background-color: var(--ct-light-bg-subtle);
}
.badge-light-lighten[href]:is(:hover, :focus) {
  color: var(--ct-light);
  text-decoration: none;
  background-color: var(--ct-light-bg-subtle);
}

.badge-dark-lighten {
  --ct-badge-color: var(--ct-dark);
  background-color: var(--ct-dark-bg-subtle);
}
.badge-dark-lighten[href] {
  color: var(--ct-dark);
  text-decoration: none;
  background-color: var(--ct-dark-bg-subtle);
}
.badge-dark-lighten[href]:is(:hover, :focus) {
  color: var(--ct-dark);
  text-decoration: none;
  background-color: var(--ct-dark-bg-subtle);
}

.badge-outline-primary {
  --ct-badge-color: var(--ct-primary);
  border: 1px solid var(--ct-primary);
  background-color: transparent;
}
.badge-outline-primary[href] {
  color: var(--ct-primary);
  text-decoration: none;
  background-color: var(--ct-primary-bg-subtle);
}
.badge-outline-primary[href]:is(:hover, :focus) {
  color: var(--ct-primary);
  text-decoration: none;
  background-color: var(--ct-primary-bg-subtle);
}

.badge-outline-secondary {
  --ct-badge-color: var(--ct-secondary);
  border: 1px solid var(--ct-secondary);
  background-color: transparent;
}
.badge-outline-secondary[href] {
  color: var(--ct-secondary);
  text-decoration: none;
  background-color: var(--ct-secondary-bg-subtle);
}
.badge-outline-secondary[href]:is(:hover, :focus) {
  color: var(--ct-secondary);
  text-decoration: none;
  background-color: var(--ct-secondary-bg-subtle);
}

.badge-outline-success {
  --ct-badge-color: var(--ct-success);
  border: 1px solid var(--ct-success);
  background-color: transparent;
}
.badge-outline-success[href] {
  color: var(--ct-success);
  text-decoration: none;
  background-color: var(--ct-success-bg-subtle);
}
.badge-outline-success[href]:is(:hover, :focus) {
  color: var(--ct-success);
  text-decoration: none;
  background-color: var(--ct-success-bg-subtle);
}

.badge-outline-info {
  --ct-badge-color: var(--ct-info);
  border: 1px solid var(--ct-info);
  background-color: transparent;
}
.badge-outline-info[href] {
  color: var(--ct-info);
  text-decoration: none;
  background-color: var(--ct-info-bg-subtle);
}
.badge-outline-info[href]:is(:hover, :focus) {
  color: var(--ct-info);
  text-decoration: none;
  background-color: var(--ct-info-bg-subtle);
}

.badge-outline-warning {
  --ct-badge-color: var(--ct-warning);
  border: 1px solid var(--ct-warning);
  background-color: transparent;
}
.badge-outline-warning[href] {
  color: var(--ct-warning);
  text-decoration: none;
  background-color: var(--ct-warning-bg-subtle);
}
.badge-outline-warning[href]:is(:hover, :focus) {
  color: var(--ct-warning);
  text-decoration: none;
  background-color: var(--ct-warning-bg-subtle);
}

.badge-outline-danger {
  --ct-badge-color: var(--ct-danger);
  border: 1px solid var(--ct-danger);
  background-color: transparent;
}
.badge-outline-danger[href] {
  color: var(--ct-danger);
  text-decoration: none;
  background-color: var(--ct-danger-bg-subtle);
}
.badge-outline-danger[href]:is(:hover, :focus) {
  color: var(--ct-danger);
  text-decoration: none;
  background-color: var(--ct-danger-bg-subtle);
}

.badge-outline-light {
  --ct-badge-color: var(--ct-light);
  border: 1px solid var(--ct-light);
  background-color: transparent;
}
.badge-outline-light[href] {
  color: var(--ct-light);
  text-decoration: none;
  background-color: var(--ct-light-bg-subtle);
}
.badge-outline-light[href]:is(:hover, :focus) {
  color: var(--ct-light);
  text-decoration: none;
  background-color: var(--ct-light-bg-subtle);
}

.badge-outline-dark {
  --ct-badge-color: var(--ct-dark);
  border: 1px solid var(--ct-dark);
  background-color: transparent;
}
.badge-outline-dark[href] {
  color: var(--ct-dark);
  text-decoration: none;
  background-color: var(--ct-dark-bg-subtle);
}
.badge-outline-dark[href]:is(:hover, :focus) {
  color: var(--ct-dark);
  text-decoration: none;
  background-color: var(--ct-dark-bg-subtle);
}

.badge {
  vertical-align: middle;
}
.badge.badge-lg {
  padding: 0.5em 1em;
  font-size: 0.85em;
}

.card {
  box-shadow: var(--ct-box-shadow);
  margin-bottom: 1.5rem;
}
.card .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
}
.card .card-drop {
  font-size: 20px;
  color: inherit;
}
.card .card-widgets {
  float: right;
  height: 16px;
}
.card .card-widgets > a {
  color: inherit;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
}
.card .card-widgets > a.collapsed i:before {
  content: "\f0415";
}

.card-title,
.card-header {
  margin-top: 0;
}
.card-title .header-title,
.card-header .header-title {
  margin-bottom: 0;
}

.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: var(--ct-border-radius);
  background: var(--ct-tertiary-bg);
  opacity: 0.8;
  cursor: progress;
}
.card-disabled .card-portlets-loader {
  background-color: var(--ct-dark);
  animation: rotatebox 1.2s infinite ease-in-out;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.75rem;
  margin-top: -0.75rem;
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.card-pricing {
  position: relative;
}
.card-pricing .card-pricing-plan-name {
  padding-bottom: 20px;
}
.card-pricing .card-pricing-icon {
  font-size: 22px;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
}
.card-pricing .card-pricing-price {
  padding: 30px 0 0;
}
.card-pricing .card-pricing-price span {
  font-size: 40%;
  color: var(--ct-secondary-color);
  letter-spacing: 2px;
  text-transform: uppercase;
}
.card-pricing .card-pricing-features {
  color: var(--ct-secondary-color);
  list-style: none;
  margin: 0;
  padding: 20px 0 0 0;
}
.card-pricing .card-pricing-features li {
  padding: 15px;
}

@media (min-width: 992px) {
  .card-pricing-recommended {
    margin-top: -1.9375rem;
  }
}
.card-pricing-recommended .card-pricing-plan-tag {
  background-color: rgba(250, 92, 124, 0.2);
  color: #fa5c7c;
  padding: 5px 0;
  font-weight: 700;
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
}

.card-h-100 {
  height: calc(100% - 1.5rem);
}

.form-checkbox-primary .form-check-input:checked, .form-radio-primary .form-check-input:checked {
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
}

.form-checkbox-secondary .form-check-input:checked, .form-radio-secondary .form-check-input:checked {
  background-color: var(--ct-secondary);
  border-color: var(--ct-secondary);
}

.form-checkbox-success .form-check-input:checked, .form-radio-success .form-check-input:checked {
  background-color: var(--ct-success);
  border-color: var(--ct-success);
}

.form-checkbox-info .form-check-input:checked, .form-radio-info .form-check-input:checked {
  background-color: var(--ct-info);
  border-color: var(--ct-info);
}

.form-checkbox-warning .form-check-input:checked, .form-radio-warning .form-check-input:checked {
  background-color: var(--ct-warning);
  border-color: var(--ct-warning);
}

.form-checkbox-danger .form-check-input:checked, .form-radio-danger .form-check-input:checked {
  background-color: var(--ct-danger);
  border-color: var(--ct-danger);
}

.form-checkbox-light .form-check-input:checked, .form-radio-light .form-check-input:checked {
  background-color: var(--ct-light);
  border-color: var(--ct-light);
}

.form-checkbox-dark .form-check-input:checked, .form-radio-dark .form-check-input:checked {
  background-color: var(--ct-dark);
  border-color: var(--ct-dark);
}

.card-radio {
  padding: 0;
}
.card-radio .form-check-label {
  background-color: var(--ct-secondary-bg);
  border: 1px solid var(--ct-border-color);
  border-radius: 0.25rem;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  padding-right: 32px;
}
.card-radio .form-check-label:hover {
  cursor: pointer;
}
.card-radio .form-check-input {
  display: none;
}
.card-radio .form-check-input:checked + .form-check-label:before {
  content: "\f05e0";
  font-family: "Material Design Icons";
  position: absolute;
  bottom: 2px;
  right: 6px;
  font-size: 16px;
  color: var(--ct-primary);
}
.card-radio.dark .form-check-input:checked + .form-check-label:before {
  color: #fff;
}

.dropdown-menu {
  box-shadow: var(--ct-dropdown-box-shadow);
}

.dropdown-header {
  margin-top: 0;
}

.dropdown-menu-animated.dropdown-menu-end[style] {
  left: auto;
  right: 0;
}

.dropdown-menu-animated {
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  margin: 0;
  z-index: 1000;
}
.dropdown-menu-animated.show {
  top: 100% !important;
}
.dropdown-menu-animated i {
  display: inline-block;
}
.dropdown-menu-animated.dropdown-menu[data-popper-placement^=right], .dropdown-menu-animated.dropdown-menu[data-popper-placement^=top], .dropdown-menu-animated.dropdown-menu[data-popper-placement^=left] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(10px);
  }
}
@media (min-width: 576px) {
  .dropdown-lg {
    width: 320px;
  }
}
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: var(--ct-body-color);
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-icon-item:hover {
  background-color: var(--ct-tertiary-bg);
  color: var(--ct-emphasis-color);
}

.arrow-none:after {
  display: none;
}

.dropdown-toggle-split::before {
  content: "";
  position: absolute;
  background-color: rgba(49, 58, 70, 0.08);
  top: -1px;
  bottom: -1px;
  right: -1px;
  left: 0;
  border-radius: 0 0.15rem 0.15rem 0;
}

.dropstart .dropdown-toggle-split::before {
  inset: auto;
  border-radius: 0;
  position: relative;
  z-index: 1;
}
.dropstart .dropdown-toggle-split::after {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: rgba(49, 58, 70, 0.1);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0.15rem 0 0 0.15rem;
  border: none;
  margin-left: 0;
}

.hljs {
  display: block;
  padding: 0 1em;
  color: var(--ct-body-color);
  max-height: 420px;
  margin: -10px 0 -30px;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: var(--ct-body-color);
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #02a8b5;
}

.hljs-string,
.hljs-doctag {
  color: #fa5c7c;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #fa5c7c;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: var(--ct-primary);
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #0acf97;
}

.hljs-symbol,
.hljs-bullet {
  color: #ff679b;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #39afd1;
}

.hljs-meta {
  color: var(--ct-body-bg);
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-comment {
  color: var(--ct-secondary);
}

.tab-pane.code {
  border: 1px solid var(--ct-border-color);
  border-radius: 0.25rem;
  position: relative;
}
.tab-pane.code pre::-webkit-scrollbar {
  -webkit-appearance: none;
}
.tab-pane.code pre::-webkit-scrollbar:vertical {
  width: 5px;
  margin-right: 5px;
}
.tab-pane.code pre::-webkit-scrollbar:horizontal {
  height: 5px;
}
.tab-pane.code pre::-webkit-scrollbar-thumb {
  background-color: rgba(var(--ct-dark-rgb), 0.2);
  border-radius: 10px;
  margin-right: 5px;
  border: none;
}
.tab-pane.code pre::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.tab-pane.code pre::-webkit-scrollbar-corner {
  background-color: transparent;
}
.tab-pane.code .btn-copy-clipboard {
  position: absolute;
  right: 8px;
  top: 10px;
  background-color: var(--ct-secondary-bg);
  border: 1px solid #0acf97;
  color: #0acf97;
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.15rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.tab-pane.code .btn-copy-clipboard:hover, .tab-pane.code .btn-copy-clipboard:focus, .tab-pane.code .btn-copy-clipboard:active {
  background-color: #0acf97;
  color: #fff;
}

.form-control-light {
  background-color: var(--ct-light) !important;
  border-color: var(--ct-border-color) !important;
  border-width: var(--ct-theme-card-border-width);
  border-style: solid;
  border-color: var(--ct-border-color);
}

input.form-control[type=color],
input.form-control[type=range] {
  min-height: 39px;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.custom-select:invalid:focus,
.form-control:invalid:focus,
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.custom-select:valid:focus,
.form-control:valid:focus {
  box-shadow: none !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.5em + 0.56rem + calc(var(--ct-border-width) * 2));
}

.password-eye {
  cursor: pointer;
}
.password-eye:before {
  font-family: "Material Design Icons";
  content: "\f06d0";
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 16px;
}

.show-password .password-eye:before {
  content: "\f06d1";
}

.modal-title {
  margin-top: 0;
  color: inherit;
}

.modal-full-width {
  width: 95%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: var(--ct-secondary-bg);
  align-content: center;
  transform: translate(25%, 0) !important;
}
.modal-right button.btn-close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.modal.show .modal-right,
.modal.show .modal-left {
  transform: translate(0, 0) !important;
}

.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

.modal-filled {
  color: #fff;
}
.modal-filled .modal-header {
  background-color: rgba(255, 255, 255, 0.07);
}
.modal-filled .modal-title {
  color: #fff;
}
.modal-filled .modal-header,
.modal-filled .modal-footer {
  border: none;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  color: var(--ct-secondary-color);
  font-weight: 600;
}

.nav-pills > a {
  color: var(--ct-body-color);
  font-weight: 600;
}

.bg-nav-pills {
  background-color: var(--ct-tertiary-bg);
}

.nav-tabs.nav-bordered {
  border-bottom: 2px solid var(--ct-border-color);
}
.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -1px;
}
.nav-tabs.nav-bordered li a {
  border: 0;
  padding: 0.625rem 1.25rem;
}
.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid var(--ct-primary);
}

.pagination-rounded .page-link {
  border-radius: 30px !important;
  margin: 0 3px !important;
  border: none;
}

.popover-header {
  margin-top: 0;
}

.primary-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-primary) !important;
  --ct-popover-header-bg: var(--ct-primary) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.secondary-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-secondary) !important;
  --ct-popover-header-bg: var(--ct-secondary) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.success-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-success) !important;
  --ct-popover-header-bg: var(--ct-success) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.info-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-info) !important;
  --ct-popover-header-bg: var(--ct-info) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.warning-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-warning) !important;
  --ct-popover-header-bg: var(--ct-warning) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.danger-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-danger) !important;
  --ct-popover-header-bg: var(--ct-danger) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.light-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-light) !important;
  --ct-popover-header-bg: var(--ct-light) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.dark-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-dark) !important;
  --ct-popover-header-bg: var(--ct-dark) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

@media print {
  .leftside-menu,
  .end-bar,
  .page-title-box,
  .navbar-custom,
  .footer {
    display: none;
  }
  .card-body,
  .content-page,
  .end-bar,
  .content,
  body {
    padding: 0;
    margin: 0;
  }
  .card {
    box-shadow: none;
  }
}
.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

html {
  position: relative;
  min-height: 100%;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 10px;
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
body {
  overflow-x: hidden;
}

button,
a {
  outline: none !important;
}

label {
  font-weight: 600;
}

address.address-lg {
  line-height: 24px;
}

b,
strong {
  font-weight: 700;
}

.ribbon-box {
  position: relative;
  /* Ribbon two */
}
.ribbon-box .ribbon {
  position: relative;
  clear: both;
  padding: 5px 12px;
  margin-bottom: 15px;
  box-shadow: 2px 5px 10px rgba(49, 58, 70, 0.15);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}
.ribbon-box .ribbon:before {
  content: " ";
  border-style: solid;
  border-width: 10px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  margin-bottom: -10px;
  z-index: -1;
}
.ribbon-box .ribbon.float-start {
  margin-left: -30px;
  border-radius: 0 3px 3px 0;
}
.ribbon-box .ribbon.float-end {
  margin-right: -30px;
  border-radius: 3px 0 0 3px;
}
.ribbon-box .ribbon.float-end:before {
  right: 0;
}
.ribbon-box .ribbon.float-center span {
  margin: 0 auto 20px auto;
}
.ribbon-box .ribbon-content {
  clear: both;
}
.ribbon-box .ribbon-primary {
  background: var(--ct-primary);
}
.ribbon-box .ribbon-primary:before {
  border-color: var(--ct-primary-text) transparent transparent;
}
.ribbon-box .ribbon-secondary {
  background: var(--ct-secondary);
}
.ribbon-box .ribbon-secondary:before {
  border-color: var(--ct-secondary-text) transparent transparent;
}
.ribbon-box .ribbon-success {
  background: var(--ct-success);
}
.ribbon-box .ribbon-success:before {
  border-color: var(--ct-success-text) transparent transparent;
}
.ribbon-box .ribbon-info {
  background: var(--ct-info);
}
.ribbon-box .ribbon-info:before {
  border-color: var(--ct-info-text) transparent transparent;
}
.ribbon-box .ribbon-warning {
  background: var(--ct-warning);
}
.ribbon-box .ribbon-warning:before {
  border-color: var(--ct-warning-text) transparent transparent;
}
.ribbon-box .ribbon-danger {
  background: var(--ct-danger);
}
.ribbon-box .ribbon-danger:before {
  border-color: var(--ct-danger-text) transparent transparent;
}
.ribbon-box .ribbon-light {
  background: var(--ct-light);
}
.ribbon-box .ribbon-light:before {
  border-color: var(--ct-light-text) transparent transparent;
}
.ribbon-box .ribbon-dark {
  background: var(--ct-dark);
}
.ribbon-box .ribbon-dark:before {
  border-color: var(--ct-dark-text) transparent transparent;
}
.ribbon-box .ribbon-two {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon-box .ribbon-two span {
  font-size: 13px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  width: 100px;
  display: block;
  box-shadow: 0 0 8px 0 rgba(var(--ct-dark-rgb), 0.08), 0 1px 0 0 rgba(var(--ct-dark-rgb), 0.03);
  position: absolute;
  top: 19px;
  left: -21px;
  font-weight: 600;
}
.ribbon-box .ribbon-two span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two span:after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two-primary span {
  background: var(--ct-primary);
}
.ribbon-box .ribbon-two-primary span:before {
  border-left: 3px solid var(--ct-primary-text);
  border-top: 3px solid var(--ct-primary-text);
}
.ribbon-box .ribbon-two-primary span:after {
  border-right: 3px solid var(--ct-primary-text);
  border-top: 3px solid var(--ct-primary-text);
}
.ribbon-box .ribbon-two-secondary span {
  background: var(--ct-secondary);
}
.ribbon-box .ribbon-two-secondary span:before {
  border-left: 3px solid var(--ct-secondary-text);
  border-top: 3px solid var(--ct-secondary-text);
}
.ribbon-box .ribbon-two-secondary span:after {
  border-right: 3px solid var(--ct-secondary-text);
  border-top: 3px solid var(--ct-secondary-text);
}
.ribbon-box .ribbon-two-success span {
  background: var(--ct-success);
}
.ribbon-box .ribbon-two-success span:before {
  border-left: 3px solid var(--ct-success-text);
  border-top: 3px solid var(--ct-success-text);
}
.ribbon-box .ribbon-two-success span:after {
  border-right: 3px solid var(--ct-success-text);
  border-top: 3px solid var(--ct-success-text);
}
.ribbon-box .ribbon-two-info span {
  background: var(--ct-info);
}
.ribbon-box .ribbon-two-info span:before {
  border-left: 3px solid var(--ct-info-text);
  border-top: 3px solid var(--ct-info-text);
}
.ribbon-box .ribbon-two-info span:after {
  border-right: 3px solid var(--ct-info-text);
  border-top: 3px solid var(--ct-info-text);
}
.ribbon-box .ribbon-two-warning span {
  background: var(--ct-warning);
}
.ribbon-box .ribbon-two-warning span:before {
  border-left: 3px solid var(--ct-warning-text);
  border-top: 3px solid var(--ct-warning-text);
}
.ribbon-box .ribbon-two-warning span:after {
  border-right: 3px solid var(--ct-warning-text);
  border-top: 3px solid var(--ct-warning-text);
}
.ribbon-box .ribbon-two-danger span {
  background: var(--ct-danger);
}
.ribbon-box .ribbon-two-danger span:before {
  border-left: 3px solid var(--ct-danger-text);
  border-top: 3px solid var(--ct-danger-text);
}
.ribbon-box .ribbon-two-danger span:after {
  border-right: 3px solid var(--ct-danger-text);
  border-top: 3px solid var(--ct-danger-text);
}
.ribbon-box .ribbon-two-light span {
  background: var(--ct-light);
}
.ribbon-box .ribbon-two-light span:before {
  border-left: 3px solid var(--ct-light-text);
  border-top: 3px solid var(--ct-light-text);
}
.ribbon-box .ribbon-two-light span:after {
  border-right: 3px solid var(--ct-light-text);
  border-top: 3px solid var(--ct-light-text);
}
.ribbon-box .ribbon-two-dark span {
  background: var(--ct-dark);
}
.ribbon-box .ribbon-two-dark span:before {
  border-left: 3px solid var(--ct-dark-text);
  border-top: 3px solid var(--ct-dark-text);
}
.ribbon-box .ribbon-two-dark span:after {
  border-right: 3px solid var(--ct-dark-text);
  border-top: 3px solid var(--ct-dark-text);
}

input[data-switch] {
  display: none;
}
input[data-switch] + label {
  width: 56px;
  height: 24px;
  background-color: var(--ct-tertiary-bg);
  background-image: none;
  border-radius: 2rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:before {
  color: var(--ct-emphasis-color);
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 24px;
  position: absolute;
  right: 3px;
  margin: 0 0.21667rem;
  top: 0;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:after {
  content: "";
  position: absolute;
  left: 4px;
  background-color: var(--ct-dark-border-subtle);
  box-shadow: none;
  border-radius: 2rem;
  height: 18px;
  width: 18px;
  top: 3px;
  transition: all 0.1s ease-in-out;
}
input[data-switch]:checked + label {
  background-color: var(--ct-primary);
}
input[data-switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 4px;
}
input[data-switch]:checked + label:after {
  left: 34px;
  background-color: var(--ct-tertiary-bg);
}

input[data-switch=bool] + label {
  background-color: #fa5c7c;
}

input:disabled + label {
  opacity: 0.5;
  cursor: default;
}

input[data-switch=bool] + label:before,
input[data-switch=bool]:checked + label:before {
  color: #fff !important;
}

input[data-switch=bool] + label:after {
  background-color: var(--ct-tertiary-bg);
}

input[data-switch=primary]:checked + label {
  background-color: #727cf5;
}

input[data-switch=secondary]:checked + label {
  background-color: #6c757d;
}

input[data-switch=success]:checked + label {
  background-color: #0acf97;
}

input[data-switch=info]:checked + label {
  background-color: #39afd1;
}

input[data-switch=warning]:checked + label {
  background-color: #ffc35a;
}

input[data-switch=danger]:checked + label {
  background-color: #fa5c7c;
}

input[data-switch=light]:checked + label {
  background-color: #eef2f7;
}

input[data-switch=dark]:checked + label {
  background-color: #313a46;
}

.table-centered th,
.table-centered td {
  vertical-align: middle !important;
}

.table .table-user img {
  height: 30px;
  width: 30px;
}
.table .action-icon {
  color: var(--ct-secondary-color);
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
}
.table .action-icon:hover {
  color: var(--ct-emphasis-color);
}
.table > :not(caption) > * > * {
  background-color: var(--ct-table-bg) !important;
}
.table.table-bordered tbody {
  border-top: 1px solid;
  border-top-color: inherit;
}

.table-sm .action-icon {
  font-size: 1rem;
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

[data-bs-theme=dark] .table-light {
  --ct-table-color: var(--ct-body-color);
  --ct-table-bg: var(--ct-light);
  --ct-table-border-color: var(--ct-border-color);
}

.border-dashed {
  border-style: dashed !important;
}

.primary-tooltip {
  --ct-tooltip-bg: var(--ct-primary) !important;
}

.secondary-tooltip {
  --ct-tooltip-bg: var(--ct-secondary) !important;
}

.success-tooltip {
  --ct-tooltip-bg: var(--ct-success) !important;
}

.info-tooltip {
  --ct-tooltip-bg: var(--ct-info) !important;
}

.warning-tooltip {
  --ct-tooltip-bg: var(--ct-warning) !important;
}

.danger-tooltip {
  --ct-tooltip-bg: var(--ct-danger) !important;
}

.light-tooltip {
  --ct-tooltip-bg: var(--ct-light) !important;
}

.dark-tooltip {
  --ct-tooltip-bg: var(--ct-dark) !important;
}

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;
}
.progress-w-percent .progress {
  width: calc(100% - 50px);
  float: left;
  margin-top: 8px;
}
.progress-w-percent .progress-value {
  width: 40px;
  float: right;
  text-align: right;
  line-height: 20px;
}

.widget-flat {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .widget-flat i.widget-icon {
    display: none;
  }
}

.widget-icon {
  color: var(--ct-primary);
  font-size: 20px;
  background-color: rgba(var(--ct-primary-rgb), 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}

.dash-item-overlay {
  position: absolute;
  /*rtl:ignore*/
  text-align: left;
  /*rtl:ignore*/
  left: 8%;
  max-width: 350px;
  padding: 20px;
  z-index: 1;
}

.chart-content-bg {
  background-color: var(--ct-tertiary-bg);
}

.chart-content-border {
  border: 1px solid var(--ct-light-border-subtle);
}

.chart-widget-list p {
  border-bottom: 1px solid var(--ct-secondary-bg-subtle);
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.timeline-alt {
  padding: 20px 0;
  position: relative;
}
.timeline-alt .timeline-item {
  position: relative;
}
.timeline-alt .timeline-item:before {
  background-color: var(--ct-tertiary-bg);
  bottom: 0;
  content: "";
  left: 9px;
  position: absolute;
  top: 20px;
  width: 2px;
  z-index: 0;
}
.timeline-alt .timeline-item .timeline-icon {
  float: left;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background-color: #fff;
}
.timeline-alt .timeline-item .timeline-item-info {
  margin-left: 30px;
}
.timeline-alt .timeline-item:last-of-type::before {
  display: none;
}

.inbox-widget .inbox-item {
  border-bottom: 1px solid var(--ct-border-color);
  overflow: hidden;
  padding: 0.625rem 0;
  position: relative;
}
.inbox-widget .inbox-item:last-of-type {
  border-bottom: none;
}
.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-img img {
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
  color: var(--ct-tertiary-color);
  display: block;
  margin-bottom: 3px;
}
.inbox-widget .inbox-item .inbox-item-text {
  color: var(--ct-dark-border-subtle);
  display: block;
  font-size: 0.8125rem;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
  color: var(--ct-secondary-color);
  font-size: 0.6875rem;
  position: absolute;
  right: 5px;
  top: 10px;
}

.tilebox-one i {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
  opacity: 0.3;
}

.toll-free-box i {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 4rem;
  opacity: 0.4;
  transform: rotate(30deg);
}

.cta-box {
  background-image: url(../images/bg-pattern.png);
  background-size: cover;
}
.cta-box .cta-box-title {
  font-size: 20px;
  line-height: 30px;
}

.conversation-list {
  list-style: none;
  padding: 0 15px;
}
.conversation-list li {
  margin-bottom: 20px;
}
.conversation-list li .conversation-actions {
  float: right;
  display: none;
}
.conversation-list li:hover .conversation-actions {
  display: block;
}
.conversation-list .chat-avatar {
  float: left;
  text-align: center;
  width: 42px;
}
.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}
.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}
.conversation-list .ctext-wrap {
  background: var(--ct-light);
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;
}
.conversation-list .ctext-wrap i {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  position: relative;
}
.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
  left: -11px;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: var(--ct-light);
  border-width: 6px;
  margin-right: -1px;
  border-right-color: var(--ct-light);
}
.conversation-list .conversation-text {
  float: left;
  font-size: 13px;
  margin-left: 12px;
  width: 70%;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
  background-color: var(--ct-light);
}
.conversation-list .odd .ctext-wrap:after {
  border-color: transparent;
  border-left-color: var(--ct-light);
  border-top-color: var(--ct-light);
  right: -10px;
  left: auto;
}
.conversation-list .odd .conversation-actions {
  float: left;
}

.calendar-widget .datepicker-inline,
.calendar-widget table {
  width: 100%;
}
.calendar-widget .datepicker-inline tr td,
.calendar-widget .datepicker-inline tr th,
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td,
.calendar-widget table tr th,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  color: #fa5c7c !important;
  text-shadow: none;
  font-weight: 700;
}
.calendar-widget .datepicker-inline tr td.active.day:hover,
.calendar-widget .datepicker-inline tr td.today.day:hover,
.calendar-widget table tr td.active.day:hover,
.calendar-widget table tr td.today.day:hover {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline td,
.calendar-widget .datepicker-inline th,
.calendar-widget table td,
.calendar-widget table th {
  height: 45px;
}
.calendar-widget .datepicker-inline .datepicker-switch,
.calendar-widget .datepicker-inline .prev,
.calendar-widget .datepicker-inline .next,
.calendar-widget table .datepicker-switch,
.calendar-widget table .prev,
.calendar-widget table .next {
  font-size: 1.1rem;
  background-color: rgba(var(--ct-primary-rgb), 0.1) !important;
  border-radius: 0;
  color: var(--ct-primary);
}

.multi-user a {
  margin-left: -15px;
  border: 3px solid var(--ct-border-color);
  border-radius: 50px;
}
.multi-user a:first-child {
  margin-left: 0px;
}

.card-bg-img {
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid var(--ct-dark-border-subtle);
  border-radius: 50%;
  color: var(--ct-dark-border-subtle);
}
.social-list-item:hover {
  color: var(--ct-secondary-color);
  border-color: var(--ct-secondary-color);
}

.horizontal-steps {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.horizontal-steps:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.2em;
  background-color: var(--ct-border-color);
}
.horizontal-steps .process-line {
  display: block;
  position: absolute;
  width: 50%;
  height: 0.2em;
  background-color: var(--ct-primary);
}
.horizontal-steps .horizontal-steps-content {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.horizontal-steps .horizontal-steps-content .step-item {
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
  height: 8px;
  width: 8px;
  margin: 0 2em;
  box-sizing: content-box;
  color: var(--ct-primary);
  background-color: currentColor;
  border: 0.25em solid var(--ct-body-bg);
  border-radius: 50%;
  z-index: 5;
}
.horizontal-steps .horizontal-steps-content .step-item:first-child {
  margin-left: 0;
}
.horizontal-steps .horizontal-steps-content .step-item:last-child {
  margin-right: 0;
  color: #0acf97;
}
.horizontal-steps .horizontal-steps-content .step-item span {
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: var(--ct-dark-border-subtle);
}
.horizontal-steps .horizontal-steps-content .step-item.current:before {
  content: "";
  display: block;
  position: absolute;
  top: 48%;
  left: 48%;
  padding: 1em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  animation-name: animation-steps-current;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
.horizontal-steps .horizontal-steps-content .step-item.current span {
  color: var(--ct-primary) !important;
}

@keyframes animation-steps-current {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
@media (max-width: 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
    white-space: inherit;
  }
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ct-tertiary-bg);
  z-index: 9999;
}

#status {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}
.bouncing-loader {
  display: flex;
  justify-content: center;
}
.bouncing-loader > div {
  width: 13px;
  height: 13px;
  margin: 32px 3px;
  background: var(--ct-primary);
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
  background: #fa5c7c;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
  background: #0acf97;
}

.hero-section {
  position: relative;
  padding: 80px 0 120px 0;
}
.hero-section:after {
  content: " ";
  background-image: linear-gradient(to bottom, #6379c3, #546ee5);
  position: absolute;
  top: -400px;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  border-radius: 0;
  transform: skewY(-3deg);
}

.auth-brand {
  margin-bottom: 2rem;
}
.auth-brand .logo-dark {
  display: block;
}
.auth-brand .logo-light {
  display: none;
}

html[data-theme=dark] .auth-brand .logo-dark {
  display: none;
}
html[data-theme=dark] .auth-brand .logo-light {
  display: block;
}

body.authentication-bg .account-pages {
  align-items: center;
  display: flex;
  min-height: 100vh;
}

.authentication-bg.enlarged,
.auth-fluid-pages.enlarged,
body.auth-fluid-pages[data-sidenav-size=condensed] {
  min-height: 100px;
}

.logout-icon {
  width: 140px;
}

.auth-fluid {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  background: url("../images/bg-auth.jpg") center;
  background-size: cover;
}
.auth-fluid .auth-fluid-form-box {
  max-width: 480px;
  border-radius: 0;
  z-index: 2;
  padding: 3rem;
  background-color: var(--ct-secondary-bg);
  position: relative;
  width: 100%;
}
.auth-fluid .auth-fluid-right,
.auth-fluid .auth-fluid-left {
  padding: 6rem 3rem;
  flex: 1;
  position: relative;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}
.auth-user-testimonial p.lead {
  font-size: 1.125rem;
  margin: 0 auto 20px auto;
  max-width: 700px;
}

@media (max-width: 991.98px) {
  .auth-fluid {
    display: block;
  }
  .auth-fluid .auth-fluid-form-box {
    max-width: 100%;
    min-height: 100vh;
  }
  .auth-fluid .auth-fluid-right {
    display: none;
  }
}
html[data-theme=dark] .auth-brand .logo-light {
  display: block;
}
html[data-theme=dark] .auth-brand .logo-dark {
  display: none;
}

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}

.grid-structure .grid-container {
  background-color: var(--ct-tertiary-bg);
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px 20px;
}

.icons-list-demo div {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.icons-list-demo div p {
  margin-bottom: 0;
  line-height: inherit;
}
.icons-list-demo div:hover, .icons-list-demo div:hover i {
  color: var(--ct-primary);
}
.icons-list-demo i {
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  border-radius: 3px;
  display: inline-block;
  transition: all 0.2s;
}

.text-error {
  color: var(--ct-primary);
  text-shadow: rgba(var(--ct-primary-rgb), 0.3) 5px 1px, rgba(var(--ct-primary-rgb), 0.2) 10px 3px;
  font-size: 5.25rem;
  line-height: 5.625rem;
}

.faq-question-q-box {
  height: 30px;
  width: 30px;
  color: var(--ct-primary);
  background-color: rgba(var(--ct-primary-rgb), 0.25);
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  text-align: center;
  border-radius: 50%;
  float: left;
  font-weight: 700;
  line-height: 30px;
}

.faq-question {
  margin-top: 0;
  margin-left: 50px;
  font-weight: 600;
  font-size: 16px;
  color: var(--ct-tertiary-color);
}

.faq-answer {
  margin-left: 50px;
}

.maintenance-icon {
  font-size: 22px;
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 58px;
  border-radius: 50%;
}

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  border: 1px solid var(--ct-border-color);
  vertical-align: top;
  margin-bottom: 1.5rem;
  border-radius: var(--ct-border-radius);
}
.tasks.tasks:not(:last-child) {
  margin-right: 1.25rem;
}
.tasks .card {
  white-space: normal;
  margin-top: 1rem;
}
.tasks .task-header {
  background-color: var(--ct-tertiary-bg);
  padding: 1rem;
  margin: 0 -1rem;
}

.task-list-items {
  min-height: 100px;
  position: relative;
}
.task-list-items:before {
  content: "No Tasks";
  position: absolute;
  line-height: 110px;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.task-modal-content .form-control-light {
  background-color: var(--ct-light) !important;
  border-color: var(--ct-border-color) !important;
}

.gantt-task-details {
  min-width: 220px;
}

.page-aside-left {
  width: 240px;
  float: left;
  padding: 0 20px 20px 10px;
  position: relative;
}
.page-aside-left:before {
  content: "";
  background-color: var(--ct-body-bg);
  width: 5px;
  position: absolute;
  right: -15px;
  height: 100%;
  bottom: -1.5rem;
}

.page-aside-right {
  margin: -1.5rem 0 -1.5rem 250px;
  border-left: 5px solid var(--ct-body-bg);
  padding: 1.5rem 0 1.5rem 25px;
}

.email-list {
  display: block;
  padding-left: 0;
  overflow: hidden;
}
.email-list > li {
  position: relative;
  display: block;
  height: 51px;
  line-height: 50px;
  cursor: default;
  transition-duration: 0.3s;
}
.email-list > li a {
  color: var(--ct-body-color);
}
.email-list > li a:hover {
  color: var(--ct-tertiary-color);
}
.email-list > li .col-mail {
  float: left;
  position: relative;
}
.email-list > li .email-sender-info {
  width: 320px;
}
.email-list > li .email-sender-info .star-toggle,
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  display: block;
  float: left;
}
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.email-list > li .email-sender-info .star-toggle {
  color: var(--ct-dark-border-subtle);
  margin-left: 10px;
  font-size: 18px;
}
.email-list > li .email-sender-info .email-title {
  position: absolute;
  top: 0;
  left: 100px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 50px;
}
.email-list > li .email-content {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
}
.email-list > li .email-content .email-subject,
.email-list > li .email-content .email-date {
  position: absolute;
  top: 0;
}
.email-list > li .email-content .email-subject {
  left: 0;
  right: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.email-list > li .email-content .email-date {
  right: 0;
  width: 100px;
  text-align: right;
  padding-left: 10px;
}
.email-list > li.active, .email-list > li.mail-selected {
  background: var(--ct-tertiary-bg);
  transition-duration: 0.05s;
}
.email-list > li.unread a {
  font-weight: 600;
  color: var(--ct-dark);
}
.email-list > li .email-action-icons {
  opacity: 0;
}
.email-list > li .email-action-icons ul {
  display: flex;
  position: absolute;
  transition: all 0.5s;
  right: -180px;
}
.email-list > li .email-action-icons ul li {
  margin: 0 10px;
}
.email-list > li .email-action-icons ul .email-action-icons-item {
  font-size: 20px;
  transition: all 0.5s;
}
.email-list > li .email-action-icons ul .email-action-icons-item:hover {
  color: #fa5c7c;
}
.email-list > li:hover {
  background: var(--ct-tertiary-bg);
  transition-duration: 0.05s;
}
.email-list > li:hover .email-action-icons {
  opacity: 1;
}
.email-list > li:hover .email-action-icons ul {
  transition: all 0.5s;
  right: 10px;
}
.email-list > li:hover .email-content .email-date {
  opacity: 0;
}
.email-list > li:hover .email-content .email-subject {
  right: 180px;
  transition: all 0.5s;
}

.email-menu-list a {
  color: var(--ct-secondary-color);
  padding: 12px 5px;
  display: block;
  font-size: 15px;
}
.email-menu-list a:hover {
  color: var(--ct-tertiary-color);
}
.email-menu-list a .badge {
  margin-top: 3px;
}

.labels-list a {
  padding: 7px 5px;
}

.write-mdg-box .CodeMirror {
  height: 150px;
}

@media (max-width: 648px) {
  .page-aside-left {
    width: 100%;
    float: none;
    padding: 0 10px 20px 10px;
  }
  .page-aside-left:before {
    width: 0;
  }
  .page-aside-right {
    margin-left: 0;
    border: 0;
    padding-left: 0;
  }
  .email-list li .email-sender-info .checkbox-wrapper-mail {
    margin-left: 0;
  }
}
@media (max-width: 520px) {
  .page-aside-right > .btn-group {
    margin-bottom: 10px;
  }
  .email-list li .email-sender-info {
    width: 150px;
  }
  .email-list li .email-sender-info .email-title {
    left: 80px;
  }
  .email-list li .email-content {
    display: none;
  }
}
.timeline {
  margin-bottom: 50px;
  position: relative;
}
.timeline:before {
  background-color: var(--ct-border-color);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 2px;
  z-index: 0;
}

.timeline-show {
  position: relative;
}
.timeline-show .time-show-name {
  display: inline-block;
  border-radius: 4px;
  background-color: var(--ct-border-color);
  padding: 7px 15px;
}

.timeline-box {
  background-color: var(--ct-secondary-bg);
  box-shadow: var(--ct-box-shadow);
  display: block;
  margin: 15px 0;
  padding: 1.5rem;
  border-radius: var(--ct-border-radius);
}

.timeline-album {
  margin-top: 12px;
}
.timeline-album a {
  display: inline-block;
  margin-right: 5px;
}
.timeline-album img {
  height: 36px;
  width: auto;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .timeline .timeline-box {
    margin-left: 65px;
  }
  .timeline .timeline-icon {
    background: var(--ct-border-color);
    border-radius: 50%;
    display: block;
    height: 24px;
    left: -11px;
    margin-top: -12px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 24px;
  }
  .timeline .timeline-icon i {
    color: var(--ct-secondary-color);
    font-size: 1rem;
    vertical-align: middle;
  }
  .timeline .timeline-desk {
    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 50%;
  }
  .timeline-lg-item {
    display: flex;
  }
  .timeline-lg-item:before {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-lg-item .timeline-desk .arrow {
    border-bottom: 12px solid transparent;
    border-right: 12px solid var(--ct-secondary-bg) !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: 53px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 0;
    box-shadow: var(--ct-box-shadow);
  }
  .timeline-lg-item.timeline-item-left:after {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .arrow-alt {
    border-bottom: 12px solid transparent;
    border-left: 12px solid var(--ct-secondary-bg) !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: auto;
    margin-top: -12px;
    position: absolute;
    right: 53px;
    top: 50%;
    width: 0;
    box-shadow: var(--ct-box-shadow);
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .album {
    float: right;
    margin-top: 20px;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .album a {
    float: right;
    margin-left: 5px;
  }
  .timeline-lg-item.timeline-item-left .timeline-icon {
    left: auto;
    right: -13px;
  }
  .timeline-lg-item.timeline-item-left:before {
    display: none;
  }
  .timeline-lg-item.timeline-item-left .timeline-box {
    margin-right: 65px;
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .timeline .timeline-icon {
    display: none;
  }
}
.apex-charts {
  min-height: 10px !important;
}
.apex-charts text {
  font-family: var(--ct-font-sans-serif) !important;
}
.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text,
.apexcharts-xaxistooltip-text,
.apexcharts-datalabels text {
  font-family: var(--ct-font-sans-serif) !important;
}

.apexcharts-tooltip {
  border: 1px solid var(--ct-border-color) !important;
  background-color: var(--ct-secondary-bg) !important;
  box-shadow: var(--ct-box-shadow) !important;
}
.apexcharts-tooltip * {
  font-family: var(--ct-font-sans-serif) !important;
  color: var(--ct-tertiary-color) !important;
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  background-color: rgba(var(--ct-light-rgb), 0.75) !important;
  border-bottom: 1px solid var(--ct-border-color) !important;
}

.apexcharts-tooltip.apexcharts-theme-dark * {
  color: #fff !important;
}

.apexcharts-legend-series {
  font-weight: 600;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: var(--ct-secondary-bg-subtle);
}

.apexcharts-xaxis-tick {
  stroke: var(--ct-border-color);
}

.apexcharts-legend-text {
  color: var(--ct-tertiary-color) !important;
  font-family: var(--ct-font-sans-serif) !important;
  font-size: 13px !important;
  vertical-align: middle;
}

.apexcharts-legend-marker {
  vertical-align: middle;
  margin-right: 5px !important;
  top: -1px !important;
}

.apexcharts-pie-label {
  fill: #fff !important;
}

.apexcharts-title-text,
.apexcharts-subtitle-text {
  fill: var(--ct-secondary-color);
  font-family: var(--ct-font-sans-serif) !important;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: var(--ct-font-sans-serif) !important;
  fill: var(--ct-secondary-color);
}

.apexcharts-yaxis-title {
  font-weight: 500;
}

.apexcharts-annotation-rect {
  fill: var(--ct-tertiary-bg) !important;
}

.apexcharts-xaxis-annotations rect,
.apexcharts-yaxis-annotations rect {
  stroke: none;
}

.apexcharts-grid-borders .apexcharts-gridline + line {
  stroke: var(--ct-border-color);
}

.apex-toolbar .btn-light.active {
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(var(--ct-primary-rgb), 0.5);
}

.apexcharts-xaxistooltip {
  background-color: var(--ct-secondary-bg) !important;
  border: var(--ct-border-width) solid var(--ct-border-color) !important;
  box-shadow: var(--ct-box-shadow) !important;
  color: var(--ct-body-color) !important;
}

.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: var(--ct-border-color) !important;
}

.apexcharts-datalabel-value {
  fill: var(--ct-body-color) !important;
}

.apexcharts-menu {
  background: var(--ct-secondary-bg) !important;
  padding: 0.25rem 0 !important;
  box-shadow: var(--ct-box-shadow) !important;
  border: var(--ct-border-width) solid var(--ct-border-color) !important;
  text-align: center !important;
}
.apexcharts-menu .apexcharts-menu-item:hover {
  color: var(--ct-emphasis-color) !important;
  background-color: var(--ct-tertiary-bg) !important;
}

.apexcharts-radar-series polygon {
  fill: transparent;
  stroke: var(--ct-border-color);
}
.apexcharts-radar-series line {
  stroke: var(--ct-border-color);
}

.apexcharts-track path {
  stroke: var(--ct-tertiary-bg);
}

.apexcharts-marker {
  stroke: var(--ct-body-bg);
}

.apexcharts-grid rect {
  fill: none;
}

.apex-charts .apexcharts-heatmap-series rect,
.apex-charts .apexcharts-treemap rect,
.apex-charts .apexcharts-bar-series path,
.apex-charts .apexcharts-pie-series path {
  stroke: var(--ct-body-bg);
}

.apexcharts-pie circle,
.apexcharts-pie line {
  stroke: var(--ct-border-color);
}

.britechart,
.tick text {
  font-family: var(--ct-font-sans-serif);
  font-size: 0.75rem;
}

.horizontal-grid-line,
.vertical-grid-line,
.extended-x-line,
.extended-y-line {
  stroke: var(--ct-border-color);
}

.tick text,
.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value {
  fill: var(--ct-secondary-color);
}

:root {
  --fc-border-color: var(--ct-border-color);
  --fc-page-bg-color: var(--ct-secondary-bg);
  --fc-neutral-bg-color: var(--ct-light);
}

.fc td,
.fc th {
  border: var(--ct-border-width) solid var(--ct-border-color);
}

.fc .fc-toolbar h2, .fc .fc-toolbar .h2 {
  font-size: 1.25rem !important;
  line-height: 1.875rem;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .fc .fc-toolbar .fc-left,
  .fc .fc-toolbar .fc-right,
  .fc .fc-toolbar .fc-center {
    float: none;
    display: block;
    text-align: center;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc .fc-toolbar .fc-today-button {
    display: none;
  }
}
.fc .fc-toolbar .btn {
  text-transform: capitalize;
}
.fc .fc-col-header-cell-cushion {
  display: block;
  padding: 2px 4px;
  color: var(--ct-body-color);
}
.fc .fc-daygrid-day-number {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  background-color: var(--ct-tertiary-bg);
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 500;
  margin: 2px;
  color: var(--ct-body-color);
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(114, 124, 245, 0.1);
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background-color: #727cf5;
  color: #fff;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(114, 124, 245, 0.1);
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(114, 124, 245, 0.1);
}
.fc .fc-list-event:hover td {
  background: transparent;
}
.fc .fc-list-event-title a {
  color: #fff !important;
}
.fc .fc-col-header,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-sync-table {
  width: 100% !important;
}
.fc .fc-scrollgrid-section > * {
  border-left: var(--ct-border-width) solid var(--ct-border-color);
  border-top: var(--ct-border-width) solid var(--ct-border-color);
}
.fc .fc-scrollgrid {
  border: 0;
}
.fc .fc-scrollgrid-section-liquid > td {
  border-top: 0;
}
.fc .fc-multimonth {
  border-color: var(--ct-border-color);
}

.fc-theme-bootstrap a:not([href]) {
  color: var(--ct-body-color);
}

.fc-event {
  color: #fff;
}

.fc th.fc-widget-header {
  background: #dee2e6;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: #dee2e6;
}
.fc-unthemed td.fc-today {
  background: #f5f7fa;
}

.fc-button {
  background: var(--ct-primary);
  border-color: var(--ct-primary);
  color: #6c757d;
  text-transform: capitalize;
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-primary-rgb), 0.5);
  padding: 0.28rem 0.8rem !important;
  height: auto !important;
}

.fc .fc-multimonth-multicol .fc-daygrid-more-link {
  border: 1px solid #727cf5;
  display: block;
  float: none;
  padding: 1px;
  font-weight: 500;
  font-family: inherit;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #727cf5;
  color: #fff;
  text-shadow: none;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-theme-standard .fc-popover .fc-popover-header {
  padding: 8px 14px;
}

.fc-event,
.fc-event-dot {
  background-color: #727cf5;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background-color: #727cf5;
}

.fc-event-title,
.fc-sticky {
  font-weight: 600 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fc-daygrid-event-dot {
  border-color: #fff !important;
}

.fc-event .fc-content {
  color: #fff;
}

#external-events .external-event {
  text-align: left;
  padding: 8px 16px;
  margin: 6px 0;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark .fc-content {
  color: #eef2f7;
}

.fc-toolbar-chunk .fc-button-group {
  box-shadow: var(--ct-box-shadow);
}
.fc-toolbar-chunk .fc-button-group .fc-button {
  color: #fff;
  background-color: #727cf5;
  border: none;
  box-shadow: none;
}
.fc-toolbar-chunk .fc-button-group .fc-button:hover, .fc-toolbar-chunk .fc-button-group .fc-button.active {
  color: #fff;
  background-color: #727cf5;
}
.fc-toolbar-chunk .fc-today-button {
  background-color: #727cf5 !important;
  border: #727cf5;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #727cf5 !important;
  border-color: #727cf5 !important;
  color: #fff !important;
}
.fc .fc-button-primary {
  text-transform: capitalize;
}

@media (max-width: 575.98px) {
  .fc-toolbar {
    flex-direction: column;
    gap: 16px;
  }
}

.fc-daygrid-event-harness .fc-daygrid-event {
  backdrop-filter: blur(4px);
}

.fc-timegrid-event-harness .fc-timegrid-event {
  backdrop-filter: blur(4px);
}

.fc-timegrid-slots table tr {
  border-color: var(--ct-border-color) !important;
}

.fc-list-table {
  border-color: var(--ct-border-color);
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-primary-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-primary-subtle .fc-event-main {
  color: #727cf5 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-primary-subtle .fc-event-title {
  color: #727cf5 !important;
}

.fc-list-table .fc-list-event.bg-primary-subtle {
  color: #727cf5 !important;
}
.fc-list-table .fc-list-event.bg-primary-subtle .fc-list-event-title > a {
  color: #727cf5 !important;
}
.fc-list-table .fc-list-event.bg-primary-subtle .fc-list-event-dot {
  border-color: #727cf5;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-secondary-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-secondary-subtle .fc-event-main {
  color: #6c757d !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-secondary-subtle .fc-event-title {
  color: #6c757d !important;
}

.fc-list-table .fc-list-event.bg-secondary-subtle {
  color: #6c757d !important;
}
.fc-list-table .fc-list-event.bg-secondary-subtle .fc-list-event-title > a {
  color: #6c757d !important;
}
.fc-list-table .fc-list-event.bg-secondary-subtle .fc-list-event-dot {
  border-color: #6c757d;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-success-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-success-subtle .fc-event-main {
  color: #0acf97 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-success-subtle .fc-event-title {
  color: #0acf97 !important;
}

.fc-list-table .fc-list-event.bg-success-subtle {
  color: #0acf97 !important;
}
.fc-list-table .fc-list-event.bg-success-subtle .fc-list-event-title > a {
  color: #0acf97 !important;
}
.fc-list-table .fc-list-event.bg-success-subtle .fc-list-event-dot {
  border-color: #0acf97;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-info-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-info-subtle .fc-event-main {
  color: #39afd1 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-info-subtle .fc-event-title {
  color: #39afd1 !important;
}

.fc-list-table .fc-list-event.bg-info-subtle {
  color: #39afd1 !important;
}
.fc-list-table .fc-list-event.bg-info-subtle .fc-list-event-title > a {
  color: #39afd1 !important;
}
.fc-list-table .fc-list-event.bg-info-subtle .fc-list-event-dot {
  border-color: #39afd1;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-warning-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-warning-subtle .fc-event-main {
  color: #ffc35a !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-warning-subtle .fc-event-title {
  color: #ffc35a !important;
}

.fc-list-table .fc-list-event.bg-warning-subtle {
  color: #ffc35a !important;
}
.fc-list-table .fc-list-event.bg-warning-subtle .fc-list-event-title > a {
  color: #ffc35a !important;
}
.fc-list-table .fc-list-event.bg-warning-subtle .fc-list-event-dot {
  border-color: #ffc35a;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-danger-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-danger-subtle .fc-event-main {
  color: #fa5c7c !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-danger-subtle .fc-event-title {
  color: #fa5c7c !important;
}

.fc-list-table .fc-list-event.bg-danger-subtle {
  color: #fa5c7c !important;
}
.fc-list-table .fc-list-event.bg-danger-subtle .fc-list-event-title > a {
  color: #fa5c7c !important;
}
.fc-list-table .fc-list-event.bg-danger-subtle .fc-list-event-dot {
  border-color: #fa5c7c;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-light-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-light-subtle .fc-event-main {
  color: #eef2f7 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-light-subtle .fc-event-title {
  color: #eef2f7 !important;
}

.fc-list-table .fc-list-event.bg-light-subtle {
  color: #eef2f7 !important;
}
.fc-list-table .fc-list-event.bg-light-subtle .fc-list-event-title > a {
  color: #eef2f7 !important;
}
.fc-list-table .fc-list-event.bg-light-subtle .fc-list-event-dot {
  border-color: #eef2f7;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle .fc-event-main {
  color: #313a46 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-dark-subtle .fc-event-title {
  color: #313a46 !important;
}

.fc-list-table .fc-list-event.bg-dark-subtle {
  color: #313a46 !important;
}
.fc-list-table .fc-list-event.bg-dark-subtle .fc-list-event-title > a {
  color: #313a46 !important;
}
.fc-list-table .fc-list-event.bg-dark-subtle .fc-list-event-dot {
  border-color: #313a46;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle .fc-event-main,
.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle .fc-event-title {
  color: var(--ct-dark-text-emphasis) !important;
}

.fc-direction-ltr {
  direction: ltr;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0.75em;
}

.chartjs-chart {
  margin: auto;
  position: relative;
  width: 100%;
}

.dataTables_wrapper.container-fluid, .dataTables_wrapper.container-sm, .dataTables_wrapper.container-md, .dataTables_wrapper.container-lg, .dataTables_wrapper.container-xl, .dataTables_wrapper.container-xxl {
  padding: 0;
}

table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr th.dtr-control:before {
  background-color: var(--ct-primary);
  box-shadow: 0px 0px 2px 0px var(--ct-primary);
}
table.dataTable thead:not.table-light th {
  background-color: transparent;
  border-bottom-color: var(--ct-border-color);
}
table.dataTable tbody tr.even td,
table.dataTable tbody tr.odd td {
  background-color: transparent;
}
table.dataTable tbody > tr.selected td {
  background-color: var(--ct-primary);
}
table.dataTable.table-striped > tbody > tr.selected:nth-of-type(odd) > * {
  --ct-table-accent-bg: $primary !important;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 0.5rem;
  left: auto;
  content: "\f0360";
  font-family: "Material Design Icons";
  font-size: 1rem;
  top: 12px;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  left: auto;
  content: "\f035d";
  font-family: "Material Design Icons";
  top: 18px;
  font-size: 1rem;
}
table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
  padding-right: 30px;
  padding-left: 0.95rem;
}
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: var(--ct-primary);
}
table.dataTable tbody > tr.selected td,
table.dataTable tbody > tr > .selected td {
  border-color: var(--ct-primary);
}
table.dataTable tbody td:focus {
  outline: none !important;
}
table.dataTable tbody th.focus,
table.dataTable tbody td.focus {
  outline: 2px solid #727cf5 !important;
  outline-offset: -1px;
  background-color: rgba(var(--ct-primary-rgb), 0.15);
}

.dataTables_info {
  font-weight: 600;
}

.dtfh-floatingparent {
  top: var(--ct-topbar-height) !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child:before {
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  background-color: #0acf97;
  top: auto;
  bottom: auto;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #fa5c7c;
}

div.dt-button-info {
  background-color: var(--ct-primary);
  border: none;
  color: #fff;
  box-shadow: none;
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info h2, div.dt-button-info .h2 {
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

div.dt-scroll-body {
  border: none;
  scrollbar-width: thin;
}

@media (max-width: 767.98px) {
  li.paginate_button.previous,
  li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem;
  }
  li.paginate_button {
    display: none;
  }
  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 !important;
  }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1.5rem;
  }
}
.activate-select .sorting_1 {
  background-color: var(--ct-tertiary-bg) !important;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
@media (max-width: 576px) {
  div.dataTables_wrapper div.dataTables_filter {
    text-align: center;
  }
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  margin-right: 0;
}
div.dataTables_wrapper div.dataTables_length {
  text-align: left;
}
@media (max-width: 576px) {
  div.dataTables_wrapper div.dataTables_length {
    text-align: center;
  }
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: 0;
}

div.dataTables_scrollBody {
  border-left: none !important;
}
div.dataTables_scrollBody > table {
  margin-bottom: 15px !important;
}
div.dataTables_scrollBody > table > :not(:first-child) {
  border-top: none !important;
}

.daterangepicker {
  font-family: var(--ct-font-sans-serif);
  border: var(--ct-border-width) solid var(--ct-border-color);
  box-shadow: var(--ct-box-shadow);
  background-color: var(--ct-secondary-bg);
}
.daterangepicker .calendar-table {
  border: 1px solid var(--ct-secondary-bg);
  background-color: var(--ct-secondary-bg);
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  color: var(--ct-secondary-color);
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: var(--ct-border-color);
}
.daterangepicker .ranges li:hover {
  background-color: var(--ct-tertiary-bg);
}
.daterangepicker .ranges li.active {
  background-color: var(--ct-primary);
}
.daterangepicker td.in-range {
  background: var(--ct-tertiary-bg);
  color: var(--ct-tertiary-color);
}
.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
  background-color: var(--ct-secondary-bg);
  color: var(--ct-body-color);
  opacity: 0.5;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: var(--ct-primary);
  color: #fff;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover,
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--ct-primary);
  color: #fff;
}
.daterangepicker td.available:hover span,
.daterangepicker th.available:hover span,
.daterangepicker td.active span,
.daterangepicker td.active:hover span {
  border-color: #fff;
}
.daterangepicker:after {
  border-bottom: 6px solid var(--ct-secondary-bg);
}
.daterangepicker:before {
  border-bottom: 7px solid var(--ct-border-color);
}
.daterangepicker .drp-buttons {
  border-top: 1px solid var(--ct-border-color);
}
.daterangepicker select.ampmselect, .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect {
  background: var(--ct-tertiary-bg);
  border: 1px solid var(--ct-border-color);
  color: var(--ct-body-color);
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid var(--ct-border-color) !important;
}

.datepicker {
  padding: 10px !important;
}
.datepicker td,
.datepicker th {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 13px;
  line-height: 28px;
}
.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td .active.disabled:hover[disabled],
.datepicker table tr td .active.disabled[disabled],
.datepicker table tr td .active:active,
.datepicker table tr td .active:hover,
.datepicker table tr td .active:hover.active,
.datepicker table tr td .active:hover.disabled,
.datepicker table tr td .active:hover:active,
.datepicker table tr td .active:hover:hover,
.datepicker table tr td .active:hover[disabled],
.datepicker table tr td .active[disabled],
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled], .datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #727cf5 !important;
  background-image: none !important;
  color: #fff;
}
.datepicker table tr td.day.focused, .datepicker table tr td.day:hover,
.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: var(--ct-tertiary-bg);
}
.datepicker table tr td.new, .datepicker table tr td.old,
.datepicker table tr td span.new,
.datepicker table tr td span.old {
  color: var(--ct-tertiary-color);
  opacity: 0.4;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: var(--ct-light-bg-subtle);
}
.datepicker .datepicker-switch:hover {
  background: none;
}

.datepicker-dropdown:after {
  border-bottom: 6px solid var(--ct-secondary-bg);
}
.datepicker-dropdown:before {
  border-bottom-color: var(--ct-border-color);
}
.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid var(--ct-border-color);
}
.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid var(--ct-secondary-bg);
}

.daterangepicker {
  z-index: 1000;
}

[dir=rtl] .daterangepicker.opensright:after {
  right: 10px;
  left: auto;
}
[dir=rtl] .daterangepicker.opensright:before {
  right: 9px;
  left: auto;
}

.flatpickr-calendar {
  background: var(--ct-secondary-bg);
  box-shadow: var(--ct-box-shadow);
  border: 1px solid var(--ct-border-color);
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--ct-secondary-bg);
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--ct-secondary-bg);
}
.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--ct-secondary-bg);
}

.flatpickr-current-month {
  font-size: 110%;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: var(--ct-primary);
  border-color: var(--ct-primary);
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 var(--ct-primary);
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: var(--ct-input-bg);
  color: var(--ct-emphasis-color);
}

.flatpickr-months .flatpickr-month {
  height: 36px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-month {
  color: var(--ct-dark-border-subtle);
  fill: var(--ct-dark-border-subtle);
}

.flatpickr-weekdays {
  background-color: var(--ct-tertiary-bg);
}

span.flatpickr-weekday,
.flatpickr-day,
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover,
.flatpickr-time input,
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: var(--ct-body-color);
  fill: var(--ct-body-color);
}

.flatpickr-day.disabled, .flatpickr-day.disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--ct-emphasis-color);
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: var(--ct-tertiary-bg);
  border-color: var(--ct-tertiary-bg);
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border-top: 1px solid var(--ct-secondary-bg);
}

.numInputWrapper:hover,
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
  color: var(--ct-emphasis-color);
}

.flatpickr-day.inRange {
  box-shadow: -5px 0 0 var(--ct-tertiary-bg), 5px 0 0 var(--ct-tertiary-bg);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: var(--ct-emphasis-color);
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: var(--ct-border-color);
}

.bg-dragula {
  background-color: var(--ct-tertiary-bg);
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.dragula-handle {
  position: relative;
  width: 36px;
  height: 36px;
  font-size: 24px;
  text-align: center;
  cursor: move;
}
.dragula-handle:before {
  content: "\f01db";
  font-family: "Material Design Icons";
  position: absolute;
}

.dropzone {
  border: 2px dashed var(--ct-border-color);
  background: var(--ct-input-bg);
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2rem 0;
}
.dropzone.dz-started .dz-message {
  display: none;
}

.form-wizard-header {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-color: var(--ct-tertiary-bg);
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: var(--ct-tertiary-bg);
  border-radius: 3px;
}

.jvm-zoom-btn {
  border: none;
  background: var(--ct-tertiary-bg);
  color: var(--ct-tertiary-color);
  font-family: var(--ct-font-sans-serif);
  font-size: 0.9rem;
  font-weight: bolder;
}

.jvm-tooltip {
  border-radius: 3px;
  background-color: var(--ct-tertiary-bg);
  color: var(--ct-tertiary-color);
  font-family: var(--ct-font-sans-serif);
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  padding: 5px 9px;
}

.jvm-container text {
  font-family: var(--ct-font-sans-serif);
  font-size: 0.9rem;
  fill: var(--ct-body-color);
}

.ql-editor {
  text-align: left;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
  padding-right: 0;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}

.ql-container {
  font-family: var(--ct-font-sans-serif);
}
.ql-container.ql-snow {
  border-color: var(--ct-border-color);
  border-radius: 0 0 0.25rem 0.25rem;
}

.ql-bubble {
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: 0.25rem;
}

.ql-toolbar {
  font-family: var(--ct-font-sans-serif) !important;
}
.ql-toolbar span {
  outline: none !important;
  color: var(--ct-body-color);
}
.ql-toolbar span:hover {
  color: #727cf5 !important;
}
.ql-toolbar.ql-snow {
  border-radius: 0.25rem 0.25rem 0 0;
  border-color: var(--ct-border-color);
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
  box-shadow: var(--ct-box-shadow);
  border-radius: 0.25rem;
}

.ql-snow .ql-stroke,
.ql-snow .ql-script,
.ql-snow .ql-strike svg {
  stroke: var(--ct-body-color);
}
.ql-snow .ql-fill {
  fill: var(--ct-body-color);
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: 0;
  left: auto;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--ct-body-color);
}

.ql-snow .ql-picker-options {
  background-color: var(--ct-secondary-bg);
  border-color: var(--ct-border-color) !important;
}

.select2-container {
  width: 100% !important;
}
.select2-container .select2-selection--single {
  border: var(--ct-border-width) solid var(--ct-border-color);
  height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
  background-color: var(--ct-input-bg);
  outline: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
  padding-left: 12px;
  color: var(--ct-body-color);
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 34px;
  width: 34px;
  /*rtl:ignore*/
  right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: var(--ct-dark-border-subtle) transparent transparent transparent;
  border-width: 5px 5px 0 5px;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent var(--ct-dark-border-subtle) transparent !important;
  border-width: 0 5px 5px 5px !important;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: var(--ct-border-width) solid var(--ct-border-color);
  box-shadow: var(--ct-box-shadow);
  background-color: var(--ct-secondary-bg);
  z-index: 1056;
}

.select2-results__options::-webkit-scrollbar {
  -webkit-appearance: none;
}
.select2-results__options::-webkit-scrollbar:vertical {
  width: 5px;
  margin-right: 5px;
}
.select2-results__options::-webkit-scrollbar:horizontal {
  height: 5px;
}
.select2-results__options::-webkit-scrollbar-thumb {
  background-color: rgba(var(--ct-dark-rgb), 0.2);
  border-radius: 10px;
  margin-right: 5px;
  border: none;
}
.select2-results__options::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.select2-results__options::-webkit-scrollbar-corner {
  background-color: transparent;
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--ct-tertiary-bg);
}
.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: var(--ct-secondary-bg);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none;
  border: 1px solid var(--ct-border-color);
  background-color: var(--ct-input-bg);
  color: var(--ct-body-color);
  border-radius: 0.25rem;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--ct-primary);
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: var(--ct-secondary-bg);
  color: var(--ct-emphasis-color);
}
.select2-container--default .select2-results__option[aria-selected=true]:hover {
  background-color: var(--ct-primary);
  color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 36px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  /*rtl:ignore*/
  right: 1px;
  /*rtl:ignore*/
  left: auto;
}

.select2-container .select2-selection--multiple {
  min-height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
  border: var(--ct-border-width) solid var(--ct-border-color) !important;
  background-color: var(--ct-input-bg);
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 1px 4px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  border: 0;
  color: var(--ct-body-color);
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: var(--ct-primary);
  border: none;
  color: #fff;
  border-radius: 3px;
  padding: 0px 7px 0 0;
  margin-top: 6px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
  margin-right: 7px;
  border-color: #8089f6;
  padding: 0 8px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #fff;
  background-color: var(--ct-primary);
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 7px;
  font-family: var(--ct-font-sans-serif);
}
.select2-container .select2-search textarea::placeholder {
  color: var(--ct-secondary-color);
}

[dir=rtl] .select2-container--open .select2-dropdown {
  left: auto;
  right: 0;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 5px;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

[data-simplebar-lg] .simplebar-scrollbar {
  right: 1px;
  width: 10px;
}

[data-simplebar-primary] .simplebar-scrollbar:before {
  background: var(--ct-primary);
}

.jq-toast-single {
  text-align: left !important;
  padding: 15px;
  font-family: var(--ct-font-sans-serif);
  background-color: var(--ct-primary);
  font-size: 13px;
  line-height: 22px;
}
.jq-toast-single h2, .jq-toast-single .h2 {
  font-family: var(--ct-font-sans-serif);
}
.jq-toast-single a {
  font-size: 0.9rem;
}
.jq-toast-single a:hover {
  color: #fff;
}

.jq-has-icon {
  padding: 10px;
}

.close-jq-toast-single {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: var(--ct-body-bg);
  color: var(--ct-border-color);
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}

.jq-toast-loader {
  height: 3px;
  top: 0;
  border-radius: 0;
}

.jq-icon-primary {
  background-color: #727cf5;
  color: #fff;
  border-color: #727cf5;
}

.jq-icon-secondary {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}

.jq-icon-success {
  background-color: #0acf97;
  color: #fff;
  border-color: #0acf97;
}

.jq-icon-info {
  background-color: #39afd1;
  color: #fff;
  border-color: #39afd1;
}

.jq-icon-warning {
  background-color: #ffc35a;
  color: #fff;
  border-color: #ffc35a;
}

.jq-icon-danger {
  background-color: #fa5c7c;
  color: #fff;
  border-color: #fa5c7c;
}

.jq-icon-light {
  background-color: #eef2f7;
  color: #fff;
  border-color: #eef2f7;
}

.jq-icon-dark {
  background-color: #313a46;
  color: #fff;
  border-color: #313a46;
}

.jq-icon-error {
  background-color: #fa5c7c;
  color: #fff;
  border-color: #fa5c7c;
}

.jq-icon-info,
.jq-icon-warning,
.jq-icon-error,
.jq-icon-success {
  background-image: none;
}

.bootstrap-touchspin .btn .input-group-text {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
}

.bootstrap-timepicker-widget table td input {
  height: 32px;
  width: 32px;
  color: #fff;
  background-color: var(--ct-primary);
  border-radius: 50%;
  border: 0;
  outline: none !important;
}
.bootstrap-timepicker-widget table td a {
  color: var(--ct-body-color);
}
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--ct-primary);
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid var(--ct-border-color);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid var(--ct-secondary-bg);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid var(--ct-secondary-bg);
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid var(--ct-border-color);
}

.editor-toolbar.fullscreen,
.CodeMirror-fullscreen {
  z-index: 1000;
}

.editor-preview,
.editor-preview-side {
  background: var(--ct-border-color);
}

.editor-toolbar {
  border: 1px solid var(--ct-border-color);
  border-bottom: 0;
  opacity: 1;
}
.editor-toolbar a {
  color: var(--ct-body-color) !important;
}
.editor-toolbar a:hover, .editor-toolbar a.active {
  background-color: transparent;
  color: var(--ct-primary) !important;
  border-color: transparent;
}
.editor-toolbar i.separator {
  display: none;
}
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: transparent;
}

.CodeMirror {
  border: 1px solid var(--ct-border-color);
  background: var(--ct-input-bg);
  color: var(--ct-body-color);
  min-height: 100px;
}

.CodeMirror-cursor {
  border-left: 1px solid var(--ct-secondary-color);
}

.editor-statusbar {
  color: var(--ct-body-bg);
}

.twitter-typeahead {
  display: inherit !important;
}

.tt-query,
.tt-hint {
  outline: none;
}

.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: var(--ct-body-color);
  background: var(--ct-input-bg) !important;
}

.tt-menu {
  width: 100%;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--ct-secondary-bg);
  box-shadow: var(--ct-box-shadow);
  border: 1px solid var(--ct-border-color);
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}
.tt-suggestion:hover, .tt-suggestion.tt-cursor {
  cursor: pointer;
  color: #fff;
  background-color: var(--ct-primary);
}
.tt-suggestion p {
  margin: 0;
}

.tt-highlight {
  font-family: var(--ct-font-sans-serif);
}

.typeahead-empty-message {
  padding: 5px 10px;
  color: #fa5c7c;
}

.league-name {
  padding: 3px 20px;
}

.jqstooltip {
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: #fff !important;
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #fff !important;
}

.jqsfield {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: var(--ct-font-sans-serif) !important;
  font-weight: 700 !important;
}

/* Timepicker */
.timepicker-orient-bottom {
  top: auto !important;
  bottom: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2)) !important;
}

.bootstrap-timepicker-widget {
  left: 0 !important;
  right: auto !important;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
  right: auto;
}
.bootstrap-timepicker-widget.timepicker-orient-left::after {
  left: 7px;
  right: auto;
}

.rateit {
  display: -moz-inline-box;
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
}
.rateit .rateit-range * {
  display: block;
}
.rateit .rateit-hover,
.rateit .rateit-selected {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
}
.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  left: auto;
  right: 0;
}
.rateit .rateit-hover {
  color: #ffc35a;
}
.rateit .rateit-hover-rtl {
  background-position: right -32px;
}
.rateit .rateit-selected {
  color: #fa5c7c;
}
.rateit .rateit-selected-rtl {
  background-position: right -16px;
}
.rateit .rateit-preset {
  color: #fd7e14;
}
.rateit button.rateit-reset {
  width: 16px;
  height: 16px;
  float: left;
  outline: none;
  border: none;
  padding: 0;
}
.rateit .rateit-reset span {
  display: none;
}
.rateit .rateit-range {
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
  height: 16px;
  outline: none;
}
.rateit.rateit-font .rateit-reset {
  width: 0.6em;
  height: 0.6em;
  margin-right: 5px;
  margin-top: 5px;
  background: var(--ct-tertiary-bg);
  border-radius: 50%;
  position: relative;
}
.rateit.rateit-font .rateit-reset span {
  display: block;
  height: calc(50% - 0.045em);
  top: 2px;
  position: absolute;
  border-bottom: 2px solid var(--ct-emphasis-color);
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}
.rateit.rateit-font .rateit-reset:hover,
.rateit.rateit-font button.rateit-reset:focus {
  background: #fa5c7c;
}
.rateit.rateit-font .rateit-reset:hover span,
.rateit.rateit-font button.rateit-reset:focus span {
  border-color: #fff;
}

.rateit-mdi {
  font-family: "Material Design Icons";
}

.rateit-font {
  font-size: 24px;
  line-height: 1em;
}
.rateit-font .rateit-range {
  background: none;
  height: auto;
}
.rateit-font .rateit-range > div {
  background: none;
  overflow: hidden;
  cursor: default;
  white-space: nowrap;
}
.rateit-font .rateit-empty {
  color: var(--ct-border-color);
}

.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  right: auto;
}

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}
.irs-handle.type_last {
  z-index: 2;
}

.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default;
}

.irs-min {
  left: 0;
  right: auto;
}

.irs-max {
  right: 0;
  left: auto;
}

[dir=rtl] .irs-min {
  right: 0;
  left: auto;
}
[dir=rtl] .irs-max {
  left: 0;
  right: auto;
}

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  /*rtl:ignore*/
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  width: 1px;
  height: 8px;
  background: #6c757d;
}
.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  /*rtl:ignore*/
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #6c757d;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  z-index: 2;
}

.lt-ie9 .irs-disable-mask {
  background: #6c757d;
  filter: alpha(opacity=0);
  cursor: not-allowed;
}

.irs-disabled {
  opacity: 0.4;
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs--flat {
  height: 40px;
}
.irs--flat.irs-with-grid {
  height: 60px;
}
.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: var(--ct-tertiary-bg);
  border-radius: 4px;
}
.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: var(--ct-primary);
}
.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}
.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: var(--ct-tertiary-bg);
}
.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent;
}
.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #515df3;
}
.irs--flat .irs-min,
.irs--flat .irs-max {
  top: 0;
  padding: 1px 3px;
  color: var(--ct-secondary-color);
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: var(--ct-tertiary-bg);
  border-radius: 4px;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: var(--ct-primary);
  border-radius: 4px;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: var(--ct-primary);
}
.irs--flat .irs-grid-pol {
  background-color: var(--ct-tertiary-bg);
}
.irs--flat .irs-grid-text {
  color: var(--ct-secondary-color);
}

[dir=rtl] .irs-bar--single {
  border-radius: 0 4px 4px 0 !important;
}

.jstree-default {
  padding: 2px 6px;
  height: auto;
}
.jstree-default .jstree-clicked,
.jstree-default .jstree-hovered {
  background: var(--ct-tertiary-bg);
  box-shadow: none;
}
.jstree-default .jstree-anchor,
.jstree-default .jstree-icon,
.jstree-default .jstree-icon:empty {
  line-height: 20px;
}
.jstree-default .jstree-node,
.jstree-default .jstree-icon {
  background-image: url(../images/treeview.png);
}
.jstree-default .jstree-last {
  background: transparent;
}
.jstree-default .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}
.jstree-default .jstree-disabled {
  color: var(--ct-secondary-color);
}

.jstree-wholerow.jstree-wholerow-clicked, .jstree-wholerow.jstree-wholerow-hovered {
  background: var(--ct-tertiary-bg);
}

.gantt .bar-progress {
  fill: var(--ct-primary);
}
.gantt .bar-wrapper:hover .bar-progress {
  fill: #8a92f7;
}
.gantt .bar,
.gantt .bar-wrapper:hover .bar {
  fill: var(--ct-light);
}
.gantt .bar-label,
.gantt .bar-label.big {
  fill: var(--ct-tertiary-color);
}
.gantt .grid-header {
  fill: rgba(var(--ct-tertiary-bg-rgb), 1);
  stroke: rgba(var(--ct-tertiary-bg-rgb), 1);
}
.gantt .grid-row {
  fill: transparent;
}
.gantt .grid-row:nth-child(even) {
  fill: rgba(var(--ct-tertiary-bg-rgb), 1);
}
.gantt .lower-text,
.gantt .upper-text {
  fill: var(--ct-body-color);
}
.gantt .row-line {
  stroke: var(--ct-border-color);
}
.gantt .today-highlight {
  fill: var(--ct-light-bg-subtle);
}

.gantt-container::-webkit-scrollbar {
  -webkit-appearance: none;
}
.gantt-container::-webkit-scrollbar:vertical {
  width: 5px;
  margin-right: 5px;
}
.gantt-container::-webkit-scrollbar:horizontal {
  height: 5px;
}
.gantt-container::-webkit-scrollbar-thumb {
  background-color: rgba(var(--ct-dark-rgb), 0.2);
  border-radius: 10px;
  margin-right: 5px;
  border: none;
}
.gantt-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.gantt-container::-webkit-scrollbar-corner {
  background-color: transparent;
}
.gantt-container .popup-wrapper {
  background: transparent;
}
.gantt-container .popup-wrapper .pointer {
  display: none;
}